import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './HowWorks.css';
import Fab from '../../components/fab/Fab';
import Chart from '../../components/chart/Chart';
import NewsLetter from '../../components/newsletter/Newsletter';

// + IMAGES +
import book from '../../assets/imgs/book.svg';
import kyc from '../../assets/imgs/kyc.svg';
import wallet from '../../assets/imgs/wallet.svg';
import settings from '../../assets/imgs/settings.svg';
import chart from '../../assets/imgs/chart.svg';
import bitcoin from '../../assets/imgs/bitcoin.svg';
import bgVector3 from '../../assets/imgs/bg_vector3.svg';
import bgVector4 from '../../assets/imgs/bg_vector4.svg';
// - IMAGES -

const HowWorks = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const navigate = useNavigate();

    const goToContact = () => {
        navigate('/contacto');
    }

    return (
        <>
            <Fab />
            <Chart />
            <div className='bg-vector-right d-none d-md-block'>
                <img src={bgVector3} alt="" />
            </div>
            <div className='bg-vector-left d-none d-md-block'>
                <img src={bgVector4} alt="" />
            </div>
            <Container className='p-5 pt-5 mt-5'>
                <h1 className='subtitle'>¿Cómo funciona?</h1>

                <h2 className='text-color'>Lorem Ipsum dolor amet</h2>

                <Row className='mt-5'>
                    <Col xs={12} md={4} className='mt-3 col-container'>
                        <div className='card-work'>
                            <Row>
                                <Col className='text-center'>
                                    <img src={book} alt="" />
                                </Col>
                            </Row>
                            <p className='text-center mt-3 title-work'>Infórmate</p>
                            <span className='text-work'>Te ofrecemos todo el contenido necesario para entender los conceptos básicos de La Casa Del Token. Gracias a nuestra Tokenpedia podrás disfrutar de un proceso de inversión seguro y rentable.</span>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className='mt-3 col-container'>
                        <div className='card-work'>
                            <Row>
                                <Col className='text-center'>
                                    <img src={kyc} alt="" />
                                </Col>
                            </Row>
                            <p className='text-center mt-3 title-work'>Registro y KYC</p>
                            <span className='text-work'>Realiza este proceso en 5 minutos. En La Casa del Token nos gusta conocer a nuestros inversores e interactuar con ellos. Aportamos este plus de seguridad para ofrecer una plataforma con perfiles verificados.</span>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className='mt-3 col-container'>
                        <div className='card-work'>
                            <Row>
                                <Col className='text-center'>
                                    <img src={wallet} alt="" />
                                </Col>
                            </Row>
                            <p className='text-center mt-3 title-work'>Te creamos tu wallet</p>
                            <span className='text-work'>Sabemos que es complejo el proceso de crear una billetera electrónica. No te preocupes, hemos creado una plataforma que te otorga este servicio totalmente gratis para evitarte cualquier demora en el proceso de invertir. Esto te ayudará a comprar tus primeros tokens.</span>
                        </div>
                    </Col>
                </Row>

                <Row className='mt-5'>
                    <Col xs={12} md={4} className='mt-3 col-container'>
                        <div className='card-work'>
                            <Row>
                                <Col className='text-center'>
                                    <img src={settings} alt="" />
                                </Col>
                            </Row>
                            <p className='text-center mt-3 title-work'>Analiza la  oferta de inmubles</p>
                            <span className='text-work'>Hemos creado una plataforma 3.0 para que puedas optimizar al máximo tu búsqueda a la hora de invertir en La Casa Del Token. Descubre nuestros filtros y las fichas de producto más completa del mercado.</span>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className='mt-3 col-container'>
                        <div className='card-work'>
                            <Row>
                                <Col className='text-center'>
                                    <img src={chart} alt="" />
                                </Col>
                            </Row>
                            <p className='text-center mt-3 title-work'>Seguimiento de tu inversión</p>
                            <span className='text-work'>En nuestra web 3.0 disfrutarás de un panel de usuario personalizado para ti con el objetivo de saber en todo momento los rendimientos que te ofrecen los inmuebles de La Casa Del Token.</span>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className='mt-3 col-container'>
                        <div className='card-work'>
                            <Row>
                                <Col className='text-center'>
                                    <img src={bitcoin} alt="" />
                                </Col>
                            </Row>
                            <p className='text-center mt-3 title-work'>Recibe tus dividendos</p>
                            <span className='text-work'>Dependiendo del tipo de inmueble que hayas elegido (One-Shot o Recurrente) podrás tener ingresos mensuales o recibir una plusvalía final para la explotación de los activos inmobiliarios.</span>
                        </div>
                    </Col>
                </Row>
            </Container>

            <div className='banner'>
                <Row className='align-items-center h-100'>
                    <Col className='text-center'>
                        <span className='subtitle'>¿Estás listo para invertir?</span>
                        <Button variant='outline-light btn-global' className='btn-gb btn-work' onClick={goToContact}>
                            CONSÚLTANOS
                        </Button>
                    </Col>
                </Row>

            </div>

            <NewsLetter />
        </>
    );
}

export default HowWorks;