import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import './Cookies.css';

// + IMAGES +
// - IMAGES -

const Cookies = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container className='privacy-text pb-5 pt-5'>
            <h1 className='mt-5'>POLÍTICA DE COOKIES</h1>

            <p>Esta Política de Cookies es parte integrante de los textos legales y de la Política de
                Privacidad del sitio web <a href="https://thunderapp.es/">[https://thunderapp.es/]</a> en adelante, el “Sitio Web”), así como de ii)
                la aplicación <a href="https://thunderapp.es/">[https://thunderapp.es/]</a> (en adelante, la “Aplicación”), propiedad de THE
                TOKEN HOUSE S.L. (en adelante, “LA CASA DEL TOKEN”), con NIF B-09608258 con
                domicilio en calle Paseo De La Castellana, 180 Bis, 1º Derecha, 28046 – Madrid, con datos
                en el Registro Mercantil de Madrid, Tomo 43.191, Folio 170, Sección 8, Hoja M-763229,
                Inscripción 1ª y correo electrónico a efectos de privacidad: <a href="https://thunderapp.es/">[https://thunderapp.es/]</a>
                El acceso y la navegación en el Sitio Web o en la Aplicación, así como la aceptación del
                banner correspondiente a las cookies y su configuración implican la aceptación de los
                términos y condiciones recogidos en la presente Política de Cookies.
                Con el fin de facilitar la navegación por el Sitio Web y la Aplicación le comunica que utiliza
                cookies u otros archivos de funcionalidad similar (en adelante, las “Cookies”).
                En todo caso, le informamos de que LA CASA DEL TOKEN es el responsable de dichas
                Cookies y del tratamiento de los datos obtenidos a través de éstas, decidiendo sobre la
                finalidad, contenido y uso del tratamiento de la información recabada. Además, tanto el Sitio
                Web como la Aplicación no instalan nuestras Cookies en los dispositivos de los Usuarios
                hasta que estos hayan aceptado la instalación de estas.</p>

            <p>El acceso y la navegación en el Sitio Web o en la Aplicación, así como la aceptación del
                banner correspondiente a las cookies y su configuración implican la aceptación de los
                términos y condiciones recogidos en la presente Política de Cookies.</p>

            <p>Con el fin de facilitar la navegación por el Sitio Web y la Aplicación le comunica que utiliza
                cookies u otros archivos de funcionalidad similar (en adelante, las “Cookies”).</p>

            <p>En todo caso, le informamos de que LA CASA DEL TOKEN es el responsable de dichas
                Cookies y del tratamiento de los datos obtenidos a través de éstas, decidiendo sobre la
                finalidad, contenido y uso del tratamiento de la información recabada. Además, tanto el Sitio
                Web como la Aplicación no instalan nuestras Cookies en los dispositivos de los Usuarios
                hasta que estos hayan aceptado la instalación de estas.</p>

            <h1 className='mt-5'>1.- ¿Qué se entiende por una Cookie?</h1>

            <p>Las Cookies son archivos que contienen pequeñas cantidades de información que se
                descargan en el dispositivo del usuario cuando visita una página web o una aplicación,
                asociándose únicamente a un usuario anónimo y su dispositivo y a veces, proporcionan
                evidencias que permiten acceder a datos personales del usuario.</p>

            <p>Las Cookies son esenciales para el funcionamiento de Internet, su finalidad principal es
                reconocer al usuario cada vez que accede al Sitio Web o la Aplicación, permitiendo, además
                de mejorar la calidad, ofrecer un mejor uso de ambos entornos, personalizando los servicios
                que se ofrecen en esta y ofreciendo al usuario información que es de su interés o que
                puede ser de su interés, en atención al uso que realiza de aquellos. En cualquier caso, el
                Usuario podrá acceder a la configuración de su navegador para modificar y/o bloquear la
                instalación de las Cookies enviadas desde el Sitio Web o la Aplicación, sin que ello impida
                al acceso a los contenidos.</p>

            <h1 className='mt-5'>2.- Uso de Cookies por parte de CLIENTE</h1>

            <p>Los usuarios autorizan expresamente el uso de esta información con las finalidades
                indicadas, a través de los canales habilitados al efecto en el Sitio Web o la Aplicación, sin
                perjuicio de su derecho a rechazar, deshabilitar o configurar el uso de Cookies. Si se
                desactivan o se rechazan las Cookies, puede que la navegación por el Sitio Web o la Aplicación no sea óptima y algunas de las utilidades de que disponibles no funcionen
                correctamente.</p>

            <p>En concreto, LA CASA DEL TOKEN está utilizando las Cookies para las finalidades que a
                continuación se exponen. Si en un futuro LA CASA DEL TOKEN utilizase otras Cookies con
                el propósito de otorgar más y mejores servicios, se informará al usuario de ello.</p>

            <p>La instalación de dichas Cookies en el dispositivo del usuario se producirá siempre de
                manera posterior a la recogida del consentimiento y/o configuración de estas.</p>

            <p>En esta Política de Cookies se informa de manera general de todas las Cookies utilizadas
                en el Sitio Web o en la Aplicación. Sin embargo, a efectos informativos para el usuario, las
                Cookies que son necesarias para el funcionamiento del Sitio Web o de la Aplicación estarán
                activadas por defecto, pudiendo ser, en su caso, desactivadas y rechazadas por el usuario,
                siempre en cumplimiento de la normativa vigente.</p>

            <h1 className='mt-5'>3.- Tipos y objetivos de las cookies utilizadas</h1>

            <p>En el Sitio Web como en la Aplicación se utilizan cookies propias y de terceros. Según la
                entidad que gestione los datos que se obtengan a través de las Cookies, se agrupan de la
                siguiente manera:</p>

            <ul>
                <li>Propias: Aquéllas que se envían al propio editor que presta el servicio solicitado por
                    el usuario.</li>
                <li>De terceros: No son gestionadas por el editor, sino por un tercero que es quien
                    gestiona los datos obtenidos a través de las Cookies.</li>
            </ul>

            <p>En función de su permanencia:</p>

            <ul>
                <li>De sesión: permanecen durante el acceso y navegación en el sitio, se suprimen una
                    vez el usuario abandona el Sitio Web o la Aplicación.</li>
                <li>Permanentes: Permanecen almacenadas en el terminal y pueden ser accedidos y
                    tratados durante un periodo de tiempo definido por el responsable de la Cookie.</li>
            </ul>

            <h1 className='mt-5'>4.- Cookies utilizadas en el Sitio Web o en la Aplicación</h1>

            <p>Las Cookies utilizadas por LA CASA DEL TOKEN son cookies propias y de terceros.</p>

            <h1 className='mt-5'>4.1. Cookies necesarias</h1>

            <p>Son aquellas cookies consideradas imprescindibles para la navegación por ambos entornos,
                pues facilitan al usuario la utilización de sus prestaciones o herramientas como, por
                ejemplo, identificar la sesión, acceder a partes de acceso restringido, garantizar la
                seguridad de la navegación. Sin estas cookies, las funciones mencionadas quedarían
                inhabilitadas.</p>

            <p>En este sentido, LA CASA DEL TOKEN. utiliza estas cookies propias con las siguientes
                finalidades:</p>

            <ul>
                <li>Identificar la sesión del usuario para mantenerla activa durante la navegación.</li>
                <li>Identificar la sesión del usuario para evitar posibles ataques y las falsificaciones de
                    peticiones.</li>
                <li>Identificar la configuración de cookies del usuario.</li>
                <li>Identificar el servidor utilizado por el usuario.</li>
                <li>Configuración de la seguridad del firewall web.</li>
                <li>Verificar la conexión con los servidores (en estos casos estas cookies pueden ser de
                    terceros, pero siempre tendrán la finalidad descrita).</li>
                <li>Optimizar el tiempo de respuesta en la web con la función de cache.</li>
            </ul>

            <p>En concreto dichas Cookies son:</p>

            <ul>
                <li>COOKIES: _wpfuuid | PROPIETARIO: Token house | FINALIDAD: Registra un ID
                    único para el visitante con el fin de que el sitio web reconozca al visitante al volver a
                    acceder al sitio web | CADUCIDAD: 11 años</li>
                <li>COOKIES: CMPList | PROPIETARIO: Token house | FINALIDAD: Almacena el
                    estado de consentimiento de la cookie del usuario para el dominio actual |
                    CADUCIDAD: Permanente</li>
                <li>COOKIES: hu-session | PROPIETARIO: Token house | FINALIDAD: Determina si el
                    usuario ha aceptado la casilla de consentimiento de cookies | CADUCIDAD: De
                    sesión</li>
            </ul>

            <h1 className='mt-5'>4.2 Cookies analíticas</h1>

            <p>Estas cookies sirven para analizar la navegación del usuario por el Sitio Web o la
                Aplicación, así como realizar una contabilidad de estas. Algunas de estas cookies identifican
                al usuario, mientras que otras solo utilizan datos agregados.</p>

            <p>LA CASA DEL TOKEN, a través del Sitio Web y de la Aplicación, utiliza Cookies de terceros
                para la seguridad de ambos entornos como a nivel analítico. En concreto dichas Cookies
                son:</p>

            <ul>
                <li>COOKIES: _ga | PROPIETARIO: Token house | FINALIDAD: Registra una
                    identificación única que se utiliza para generar datos estadísticos sobre el uso que el
                    visitante hace del sitio web | CADUCIDAD: 2 años</li>
                <li>COOKIES: _dit | PROPIETARIO: Token house | FINALIDAD: Establece un ID único
                    para la sesión. Esto permite que el sitio web obtenga datos sobre el comportamiento
                    de los visitantes para realizar estadísticas. | CADUCIDAD: 2 años</li>
                <li>COOKIES: _ga | PROPIETARIO: Token house | FINALIDAD: Registra una
                    identificación única que se utiliza para generar datos estadísticos sobre el uso que el
                    visitante hace del sitio web | CADUCIDAD: 1 día</li>
                <li>COOKIES: _ga_# | PROPIETARIO: Token house | FINALIDAD: Utilizado por Google
                    Analytics para recoger datos sobre el número de veces que un usuario ha visitado el
                    sitio web, así como las fechas de la primera y la última visita | CADUCIDAD: 2 años</li>
                <li>COOKIES: _gat | PROPIETARIO: Token house | FINALIDAD: RUtilizado por Google
                    Analytics para acelerar el ritmo de las solicitudes | CADUCIDAD: 1 día</li>
                <li>COOKIES: _gid | PROPIETARIO: Token house | FINALIDAD: Registra una
                    identificación única que se utiliza para generar datos estadísticos sobre el uso que el
                    visitante hace del sitio web | CADUCIDAD: 1 día</li>
                <li>COOKIES: c3po.jpg | PROPIETARIO: tracker.metricool.com | FINALIDAD: Recoge
                    datos sobre la navegación y el comportamiento del usuario en el sitio web. Se utiliza
                    para elaborar informes estadísticos y mapas de calor para el propietario del sitio web
                    | CADUCIDAD: De sesión</li>
            </ul>

            <h1 className='mt-5'>4.3 Cookies de marketing</h1>

            <p>Las cookies de marketing se utilizan para hacer un seguimiento de los usuarios que
                navegan en los sitios web. La intención es mostrar anuncios que sean relevantes para los
                usuarios y, por tanto, más valiosos para los terceros anunciantes.</p>

            <ul>
                <li>COOKIES: _fbp | PROPIETARIO: Token house | FINALIDAD: Utilizada por
                    Facebook para ofrecer contenidos publicitarios y otro tipo de información de terceros
                    anunciantes, en tiempo real, que pueda ser relevante para los Usuarios |
                    CADUCIDAD: 3 meses</li>
                <li>COOKIES: Ads/ga-audiences | PROPIETARIO: Google.com | FINALIDAD: Utilizado
                    por Google Ad Words para volver a captar visitantes que probablemente se
                    conviertan en clientes en función del comportamiento en línea del visitante en los
                    sitios web | CADUCIDAD: 6 meses</li>
                <li>COOKIES: fr | PROPIETARIO: Facebook | FINALIDAD: Utilizado por Facebook para
                    ofrecer una serie de productos publicitarios, como las ofertas en tiempo real de
                    terceros de terceros | CADUCIDAD: 3 meses</li>
                <li>COOKIES: tr | PROPIETARIO: Facebook | FINALIDAD: Utilizada por Facebook para
                    ofrecer contenidos publicitarios y otro tipo de información de terceros anunciantes,
                    en tiempo real, que pueda ser relevante para los Usuarios | CADUCIDAD: Sesión</li>
            </ul>

            <h1 className='mt-5'>5.- Configuración del usuario para evitar Cookies</h1>

            <p>En cumplimiento de la normativa vigente, el usuario puede configurar la instalación de
                Cookies en el Sitio Web o en la Aplicación en los canales específicamente habilitados.
                Mediante esta herramienta, el Usuario puede aceptar todas las Cookies, rechazarlas todas
                (en su caso, aquellas que no sean necesarias para el funcionamiento de la web) o
                configurarlas gradualmente.</p>

            <p>De igual manera, LA CASA DEL TOKEN pone a disposición de los usuarios del Sitio Web y
                de la Aplicación la información que les permita configurar su navegador/navegadores de
                Internet para mantener su privacidad y seguridad en relación con las Cookies. Por ello, se
                facilita la información y los enlaces a los sitios de soporte oficiales de los principales
                navegadores para que cada usuario pueda decidir si desea o no aceptar el uso de Cookies.</p>

            <p>Así, es posible bloquear las Cookies a través de las herramientas de configuración del
                navegador, o bien, cada usuario puede configurar su navegador para que le avise cuando
                un servidor quiera guardar una Cookie:</p>

            <h1 className='mt-5'>6.- Cookies en los dispositivos móviles</h1>

            <p>LA CASA DEL TOKEN también usa cookies u otros dispositivos de almacenamiento en
                dispositivos móviles.</p>

            <p>Aquellas cookies que no sean obligatorias para la navegación del presente Sitio Web
                podrán deshabilitarse en la configuración del Sitio Web.</p>

            <p>Estos ajustes se encuentran en el pie de página del Sitio Web. Además, al igual que sucede
                en los navegadores de ordenadores, lo navegadores de los dispositivos móviles permiten
                realizar cambios en las opciones o ajustes de privacidad para desactivar o eliminar las
                cookies.</p>

            <p>Si desea modificar las opciones de privacidad siga las instrucciones especificadas por el
                desarrollador de su navegador para dispositivo móvil.</p>

            <p>A continuación, podrá encontrar algunos ejemplos de los links que le guiarán para modificar
                las opciones de privacidad en su dispositivo móvil:</p>

            <ul>
                <li>IOS: <a href="http://goo.gl/61xevS">(http://goo.gl/61xevS)</a></li>
                <li>Windows Phone: <a href="https://goo.gl/tKyb0y">(https://goo.gl/tKyb0y)</a></li>
                <li>Chrome Mobile: <a href="http://goo.gl/XJp7N">(http://goo.gl/XJp7N)</a></li>
                <li>Opera Mobile: <a href="http://goo.gl/Nzr8s7">(http://goo.gl/Nzr8s7)</a></li>
            </ul>

            <h1 className='mt-5'>7.- Información en relación con sus datos</h1>

            <p>Para poder ejercitar cualquiera de sus derechos en materia de protección de datos, así
                como para poder conocer los (i) Fines del tratamiento y base jurídica del tratamiento; (ii)
                Destinatarios o categorías de destinatarios de los datos personales; (iii) Transferencias
                internacionales; (iv) Plazo de conservación; (v) Derechos de los interesados; (vi) Existencia
                de decisiones automatizadas, incluida la elaboración de perfiles, puede acceder a la Política
                de Privacidad de LA CASA DEL TOKEN <a href="https://thunderapp.es/">[https://thunderapp.es/]</a>.</p>

            <h1 className='mt-5'>8.- Modificaciones de la Política de Cookies</h1>

            <p>Esta Política de Cookies podría ser modificada en función de las exigencias legislativas,
                reglamentarias, o con la finalidad de adaptar dicha política a las instrucciones dictadas por
                la Agencia Española de Protección de Datos. Por ello, se aconseja a los usuarios que la
                visiten periódicamente. Cuando se produzcan cambios significativos en esta Política de
                Cookies, se comunicarán a los usuarios a través de correo electrónico.</p>

            <p>2022 © Todos los derechos reservados. THE TOKEN HOUSE S.L.</p>
            
            <p>Última actualización: 21 de abril de 2022.</p>
        </Container>
    );
}

export default Cookies;