import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button, ProgressBar, Placeholder, Nav, Tab } from 'react-bootstrap';
import './Dashboard.css';
import { HiChevronDoubleDown, HiOutlineLockClosed } from "react-icons/hi";
import SideMenu from '../../components/sidemenu/SideMenu';
import SearchMenu from '../../components/searchmenu/SearchMenu';
import { useQuery, gql } from '@apollo/client';
import AppSettings from '../../AppSettings';
import { IoLocationOutline } from "react-icons/io5";
import { toast } from 'react-toastify';
import Chart from "react-apexcharts";

// + IMAGES +
import blogCover from '../../assets/imgs/blog_cover.png';
import img_card from '../../assets/imgs/img_card.png';
import avatar from '../../assets/imgs/avatar.png';
// - IMAGES -

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

const Dashboard = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const options = {
        series: [{
            name: "STOCK ABC",
            data: [1, 2, 2, 3]
        }],
        options: {
            chart: {
                type: 'area',
                height: 350,
                foreColor: '#ffffff',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            theme: {
                monochrome: {
                    enabled: true,
                    color: '#FF0000',
                    shadeTo: 'dark',
                    shadeIntensity: 0.9
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                colors: ['#FF0000', '#FF6060', '#0C0C0C'],
            },

            title: {
                text: 'Dinero invertido',
                align: 'left',
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontFamily: 'Aileron',
                    color: '#FFF'
                }
            },
            subtitle: {
                text: 'Beneficio acumulado',
                align: 'left',
                style: {
                    fontSize: '14px',
                    fontWeight: 'light',
                    fontFamily: 'Aileron',
                    color: '#FFF'
                }
            },
            labels: ['06/03/2023', '07/03/2023', '08/03/2023', '09/03/2023'],
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                opposite: true
            },
            legend: {
                horizontalAlign: 'left'
            }
        },
    };

    const { settings } = AppSettings();

    // (+) Graphql

    const SEARCH_PROPERTIES_FEATURED = gql`${settings.graphql.query.searchPropertiesFeatured}`;

    const { data, loading, error } = useQuery(SEARCH_PROPERTIES_FEATURED);

    if (error) toast(error.message);

    // (-) Graphql 

    const onErrorCover = (error) => {
        error.target.src = blogCover;
    }

    const navigate = useNavigate();

    const goToPropertyDetail = (property) => {
        console.log(property.node);
        navigate(`/projecto/${property.node.projectSlug}`);
    }

    const getPropertyStatusUIClasses = (property) => {

        if (property.node.status == 'INI') return 'dot dot-subscription';
        if (property.node.status == 'CLO') return 'dot dot-finish';
        if (property.node.status == 'PRE') return 'dot dot-design';
        if (property.node.status == 'FUN') return 'dot dot-finish'; // FONDEADO
        return '';
    }

    const getPropertyStatusUILabel = (property) => {
        if (property.node.status == 'INI') return 'en SUBSCRIPCIÓN';
        if (property.node.status == 'CLO') return 'FINALIZADO';
        if (property.node.status == 'PRE') return 'en PREPARACIÓN';
        if (property.node.status == 'FUN') return 'FONDEADO'; // FONDEADO
        return '';
    }

    const truncate = function (str) {
        return str.length > 180 ? str.substring(0, 180) + "..." : str;
    }

    const truncateTitle = function (str) {
        return str.length > 45 ? str.substring(0, 45) + "..." : str;
    }

    const numberFormat = function (number) {// floating point example
        return number.toLocaleString(undefined, { maximumFractionDigits: 2 })
    }

    return (
        <>
            <SideMenu />
            <div className='position-relative overflow-hidden container-fluid pb-5'>
                <SearchMenu />
                <div className='container-left-dash-a'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-left-dash-b'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-center-dash'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-right-dash-a'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-right-dash-b'>
                    <div className='ball-c'></div>
                </div>

                <Row className='p-4 pb-5 pt-5'>
                    <Col xs={12} md={3}></Col>
                    <Col xs={12} md={6}>
                        <Tab.Container defaultActiveKey="investments">
                            <div className='main-chart'>
                                <h4 className='my-investment pt-3'>Mi inversión</h4>
                                <Nav>
                                    <Nav.Item>
                                        <Nav.Link eventKey="investments" className='detail-tab pointer'>Todas mis inversiones</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="actives" className='detail-tab pointer'>Inversiones activas</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="finish" className='detail-tab pointer'>Inversiones Finalizadas</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="investments" className='animate__animated animate__fadeInRight animate__faster'>
                                        <Row className='mt-3 item'>
                                            <Col xs={12} md={3}>
                                                <img src={img_card} alt="" />
                                            </Col>
                                            <Col xs={12} md={9}>
                                                <div className='d-flex align-items-center'>
                                                    <span className="dot2 activa"></span>
                                                    <p className='text-white text-uppercase m-0 font-chip'>ACTIVA</p>
                                                </div>
                                                <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                                <Row className='align-items-center'>
                                                    <Col>
                                                        <p className='minitext'>Dinero invertido</p>
                                                    </Col>
                                                    <Col>
                                                        <p className='minitext'>Rentabilidad</p>
                                                    </Col>
                                                    <Col>
                                                        <p className='minitext'>Beneficio acumulado</p>
                                                    </Col>
                                                </Row>
                                                <Row className='align-items-center'>
                                                    <Col>
                                                        <p className='value'>10.000€</p>
                                                    </Col>
                                                    <Col>
                                                        <p className='value-success'>10%</p>
                                                    </Col>
                                                    <Col>
                                                        <p className='value-success'>1.000€</p>
                                                    </Col>
                                                </Row>

                                                <Row className='justify-content-end'>
                                                    <Col xs={12} md={4}>
                                                        <Button variant='outline-light' className='btn-gb text-uppercase btn-global btn-detail'>Detalle</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <hr className='line-item' />

                                        <Row className='mt-3 item'>
                                            <Col xs={12} md={3}>
                                                <img src={img_card} alt="" />
                                            </Col>
                                            <Col xs={12} md={9}>
                                                <div className='d-flex align-items-center'>
                                                    <span className="dot2 finish-status"></span>
                                                    <p className='text-white text-uppercase m-0 font-chip'>FINALIZADA</p>
                                                </div>
                                                <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                                <Row className='align-items-center'>
                                                    <Col>
                                                        <p className='minitext'>Dinero invertido</p>
                                                    </Col>
                                                    <Col>
                                                        <p className='minitext'>Rentabilidad</p>
                                                    </Col>
                                                    <Col>
                                                        <p className='minitext'>Beneficio acumulado</p>
                                                    </Col>
                                                </Row>
                                                <Row className='align-items-center'>
                                                    <Col>
                                                        <p className='value'>10.000€</p>
                                                    </Col>
                                                    <Col>
                                                        <p className='value-success'>8%</p>
                                                    </Col>
                                                    <Col>
                                                        <p className='value-success'>800€</p>
                                                    </Col>
                                                </Row>

                                                <Row className='justify-content-end'>
                                                    <Col xs={12} md={4}>
                                                        <Button variant='outline-light' className='btn-gb text-uppercase btn-global btn-detail'>Detalle</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <hr className='line-item' />

                                        <Row className='mt-3 item'>
                                            <Col xs={12} md={3}>
                                                <img src={img_card} alt="" />
                                            </Col>
                                            <Col xs={12} md={9}>
                                                <div className='d-flex align-items-center'>
                                                    <span className="dot2 activa"></span>
                                                    <p className='text-white text-uppercase m-0 font-chip'>ACTIVA</p>
                                                </div>
                                                <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                                <Row className='align-items-center'>
                                                    <Col>
                                                        <p className='minitext'>Dinero invertido</p>
                                                    </Col>
                                                    <Col>
                                                        <p className='minitext'>Rentabilidad</p>
                                                    </Col>
                                                    <Col>
                                                        <p className='minitext'>Beneficio acumulado</p>
                                                    </Col>
                                                </Row>
                                                <Row className='align-items-center'>
                                                    <Col>
                                                        <p className='value'>10.000€</p>
                                                    </Col>
                                                    <Col>
                                                        <p className='value-success'>10%</p>
                                                    </Col>
                                                    <Col>
                                                        <p className='value-success'>1.000€</p>
                                                    </Col>
                                                </Row>

                                                <Row className='justify-content-end'>
                                                    <Col xs={12} md={4}>
                                                        <Button variant='outline-light' className='btn-gb text-uppercase btn-global btn-detail'>Detalle</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </Col>
                    <Col xs={12} md={3}>
                        <div className='second-chart'>
                            <h4 className='my-investment pt-3'>Resumen</h4>

                            <Row className='align-items-center ps-3'>
                                <Col xs={12} md={2}>
                                    <img src={avatar} className='avatar' alt="" />
                                </Col>
                                <Col xs={12} md={10}>
                                    <label>
                                        <p className='m-0 minitext'>Nº Proyectos</p>
                                        <p className='value'>12</p>
                                    </label>
                                </Col>
                                <Col xs={12} md={6}>
                                    <label>
                                        <div className='d-flex align-items-center'>
                                            <span className="dot2 activa"></span>
                                            <p className='text-white text-uppercase m-0 font-chip'>ACTIVOS</p>
                                        </div>
                                        <p className='value'>2</p>
                                    </label>
                                </Col>
                                <Col xs={12} md={6}>
                                    <label>
                                        <div className='d-flex align-items-center'>
                                            <span className="dot2 finish-status"></span>
                                            <p className='text-white text-uppercase m-0 font-chip'>FINALIZADOS</p>
                                        </div>
                                        <p className='value'>2</p>
                                    </label>
                                </Col>
                            </Row>

                            <hr className='line-item' />

                            <div id="chart">
                                <Chart
                                    options={options.options}
                                    series={options.series}
                                    type="area"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={3}></Col>
                    <Col xs={12} md={9}>
                        <h2 className='subtitle'>Proyectos destacados</h2>
                        <h3 className='subtitle-text'>Lorem Impsum dolor sit amet, consectetur.</h3>
                        <p className='text-gb'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis quisque tincidunt vitae consequat platea pellentesque feugiat turpis. Vitae non adipiscing consequat.s</p>

                        <div className='mb-4 mt-4 '>
                            <Swiper
                                cssMode={true}
                                navigation={true}
                                pagination={{
                                    clickable: true,
                                }}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Navigation, Mousewheel, Keyboard, Pagination]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    1300: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                }}
                                className="swipper-cards-proyects"
                            >
                                {!data &&
                                    <>
                                        <SwiperSlide position-relative>
                                            <Card className='container-card-white'>
                                                <div className='container-img-card'>
                                                    <div className='mascara'>
                                                        <Card.Img variant="top" src={blogCover} />
                                                    </div>
                                                </div>
                                                <Card.Body>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" xs={6} />
                                                    </Placeholder>
                                                    <Placeholder as={Card.Text} animation="glow">
                                                        <Placeholder bg="light" xs={7} /> <Placeholder bg="light" xs={4} /> <Placeholder bg="light" xs={4} />{' '}
                                                    </Placeholder>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" lg={8} />
                                                    </Placeholder>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" lg={8} />
                                                    </Placeholder>
                                                    <Row className='mt-4'>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mt-4'>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder.Button variant="light" xs={12} />
                                                            </Placeholder>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>

                                            <div className='custom-card-white'></div>
                                        </SwiperSlide>

                                        <SwiperSlide position-relative>
                                            <Card className='container-card-white'>
                                                <div className='container-img-card'>
                                                    <div className='mascara'>
                                                        <Card.Img variant="top" src={blogCover} />
                                                    </div>
                                                </div>
                                                <Card.Body>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" xs={6} />
                                                    </Placeholder>
                                                    <Placeholder as={Card.Text} animation="glow">
                                                        <Placeholder bg="light" xs={7} /> <Placeholder bg="light" xs={4} /> <Placeholder bg="light" xs={4} />{' '}
                                                    </Placeholder>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" lg={8} />
                                                    </Placeholder>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" lg={8} />
                                                    </Placeholder>
                                                    <Row className='mt-4'>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mt-4'>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder.Button variant="light" xs={12} />
                                                            </Placeholder>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>

                                            <div className='custom-card-white'></div>
                                        </SwiperSlide>

                                        <SwiperSlide position-relative>
                                            <Card className='container-card-white'>
                                                <div className='container-img-card'>
                                                    <div className='mascara'>
                                                        <Card.Img variant="top" src={blogCover} />
                                                    </div>
                                                </div>
                                                <Card.Body>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" xs={6} />
                                                    </Placeholder>
                                                    <Placeholder as={Card.Text} animation="glow">
                                                        <Placeholder bg="light" xs={7} /> <Placeholder bg="light" xs={4} /> <Placeholder bg="light" xs={4} />{' '}
                                                    </Placeholder>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" lg={8} />
                                                    </Placeholder>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" lg={8} />
                                                    </Placeholder>
                                                    <Row className='mt-4'>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mt-4'>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder.Button variant="light" xs={12} />
                                                            </Placeholder>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>

                                            <div className='custom-card-white'></div>
                                        </SwiperSlide>
                                    </>
                                }

                                {data && data.searchProperties?.edges.map((property) => (
                                    <SwiperSlide position-relative>

                                        <Card className='container-card-white'>
                                            <div className='container-img-card'>
                                                <div className='chip'>
                                                    <span className={getPropertyStatusUIClasses(property)}></span>
                                                    <p className='text-white text-uppercase m-0 font-chip'>{getPropertyStatusUILabel(property)}</p>
                                                </div>
                                                <div className='mascara'>
                                                    <Card.Img src={property.node.coverPhotoFullPath} onError={onErrorCover} />
                                                </div>
                                            </div>

                                            <Card.Body>
                                                <Card.Title className='text-white'>{truncateTitle(property.node.projectName)}</Card.Title>
                                                <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> {property.node.location}</Card.Subtitle>
                                                <Row>
                                                    <Col xs={6} md={6}>
                                                        <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                        <p className='text-gb custom-bold text-uppercase'>{numberFormat(property.node.investmentEstimatedYield)} %</p>
                                                    </Col>
                                                    <Col xs={6} md={6}>
                                                        <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                        <p className='text-gb custom-bold'>{property.node.executionTime} meses</p>
                                                    </Col>
                                                </Row>
                                                <Card.Text className='custom-ligth'>
                                                    {truncate(property.node.description)}
                                                </Card.Text>

                                                <ProgressBar now={property.node.raisedAmmount / property.node.targetGoal * 100} className='custom-progress mt-4' />
                                                <Row>
                                                    <Col xs={6} md={6}>
                                                        <p className='text-gb mt-1'>{numberFormat(property.node.raisedAmmount / property.node.targetGoal * 100)} %</p>
                                                    </Col>
                                                    <Col xs={6} md={6}>
                                                        <p className='text-gb mt-1 text-end'>{numberFormat(property.node.targetGoal)} €</p>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xs={6} md={6}>
                                                        <label>
                                                            <p className='custom-ligth mb-1 '>Inversión mínima</p>
                                                            <p className='text-gb custom-bold text-end'>{numberFormat(property.node.minInvestment)} €</p>
                                                        </label>
                                                    </Col>
                                                    <Col xs={6} md={6}>
                                                        <div className='d-flex justify-content-end'>
                                                            <Button variant='outline-light' className='btn-gb text-uppercase btn-global btn-detail' onClick={() => goToPropertyDetail(property)}>Detalle</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>

                                        <div className='custom-card-white'></div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>

                        <div className='pt-4 mb-4 mt-4'>
                            <Button variant='outline-light m-auto btn-solid custom-ma' className='btn-gb text-uppercase'>
                                Ver más proyectos
                            </Button>
                        </div>
                    </Col>
                </Row>

            </div>
        </>
    );
}

export default Dashboard;