import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ProgressBar, Row, Col, Button, Card, Pagination } from 'react-bootstrap';
import './Projects.css';
import { IoLocationOutline } from "react-icons/io5";
import SideMenu from '../../components/sidemenu/SideMenu';
import SearchMenu from '../../components/searchmenu/SearchMenu';

// + IMAGES +
import card from '../../assets/imgs/img_card.png';
// - IMAGES -

const Projects = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <SideMenu />
            <div className='position-relative overflow-hidden container-fluid pb-5'>
                <SearchMenu />
                <div className='container-left-dash-a'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-left-dash-b'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-center-dash'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-right-dash-a'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-right-dash-b'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-right-dash-c'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-right-dash-d'>
                    <div className='ball-c'></div>
                </div>

                <Row className='align-items-center mt-5'>
                    <Col xs={12} md={3}></Col>
                    <Col xs={12} md={3}>
                        <div className='position-relative'>
                            <Card className='container-card-white card-investment2'>
                                <div className='container-img-card'>
                                    <div className='chip'>
                                        <span className="dot dot-subscription"></span>
                                        <p className='text-white text-uppercase m-0 font-chip'>en SUBSCRIPCIÓN</p>
                                    </div>
                                    <div className='mascara'>
                                        <Card.Img src={card} />
                                    </div>
                                </div>

                                <Card.Body>
                                    <Card.Title className='text-white'>Inmueble</Card.Title>
                                    <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                            <p className='text-gb custom-bold text-uppercase'>8%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                            <p className='text-gb custom-bold'>24 meses</p>
                                        </Col>
                                    </Row>
                                    <Card.Text className='custom-ligth'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                    </Card.Text>

                                    <ProgressBar now={50} className='custom-progress mt-4' />
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1'>50%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={6} md={6}>
                                            <label>
                                                <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                <p className='text-gb custom-bold text-end'>100 €</p>
                                            </label>
                                        </Col>
                                        <Col xs={6} md={6} className='text-end'>
                                            <div className='d-flex justify-content-end'>
                                                <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail'>Detalle</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            <div className='custom-card-white'></div>
                        </div>
                    </Col>

                    <Col xs={12} md={3} className='mt-4'>
                        <div className='position-relative'>
                            <Card className='container-card-white card-investment2'>
                                <div className='container-img-card'>
                                    <div className='chip'>
                                        <span className="dot dot-subscription"></span>
                                        <p className='text-white text-uppercase m-0 font-chip'>en SUBSCRIPCIÓN</p>
                                    </div>
                                    <div className='mascara'>
                                        <Card.Img src={card} />
                                    </div>
                                </div>

                                <Card.Body>
                                    <Card.Title className='text-white'>Inmueble</Card.Title>
                                    <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                            <p className='text-gb custom-bold text-uppercase'>8%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                            <p className='text-gb custom-bold'>24 meses</p>
                                        </Col>
                                    </Row>
                                    <Card.Text className='custom-ligth'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                    </Card.Text>

                                    <ProgressBar now={50} className='custom-progress mt-4' />
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1'>50%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={6} md={6}>
                                            <label>
                                                <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                <p className='text-gb custom-bold text-end'>100 €</p>
                                            </label>
                                        </Col>
                                        <Col xs={6} md={6} className='text-end'>
                                            <div className='d-flex justify-content-end'>
                                                <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail'>Detalle</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            <div className='custom-card-white'></div>
                        </div>
                    </Col>

                    <Col xs={12} md={3} className='mt-4'>
                        <div className='position-relative'>
                            <Card className='container-card-white card-investment2'>
                                <div className='container-img-card'>
                                    <div className='chip'>
                                        <span className="dot dot-subscription"></span>
                                        <p className='text-white text-uppercase m-0 font-chip'>en SUBSCRIPCIÓN</p>
                                    </div>
                                    <div className='mascara'>
                                        <Card.Img src={card} />
                                    </div>
                                </div>

                                <Card.Body>
                                    <Card.Title className='text-white'>Inmueble</Card.Title>
                                    <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                            <p className='text-gb custom-bold text-uppercase'>8%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                            <p className='text-gb custom-bold'>24 meses</p>
                                        </Col>
                                    </Row>
                                    <Card.Text className='custom-ligth'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                    </Card.Text>

                                    <ProgressBar now={50} className='custom-progress mt-4' />
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1'>50%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={6} md={6}>
                                            <label>
                                                <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                <p className='text-gb custom-bold text-end'>100 €</p>
                                            </label>
                                        </Col>
                                        <Col xs={6} md={6} className='text-end'>
                                            <div className='d-flex justify-content-end'>
                                                <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail'>Detalle</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            <div className='custom-card-white'></div>
                        </div>
                    </Col>
                </Row>

                <Row className='align-items-center mt-5'>
                    <Col xs={12} md={3}></Col>
                    <Col xs={12} md={3}>
                        <div className='position-relative'>
                            <Card className='container-card-white card-investment2'>
                                <div className='container-img-card'>
                                    <div className='chip'>
                                        <span className="dot dot-subscription"></span>
                                        <p className='text-white text-uppercase m-0 font-chip'>en SUBSCRIPCIÓN</p>
                                    </div>
                                    <div className='mascara'>
                                        <Card.Img src={card} />
                                    </div>
                                </div>

                                <Card.Body>
                                    <Card.Title className='text-white'>Inmueble</Card.Title>
                                    <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                            <p className='text-gb custom-bold text-uppercase'>8%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                            <p className='text-gb custom-bold'>24 meses</p>
                                        </Col>
                                    </Row>
                                    <Card.Text className='custom-ligth'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                    </Card.Text>

                                    <ProgressBar now={50} className='custom-progress mt-4' />
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1'>50%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={6} md={6}>
                                            <label>
                                                <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                <p className='text-gb custom-bold text-end'>100 €</p>
                                            </label>
                                        </Col>
                                        <Col xs={6} md={6} className='text-end'>
                                            <div className='d-flex justify-content-end'>
                                                <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail'>Detalle</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            <div className='custom-card-white'></div>
                        </div>
                    </Col>

                    <Col xs={12} md={3} className='mt-4'>
                        <div className='position-relative'>
                            <Card className='container-card-white card-investment2'>
                                <div className='container-img-card'>
                                    <div className='chip'>
                                        <span className="dot dot-subscription"></span>
                                        <p className='text-white text-uppercase m-0 font-chip'>en SUBSCRIPCIÓN</p>
                                    </div>
                                    <div className='mascara'>
                                        <Card.Img src={card} />
                                    </div>
                                </div>

                                <Card.Body>
                                    <Card.Title className='text-white'>Inmueble</Card.Title>
                                    <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                            <p className='text-gb custom-bold text-uppercase'>8%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                            <p className='text-gb custom-bold'>24 meses</p>
                                        </Col>
                                    </Row>
                                    <Card.Text className='custom-ligth'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                    </Card.Text>

                                    <ProgressBar now={50} className='custom-progress mt-4' />
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1'>50%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={6} md={6}>
                                            <label>
                                                <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                <p className='text-gb custom-bold text-end'>100 €</p>
                                            </label>
                                        </Col>
                                        <Col xs={6} md={6} className='text-end'>
                                            <div className='d-flex justify-content-end'>
                                                <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail'>Detalle</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            <div className='custom-card-white'></div>
                        </div>
                    </Col>

                    <Col xs={12} md={3} className='mt-4'>
                        <div className='position-relative'>
                            <Card className='container-card-white card-investment2'>
                                <div className='container-img-card'>
                                    <div className='chip'>
                                        <span className="dot dot-subscription"></span>
                                        <p className='text-white text-uppercase m-0 font-chip'>en SUBSCRIPCIÓN</p>
                                    </div>
                                    <div className='mascara'>
                                        <Card.Img src={card} />
                                    </div>
                                </div>

                                <Card.Body>
                                    <Card.Title className='text-white'>Inmueble</Card.Title>
                                    <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                            <p className='text-gb custom-bold text-uppercase'>8%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                            <p className='text-gb custom-bold'>24 meses</p>
                                        </Col>
                                    </Row>
                                    <Card.Text className='custom-ligth'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                    </Card.Text>

                                    <ProgressBar now={50} className='custom-progress mt-4' />
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1'>50%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={6} md={6}>
                                            <label>
                                                <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                <p className='text-gb custom-bold text-end'>100 €</p>
                                            </label>
                                        </Col>
                                        <Col xs={6} md={6} className='text-end'>
                                            <div className='d-flex justify-content-end'>
                                                <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail'>Detalle</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            <div className='custom-card-white'></div>
                        </div>
                    </Col>
                </Row>

                <Row className='align-items-center mt-5'>
                    <Col xs={12} md={3}></Col>
                    <Col xs={12} md={3}>
                        <div className='position-relative'>
                            <Card className='container-card-white card-investment2'>
                                <div className='container-img-card'>
                                    <div className='chip'>
                                        <span className="dot dot-subscription"></span>
                                        <p className='text-white text-uppercase m-0 font-chip'>en SUBSCRIPCIÓN</p>
                                    </div>
                                    <div className='mascara'>
                                        <Card.Img src={card} />
                                    </div>
                                </div>

                                <Card.Body>
                                    <Card.Title className='text-white'>Inmueble</Card.Title>
                                    <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                            <p className='text-gb custom-bold text-uppercase'>8%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                            <p className='text-gb custom-bold'>24 meses</p>
                                        </Col>
                                    </Row>
                                    <Card.Text className='custom-ligth'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                    </Card.Text>

                                    <ProgressBar now={50} className='custom-progress mt-4' />
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1'>50%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={6} md={6}>
                                            <label>
                                                <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                <p className='text-gb custom-bold text-end'>100 €</p>
                                            </label>
                                        </Col>
                                        <Col xs={6} md={6} className='text-end'>
                                            <div className='d-flex justify-content-end'>
                                                <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail'>Detalle</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            <div className='custom-card-white'></div>
                        </div>
                    </Col>

                    <Col xs={12} md={3} className='mt-4'>
                        <div className='position-relative'>
                            <Card className='container-card-white card-investment2'>
                                <div className='container-img-card'>
                                    <div className='chip'>
                                        <span className="dot dot-subscription"></span>
                                        <p className='text-white text-uppercase m-0 font-chip'>en SUBSCRIPCIÓN</p>
                                    </div>
                                    <div className='mascara'>
                                        <Card.Img src={card} />
                                    </div>
                                </div>

                                <Card.Body>
                                    <Card.Title className='text-white'>Inmueble</Card.Title>
                                    <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                            <p className='text-gb custom-bold text-uppercase'>8%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                            <p className='text-gb custom-bold'>24 meses</p>
                                        </Col>
                                    </Row>
                                    <Card.Text className='custom-ligth'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                    </Card.Text>

                                    <ProgressBar now={50} className='custom-progress mt-4' />
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1'>50%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={6} md={6}>
                                            <label>
                                                <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                <p className='text-gb custom-bold text-end'>100 €</p>
                                            </label>
                                        </Col>
                                        <Col xs={6} md={6} className='text-end'>
                                            <div className='d-flex justify-content-end'>
                                                <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail'>Detalle</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            <div className='custom-card-white'></div>
                        </div>
                    </Col>

                    <Col xs={12} md={3} className='mt-4'>
                        <div className='position-relative'>
                            <Card className='container-card-white card-investment2'>
                                <div className='container-img-card'>
                                    <div className='chip'>
                                        <span className="dot dot-subscription"></span>
                                        <p className='text-white text-uppercase m-0 font-chip'>en SUBSCRIPCIÓN</p>
                                    </div>
                                    <div className='mascara'>
                                        <Card.Img src={card} />
                                    </div>
                                </div>

                                <Card.Body>
                                    <Card.Title className='text-white'>Inmueble</Card.Title>
                                    <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                            <p className='text-gb custom-bold text-uppercase'>8%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                            <p className='text-gb custom-bold'>24 meses</p>
                                        </Col>
                                    </Row>
                                    <Card.Text className='custom-ligth'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                    </Card.Text>

                                    <ProgressBar now={50} className='custom-progress mt-4' />
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1'>50%</p>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={6} md={6}>
                                            <label>
                                                <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                <p className='text-gb custom-bold text-end'>100 €</p>
                                            </label>
                                        </Col>
                                        <Col xs={6} md={6} className='text-end'>
                                            <div className='d-flex justify-content-end'>
                                                <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail'>Detalle</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            <div className='custom-card-white'></div>
                        </div>
                    </Col>
                </Row>

                <Row className='justify-content-center mt-5'>
                    <Col xs={12} md={3}></Col>
                    <Col xs={12} md={3}></Col>
                    <Col xs={12} md={3} className='text-center'>
                        <Pagination className='paginations'>

                            <Pagination.First />

                            <Pagination.Ellipsis />

                            <Pagination.Item active>{3}</Pagination.Item>
                            <Pagination.Item>{4}</Pagination.Item>
                            <Pagination.Item>{5}</Pagination.Item>
                            <Pagination.Item>{6}</Pagination.Item>
                            <Pagination.Item>{7}</Pagination.Item>

                            <Pagination.Ellipsis />

                            <Pagination.Last />

                        </Pagination>
                    </Col>
                    <Col xs={12} md={3}></Col>
                </Row>
            </div>
        </>
    );
}

export default Projects;