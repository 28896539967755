const AppSettings = () => {

    const settings = {

        googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,

        graphql: {
            url: process.env.REACT_APP_GRAPHQL_URL,
            
            query: {

                searchPropertiesFeatured: `
                                {
                                    searchProperties (isFeatured: true){
                                        edges{
                                            node{
                                                coverPhotoFullPath
                                                description
                                                executionTime
                                                id
                                                investmentEstimatedYield
                                                location
                                                maxDate
                                                minInvestment
                                                projectName
                                                projectSlug
                                                raisedAmmount
                                                status
                                                targetGoal
                                            }, cursor
                                        }, pageInfo {
                                            startCursor
                                            endCursor
                                            hasNextPage
                                            hasPreviousPage
                                        }
                                    }
                                }
                `,

                getPropertyBySlug: `
                    query (
                        $slug: String!
                    ) {
                        searchProperties (projectSlug: $slug){
                            edges{
                                node{
                                    coverPhotoFullPath
                                    description
                                    executionTime
                                    id
                                    investmentEstimatedYield
                                    latitude
                                    location
                                    longitude
                                    maxDate
                                    minInvestment
                                    projectName
                                    projectSlug
                                    projectTypeText
                                    raisedAmmount
                                    status
                                    targetGoal
                                    raisedAmmount
                                    shortDescription
                                    status
                                    targetGoal
                                }, cursor
                            }, pageInfo {
                                startCursor
                                endCursor
                                hasNextPage
                                hasPreviousPage
                            }
                        }
                    }
                `,

                searchArticles: `
                                query (
                                    $offset: Int!
                                    $limit: Int
                                ) {
                                    searchArticles (offset: $offset, first: $limit, state: "1"){
                                        edges{
                                            node{
                                            id
                                            title
                                            coverPhoto
                                            coverPhotoFullPath
                                            state
                                            articleDate
                                            slug
                                            }, cursor
                                        }, pageInfo {
                                            startCursor
                                            endCursor
                                            hasNextPage
                                            hasPreviousPage
                                        }
                                    }                
                                }
                `,


                getArticleBySlug: `
                                query (
                                    $slug: String!
                                ) {
                                    searchArticles (slug: $slug) {
                                        edges{
                                            node{
                                                articleDate
                                                body
                                                coverPhoto
                                                id
                                                slug
                                                state
                                                subtitle
                                                title
                                                coverPhotoFullPath
                                            }, cursor
                                        }, pageInfo {
                                            startCursor
                                            endCursor
                                            hasNextPage
                                            hasPreviousPage
                                        }
                                    }                
                                }
                `,
            }
            ,

            mutation: {
                
                sendContact: `
                                mutation (
                                        $comercialComunications: Boolean,
                                        $email: String!,
                                        $message: String!,
                                        $name: String!,
                                        $subject: String!
                                ) {
                                    sendContact(
                                        comercialComunications: $comercialComunications,
                                        email: $email,
                                        message: $message,
                                        name: $name,
                                        subject: $subject
                                    ) {
                                        ok
                                    }
                                }
                            `
                ,

                subscribeNewsletter: `
                                mutation (
                                        $comercialComunications: Boolean,
                                        $email: String!
                                ) {
                                    subscribeNewsletter(
                                        comercialComunications: $comercialComunications,
                                        email: $email
                                    ) {
                                        ok
                                    }
                                }
                            `

            }
        }
        
    };

    return { settings };
}

export default AppSettings;