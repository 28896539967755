import React, { useState, useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button, ProgressBar, Placeholder } from 'react-bootstrap';
import './Home.css';
import { HiChevronDoubleDown } from "react-icons/hi";
import { IoLocationOutline } from "react-icons/io5";
import { RiBuildingLine } from "react-icons/ri";
import Fab from '../../components/fab/Fab';
import NewsLetter from '../../components/newsletter/Newsletter';
import { toast } from 'react-toastify';
import { useQuery, gql } from '@apollo/client';
import AppSettings from '../../AppSettings';
import Moment from 'react-moment';



import 'animate.css';

// + IMAGES +
import trama from '../../assets/imgs/bg_vector.png';
import logo from '../../assets/imgs/logo_blanco.svg';
import formLeft from '../../assets/imgs/form_left.svg';
import imgCardHeader from '../../assets/imgs/img_card.png';
import book from '../../assets/imgs/book.svg';
import kyc from '../../assets/imgs/kyc.svg';
import wallet from '../../assets/imgs/wallet.svg';
import settingsImg from '../../assets/imgs/settings.svg';
import chart from '../../assets/imgs/chart.svg';
import bitcoin from '../../assets/imgs/bitcoin.svg';
// import bitcoin from '../../assets/imgs/bitcoin.svg';
import swiper1 from '../../assets/imgs/swiper1.svg';
import swiper2 from '../../assets/imgs/swiper2.svg';
import swiper3 from '../../assets/imgs/swiper3.svg';
import discY from '../../assets/imgs/disc_yellow.svg';
import discG from '../../assets/imgs/disc_green.svg';
import discC from '../../assets/imgs/disc_cyan.svg';
import criptoY from '../../assets/imgs/cripto_amarillo_gif.gif';
import criptoC from '../../assets/imgs/cripto_aguamarina_gif.gif';
import criptoG from '../../assets/imgs/cripto_verde_gif.gif';
import formRight from '../../assets/imgs/form_right.svg';
import vectorLeft from '../../assets/imgs/vector_left_a.svg';
import blogCover from '../../assets/imgs/blog_cover.png';
// - IMAGES -

// + VIDEOS +
import t_video from '../../assets/videos/desfragmentacion.webm';
// - VIDEOS -


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const { settings } = AppSettings();


    // (+) Graphql

    const SEARCH_PROPERTIES_FEATURED = gql`${settings.graphql.query.searchPropertiesFeatured}`;

    const { data, loading, error } = useQuery(SEARCH_PROPERTIES_FEATURED);

    if (error) toast(error.message);

    // (-) Graphql


    const onErrorCover = (error) => {
        error.target.src = blogCover;
    }

    const ref = useRef(null);

    const loop = () => {
        if (ref && ref.current && ref.current.play)
            ref.current.play();
    };

    const [card, setCard] = useState(false);

    const navigate = useNavigate();

    const goToHowWorks = () => {
        navigate('/como-invertir-en-inmuebles-tokenizados');
    }

    const goToContact = () => {
        navigate('/contacto');
    }

    const goToPropertyDetail = (property) => {
        console.log(property.node);
        navigate(`/projecto/${property.node.projectSlug}`); 
    }

    const goToFaqs = () => {
        navigate('/faqs-inversion-inmuebles-tokenizados');
    }

    const goToPartners = () => {
        navigate('/equipo-casa-del-token');
    }

    //scroll
    const [scrollDir, setScrollDir] = useState("scrolling down");
    useEffect(() => {
        const threshold = 0;
        let lastScrollY = window.pageYOffset;
        let ticking = false;
        let onTop = true;
        const updateScrollDir = () => {
            const scrollY = window.pageYOffset;

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setScrollDir(scrollY > lastScrollY ? "down" : "up");
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
            if (window.pageYOffset <= 0) {
                onTop = true;
            }
            else if (onTop && window.pageYOffset > 0) {
                onTop = false;
                loop();
            }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollDir]);

    const getPropertyStatusUIClasses = (property) => {

        if (property.node.status == 'INI') return 'dot dot-subscription';
        if (property.node.status == 'CLO') return 'dot dot-finish';
        if (property.node.status == 'PRE') return 'dot dot-design';
        if (property.node.status == 'FUN') return 'dot dot-finish'; // FONDEADO
        return '';
    }

    const getPropertyStatusUILabel = (property) => {
        if (property.node.status == 'INI') return 'en SUBSCRIPCIÓN';
        if (property.node.status == 'CLO') return 'FINALIZADO';
        if (property.node.status == 'PRE') return 'en PREPARACIÓN';
        if (property.node.status == 'FUN') return 'FONDEADO'; // FONDEADO
        return '';
    }

    const truncate = function (str) {
        return str.length > 180 ? str.substring(0, 180) + "..." : str;
    }

    const truncateTitle = function (str) {
        return str.length > 45 ? str.substring(0, 45) + "..." : str;
    }

    const numberFormat = function (number) {// floating point example
        return number.toLocaleString(undefined, { maximumFractionDigits: 2 })
    }

    return (
        <>
            <div className='position-relative overflow-hidden container-fluid pb-5 pt-5'>
                <Fab />
                <div className='container-trama'>
                    <img src={trama} className='trama' alt="" />
                </div>

                <div className='container-fluid position-relative'>
                    <div className='container-vector-left'>
                        <img src={formLeft} alt="" className='vector-responsive' />
                    </div>
                    <div className='container-lg p-none position-relative'>
                        <div className="container-right">
                            <div className='ball-right'></div>
                        </div>
                        <div className="container-top">
                            <div className='ball-top'></div>
                        </div>
                        <div className='container-sm p-none'>
                            <div className='ps-3 pe-3 pb-5 pt-5'>
                                <Row className='align-items-center justify-content-center'>
                                    <Col xs={{ span: 12, order: 2 }} md={{ span: 4, order: 1 }}>
                                        <div className='position-relative'>
                                            <h1 className='title d-none d-md-block text-uppercase'>la casa del token</h1>
                                            <span className='text-line'></span>
                                        </div>
                                        <h2 className='subtitle'>Invierte en inmuebles tokenizados</h2>
                                        <p className='text-gb '>Invierte mediante <strong>Crypto y Euro</strong> con tecnología <strong>blockchain</strong> a través <br /> de <strong>NFT</strong> y <strong>Smart Contracts</strong></p>

                                        <Row className='align-items-center mt-5'>
                                            <Col xs={12} md={6} className='mt-3'>
                                                <Button variant='outline-light' className='btn-gb w-100 btn-global btn-border-light size-btn-home' onClick={goToContact}>CONTÁCTANOS</Button>
                                            </Col>
                                            <Col xs={12} md={6} className='mt-3'>
                                                <Button variant='outline-light' className='btn-gb w-100 btn-global btn-border-light size-btn-home' href='#properties'>PROPIEDADES</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={{ span: 12, order: 1 }} md={{ span: 8, order: 2 }}>
                                        <div className='card-blur-reounded'>
                                            <video id="video"
                                                ref={ref}
                                                muted width="120%" height="120%" className='animation'>
                                                <source src={t_video} type="video/webm" />
                                            </video>
                                            <img src={logo} alt="" className='m-auto logotype' />
                                            <HiChevronDoubleDown className='btn-mb d-md-none text-light' />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='container-fluid position-relative'>
                    <div className='container-vector-right'>
                        <img src={formRight} alt="" className='vector-responsive' />
                    </div>
                    <div className='container-lg p-none position-relative grl-mb' id='properties'>
                        <div className='container-left-c'>
                            <div className='ball-c'></div>
                        </div>
                        <div className="container-right-b">
                            <div className='ball-c'></div>
                        </div>

                        <Row>
                            <Col xxl={{ span: 12, order: 1 }}>
                                <h2 className='subtitle'>¡Proyectos <br /> de inversión</h2>
                                <h3 className='subtitle-text'>Invierte en inmuebles desde 100€!</h3>
                                <Row className='align-items-center mt-4'>
                                    <Col xs={12} md={4}>
                                        <p className='text-gb'>La Casa del Token está analizando el mercado y en búsqueda continua para encontrar los mejores proyectos de inversión. 
                                        ¿Cansado de no poder invertir por los tickets mínimos de más de 500.000€? ¡Invierte con nosotros desde tan solo 100€!</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className='mb-4 mt-4 '>
                            <Swiper
                                cssMode={true}
                                navigation={true}
                                pagination={{
                                    clickable: true,
                                }}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Navigation, Mousewheel, Keyboard, Pagination]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    1300: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                }}
                                className="swipper-cards-proyects"
                            >
                                {!data &&
                                    <>
                                        <SwiperSlide position-relative>
                                            <Card className='container-card-white'>
                                                <div className='container-img-card'>
                                                    <div className='mascara'>
                                                        <Card.Img variant="top" src={blogCover} />
                                                    </div>
                                                </div>
                                                <Card.Body>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" xs={6} />
                                                    </Placeholder>
                                                    <Placeholder as={Card.Text} animation="glow">
                                                        <Placeholder bg="light" xs={7} /> <Placeholder bg="light" xs={4} /> <Placeholder bg="light" xs={4} />{' '}
                                                    </Placeholder>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" lg={8} />
                                                    </Placeholder>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" lg={8} />
                                                    </Placeholder>
                                                    <Row className='mt-4'>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mt-4'>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder.Button variant="light" xs={12} />
                                                            </Placeholder>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>

                                            <div className='custom-card-white'></div>
                                        </SwiperSlide>

                                        <SwiperSlide position-relative>
                                            <Card className='container-card-white'>
                                                <div className='container-img-card'>
                                                    <div className='mascara'>
                                                        <Card.Img variant="top" src={blogCover} />
                                                    </div>
                                                </div>
                                                <Card.Body>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" xs={6} />
                                                    </Placeholder>
                                                    <Placeholder as={Card.Text} animation="glow">
                                                        <Placeholder bg="light" xs={7} /> <Placeholder bg="light" xs={4} /> <Placeholder bg="light" xs={4} />{' '}
                                                    </Placeholder>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" lg={8} />
                                                    </Placeholder>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" lg={8} />
                                                    </Placeholder>
                                                    <Row className='mt-4'>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mt-4'>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder.Button variant="light" xs={12} />
                                                            </Placeholder>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>

                                            <div className='custom-card-white'></div>
                                        </SwiperSlide>

                                        <SwiperSlide position-relative>
                                            <Card className='container-card-white'>
                                                <div className='container-img-card'>
                                                    <div className='mascara'>
                                                        <Card.Img variant="top" src={blogCover} />
                                                    </div>
                                                </div>
                                                <Card.Body>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" xs={6} />
                                                    </Placeholder>
                                                    <Placeholder as={Card.Text} animation="glow">
                                                        <Placeholder bg="light" xs={7} /> <Placeholder bg="light" xs={4} /> <Placeholder bg="light" xs={4} />{' '}
                                                    </Placeholder>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" lg={8} />
                                                    </Placeholder>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        <Placeholder bg="light" lg={8} />
                                                    </Placeholder>
                                                    <Row className='mt-4'>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mt-4'>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder bg="light" lg={12} />
                                                            </Placeholder>
                                                        </Col>
                                                        <Col xs={6} md={6}>
                                                            <Placeholder as={Card.Title} animation="glow">
                                                                <Placeholder.Button variant="light" xs={12} />
                                                            </Placeholder>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>

                                            <div className='custom-card-white'></div>
                                        </SwiperSlide>
                                    </>
                                }

                                {data && data.searchProperties?.edges.map((property) => (
                                    <SwiperSlide position-relative>

                                        <Card className='container-card-white'>
                                            <div className='container-img-card'>
                                                <div className='chip'>
                                                    <span className={getPropertyStatusUIClasses(property)}></span>
                                                    <p className='text-white text-uppercase m-0 font-chip'>{getPropertyStatusUILabel(property)}</p>
                                                </div>
                                                <div className='mascara'>
                                                    <Card.Img src={property.node.coverPhotoFullPath} onError={onErrorCover} />
                                                </div>
                                            </div>

                                            <Card.Body>
                                                <Card.Title className='text-white'>{truncateTitle(property.node.projectName)}</Card.Title>
                                                <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> {property.node.location}</Card.Subtitle>
                                                <Row>
                                                    <Col xs={6} md={6}>
                                                        <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                        <p className='text-gb custom-bold text-uppercase'>{numberFormat(property.node.investmentEstimatedYield)} %</p>
                                                    </Col>
                                                    <Col xs={6} md={6}>
                                                        <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                        <p className='text-gb custom-bold'>{property.node.executionTime} meses</p>
                                                    </Col>
                                                </Row>
                                                <Card.Text className='custom-ligth'>
                                                    {truncate(property.node.description)}
                                                </Card.Text>

                                                <ProgressBar now={property.node.raisedAmmount / property.node.targetGoal * 100} className='custom-progress mt-4' />
                                                <Row>
                                                    <Col xs={6} md={6}>
                                                        <p className='text-gb mt-1'>{numberFormat(property.node.raisedAmmount / property.node.targetGoal * 100)} %</p>
                                                    </Col>
                                                    <Col xs={6} md={6}>
                                                        <p className='text-gb mt-1 text-end'>{numberFormat(property.node.targetGoal)} €</p>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xs={6} md={6}>
                                                        <label>
                                                            <p className='custom-ligth mb-1 '>Inversión mínima</p>
                                                            <p className='text-gb custom-bold text-end'>{numberFormat(property.node.minInvestment)} €</p>
                                                        </label>
                                                    </Col>
                                                    <Col xs={6} md={6}>
                                                        <div className='d-flex justify-content-end'>
                                                            <Button variant='outline-light' className='btn-gb text-uppercase btn-global btn-detail'  onClick={() => goToPropertyDetail(property)}>Detalle</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>

                                        <div className='custom-card-white'></div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        <div className='pt-4 mb-4'>
                            <Button variant='outline-light m-auto btn-solid custom-ma' className='btn-gb text-uppercase' onClick={goToContact}>
                                Contáctanos
                            </Button>
                        </div>
                    </div>
                </div>

                <div className='container-fluid position-relative'>
                    <div className='container-vector-left'>
                        <img src={vectorLeft} alt="" className='vector-responsive' />
                    </div>
                    <div className='container-lg p-none position-relative'>
                        <div className="container-right">
                            <div className='ball-right'></div>
                        </div>
                        {/* <div className="container-top">
                            <div className='ball-top'></div>
                        </div> */}

                    </div>
                    <div className='container-lg p-none position-relative'>
                        <Row>
                            <Col xxl={{ span: 12, order: 1 }}>
                                <h2 className='subtitle'>¿Quieres saber como <br /> funciona?</h2>
                            </Col>
                        </Row>

                        <div className='d-none d-sm-block'>
                            <Row className='mt-5 align-items-center'>
                                <Col xs={12} md={4} className='mt-3 col-container'>
                                    <div className='card-work'>
                                        <Row>
                                            <Col className='text-center'>
                                                <img src={book} alt="" />
                                            </Col>
                                        </Row>
                                        <p className='text-center mt-3 title-work'>Infórmate</p>
                                        <span className='text-work'>Te ofrecemos todo el contenido necesario para entender los conceptos básicos de La Casa Del Token. Gracias a nuestra Tokenpedia podrás disfrutar de un proceso de inversión seguro y rentable.</span>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} className='mt-3 col-container'>
                                    <div className='card-work'>
                                        <Row>
                                            <Col className='text-center'>
                                                <img src={kyc} alt="" />
                                            </Col>
                                        </Row>
                                        <p className='text-center mt-3 title-work'>Registro y KYC</p>
                                        <span className='text-work'>Realiza este proceso en 5 minutos. En La Casa del Token nos gusta conocer a nuestros inversores e interactuar con ellos. Aportamos este plus de seguridad para ofrecer una plataforma con perfiles verificados.</span>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} className='mt-3 col-container'>
                                    <div className='card-work'>
                                        <Row>
                                            <Col className='text-center'>
                                                <img src={wallet} alt="" />
                                            </Col>
                                        </Row>
                                        <p className='text-center mt-3 title-work'>Te creamos tu wallet</p>
                                        <span className='text-work'>Sabemos que es complejo el proceso de crear una billetera electrónica. No te preocupes, hemos creado una plataforma que te otorga este servicio totalmente gratis para evitarte cualquier demora en el proceso de invertir. Esto te ayudará a comprar tus primeros tokens.</span>
                                    </div>
                                </Col>
                            </Row>

                            <Row className='mt-5 align-items-center'>
                                <Col xs={12} md={4} className='mt-3 col-container'>
                                    <div className='card-work'>
                                        <Row>
                                            <Col className='text-center'>
                                                <img src={settingsImg} alt="" />
                                            </Col>
                                        </Row>
                                        <p className='text-center mt-3 title-work'>Analiza la  oferta de inmubles</p>
                                        <span className='text-work'>Hemos creado una plataforma 3.0 para que puedas optimizar al máximo tu búsqueda a la hora de invertir en La Casa Del Token. Descubre nuestros filtros y las fichas de producto más completa del mercado.</span>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} className='mt-3 col-container'>
                                    <div className='card-work'>
                                        <Row>
                                            <Col className='text-center'>
                                                <img src={chart} alt="" />
                                            </Col>
                                        </Row>
                                        <p className='text-center mt-3 title-work'>Seguimiento de tu inversión</p>
                                        <span className='text-work'>En nuestra web 3.0 disfrutarás de un panel de usuario personalizado para ti con el objetivo de saber en todo momento los rendimientos que te ofrecen los inmuebles de La Casa Del Token.</span>
                                    </div>
                                </Col>
                                <Col xs={12} md={4} className='mt-3 col-container'>
                                    <div className='card-work'>
                                        <Row>
                                            <Col className='text-center'>
                                                <img src={bitcoin} alt="" />
                                            </Col>
                                        </Row>
                                        <p className='text-center mt-3 title-work'>Recibe tus dividendos</p>
                                        <span className='text-work'>Dependiendo del tipo de inmueble que hayas elegido (One-Shot o Recurrente) podrás tener ingresos mensuales o recibir una plusvalía final para la explotación de los activos inmobiliarios.</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='d-sm-none mt-4'>
                            <Swiper
                                cssMode={true}
                                navigation={false}
                                pagination={true}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    1300: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },
                                }}
                                className="swiper-home-cards"
                            >
                                <SwiperSlide>
                                    <div className='card-work-home'>
                                        <Row>
                                            <Col className='text-center'>
                                                <img src={book} alt="" />
                                            </Col>
                                        </Row>
                                        <p className='text-center mt-3 title-work'>Infórmate</p>
                                        <span className='text-work'>Te ofrecemos todo el contenido necesario para entender los conceptos básicos de La Casa Del Token. Gracias a nuestra Tokenpedia podrás disfrutar de un proceso de inversión seguro y rentable.</span>

                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-work-home'>
                                        <Row>
                                            <Col className='text-center'>
                                                <img src={kyc} alt="" />
                                            </Col>
                                        </Row>
                                        <p className='text-center mt-3 title-work'>Registro y KYC</p>
                                        <span className='text-work'>Realiza este proceso en 5 minutos. En La Casa del Token nos gusta conocer a nuestros inversores e interactuar con ellos. Aportamos este plus de seguridad para ofrecer una plataforma con perfiles verificados.</span>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-work-home'>
                                        <Row>
                                            <Col className='text-center'>
                                                <img src={wallet} alt="" />
                                            </Col>
                                        </Row>
                                        <p className='text-center mt-3 title-work'>Te creamos tu wallet</p>
                                        <span className='text-work'>Sabemos que es complejo el proceso de crear una billetera electrónica. No te preocupes, hemos creado una plataforma que te otorga este servicio totalmente gratis para evitarte cualquier demora en el proceso de invertir. Esto te ayudará a comprar tus primeros tokens.</span>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-work-home'>
                                        <Row>
                                            <Col className='text-center'>
                                                <img src={settingsImg} alt="" />
                                            </Col>
                                        </Row>
                                        <p className='text-center mt-3 title-work'>Analiza la  oferta de inmubles</p>
                                        <span className='text-work'>Hemos creado una plataforma 3.0 para que puedas optimizar al máximo tu búsqueda a la hora de invertir en La Casa Del Token. Descubre nuestros filtros y las fichas de producto más completa del mercado.</span>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-work-home'>
                                        <Row>
                                            <Col className='text-center'>
                                                <img src={chart} alt="" />
                                            </Col>
                                        </Row>
                                        <p className='text-center mt-3 title-work'>Seguimiento de tu inversión</p>
                                        <span className='text-work'>En nuestra web 3.0 disfrutarás de un panel de usuario personalizado para ti con el objetivo de saber en todo momento los rendimientos que te ofrecen los inmuebles de La Casa Del Token.</span>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-work-home'>
                                        <Row>
                                            <Col className='text-center'>
                                                <img src={bitcoin} alt="" />
                                            </Col>
                                        </Row>
                                        <p className='text-center mt-3 title-work'>Recibe tus dividendos</p>
                                        <span className='text-work'>Dependiendo del tipo de inmueble que hayas elegido (One-Shot o Recurrente) podrás tener ingresos mensuales o recibir una plusvalía final para la explotación de los activos inmobiliarios.</span>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                        <div className='pt-4 mb-4 responsive-pt-64'>
                            <Button variant='outline-light m-auto btn-solid custom-ma' onClick={goToHowWorks} className='btn-gb text-uppercase'>
                                CÓMO FUNCIONA
                            </Button>
                        </div>
                    </div>
                </div>

                <div className='container-lg p-none position-relative'>
                    <div className='container-green-left'>
                        <div className='ball-cyan'></div>
                    </div>
                    <div className="container-green-right">
                        <div className='ball-cyan'></div>
                    </div>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='custom-pl'>
                                <h2 className='subtitle'>Equipo de<br /> La casa del token</h2>

                                <h3 className='subtitle-text'>conoce a nuestros socios</h3>

                                <div className='d-none d-sm-block'>
                                    <p className='text-gb text-work'><span className='text-muted'>Somos la primera plataforma de</span> inmuebles tokenizados <span className='text-muted'> que permite la</span> inversión y retirada de los beneficios<span className='text-muted'> tanto en crypto como en FIAT</span> ampliando así las posibilidades de inversión.</p>
                                    <p className='text-gb text-work'><span className='text-muted'>El expertise en áreas como tecnología, legal y Real Estate nos permite ofrecer una </span>experiencia de usuario única y <span className='text-muted'>excelente.</span></p>
                                    <div className='pt-4 mb-4'>
                                        <Button variant='outline-light btn-solid' className='btn-gb text-uppercase' onClick={goToPartners}>
                                            TODOS LOS SOCIOS
                                        </Button>
                                    </div>
                                </div>
                                <div className='d-sm-none'>
                                    <div className='container-truncate'>
                                        <div className='text-truncate'>
                                            <p className='text-gb text-work'><span className='text-muted'>Somos la primera plataforma de</span> inmuebles tokenizados <span className='text-muted'> que permite la</span> inversión y retirada de los beneficios<span className='text-muted'> tanto en crypto como en FIAT</span> ampliando así las posibilidades de inversión. <br /><span className='text-muted'>El expertise en áreas como tecnología, legal y Real Estate nos permite ofrecer una </span>experiencia de usuario única y <span className='text-muted'>excelente.</span></p>
                                        </div>
                                        <p className='btn-more'>
                                            Leer mas
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <Swiper
                                cssMode={true}
                                navigation={true}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Navigation, Mousewheel, Keyboard]}
                                className="swipper-gifs"
                            >
                                <SwiperSlide position-relative>
                                    <div>
                                        <div className='text-center'>
                                            <img src={criptoC} alt="" className='img-fluid' />
                                            <h2 className='text-white text-uppercase title-slider'>adapta socios</h2>
                                        </div>
                                        <div>
                                            <img src={discC} alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide position-relative>
                                    <div>
                                        <div className='text-center'>
                                            <img src={criptoG} alt="" className='img-fluid' />
                                            <h2 className='text-white text-uppercase title-slider'>ALEDRA LEGAL</h2>
                                        </div>
                                        <div>
                                            <img src={discG} alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide position-relative>
                                    <div>
                                        <div className='text-center'>
                                            <img src={criptoY} alt="" className='img-fluid' />
                                            <h2 className='text-white text-uppercase title-slider'>MINERY REPORT</h2>
                                        </div>
                                        <div>
                                            <img src={discY} alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>

                            <div className='pt-4 mb-4 d-sm-none'>
                                <Button variant='outline-light btn-solid custom-ma' className='btn-gb text-uppercase' onClick={goToPartners}>
                                    TODOS LOS SOCIOS
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className='container-lg p-none position-relative pb-5 m-top'>
                    <Row>
                        <Col xs={12} md={5}>
                            <h2 className='subtitle responsive-txt-center'>Preguntas frecuentes</h2>
                            <h3 className='subtitle-text responsive-txt-center'>¿Tienes alguna duda?</h3>
                            <div className='d-none d-sm-block'>
                                <p className='text-gb text-work text-muted'>Puedes preguntarnos lo que quieras pero antes de ponerte un contacto puedes echar un ojo a nuestras preguntas frecuentes. </p>
                                <p className='text-gb text-work text-muted'>Probablemente encuentres la  respuesta que buscas.</p>
                                <p className='text-gb text-work'>¿Sobre qué tema te gustaría profundizar? </p>
                                <div className='pt-4 mb-4'>
                                    <Button variant='outline-light btn-solid' onClick={goToFaqs} className='btn-gb text-uppercase'>
                                        TODAS LAS PREGUNTAS
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={7}>
                            <div className='d-none d-sm-block'>
                                <Row>
                                    <Col xs={12} md={2}></Col>
                                    <Col xs={12} md={5}>
                                        <div className='card-rounded'>
                                            <div className='icon-swiper-container h-0 mb-2'>
                                                <img src={swiper3} className='icon-swiper' alt="" />
                                            </div>
                                            <p className='title-swiper text-white text-center m-0'>Sobre inversiones <br /> inmobiliarias</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={5} className='pt-4'>
                                        <div className='card-rounded'>
                                            <div className='icon-swiper-container  h-0 mb-2'>
                                                <img src={swiper1} className='icon-swiper' alt="" />
                                            </div>
                                            <p className='title-swiper text-white text-center'>Sobre inversiones <br /> inmobiliarias</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='padding-responsive'>
                                    <Col xs={12} md={5} className='pt-4'>
                                        <div className='card-rounded'>
                                            <div>
                                                <div className='icon-swiper-container h-0 mb-2'>
                                                    <img src={swiper1} className='icon-swiper' alt="" />
                                                </div>
                                                <p className='title-swiper text-white text-center'>Sobre la Casa del <br /> Token y su <br />funcionamiento</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={5} className='pt-4'>
                                        <div className='card-rounded'>
                                            <div className='icon-swiper-container mb-2'>
                                                <img src={swiper2} className='icon-swiper' alt="" />
                                            </div>
                                            <p className='title-swiper text-white text-center'>Sobre BlockChain</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={2}></Col>
                                </Row>
                            </div>

                            <div className='d-sm-none'>
                                <div className='mt-5'>
                                    <Swiper
                                        spaceBetween={16}
                                        modules={[Pagination]}
                                        breakpoints={{
                                            300: {
                                                slidesPerView: 1.4,
                                            },
                                            600: {
                                                slidesPerView: 1.4,
                                            }
                                        }}>
                                        <SwiperSlide>
                                            <div className={`card-round-faq ${!card ? 'active-card' : ''}`}>
                                                <div className='icon-swiper-container-faq'>
                                                    <img src={swiper1} className='icon-swiper' alt="" />
                                                </div>

                                                <p className='title-swiper text-center'>Sobre la Casa del Token y su funcionamiento</p>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className={`card-round-faq ${card ? 'active-card' : ''}`} onClick={() => setCard(true)}>
                                                <div className='icon-swiper-container-faq'>
                                                    <img src={swiper2} className='icon-swiper' alt="" />
                                                </div>

                                                <p className='title-swiper text-center'>Sobre BlockChain</p>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className='card-round-faq'>
                                                <div className='icon-swiper-container-faq'>
                                                    <img src={swiper3} className='icon-swiper' alt="" />
                                                </div>

                                                <p className='title-swiper text-center'>Sobre inversiones inmobiliarias</p>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className='card-round-faq'>
                                                <div className='icon-swiper-container-faq'>
                                                    <img src={swiper1} className='icon-swiper' alt="" />
                                                </div>

                                                <p className='title-swiper text-center'>Lorem Ipsum dolor Amet</p>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>

                                    <div className='pt-4 grl-mb'>
                                        <Button variant='outline-light btn-global custom-ma' onClick={goToFaqs} className='btn-gb btn-work'>
                                            Ver Más preguntas
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className='banner'>
                    <Row className='align-items-center h-100'>
                        <Col className='text-center'>
                            <span className='subtitle'>¿Estás listo para invertir?</span>
                            <Button variant='outline-light btn-global custom-ma' className='btn-gb btn-work' onClick={goToContact}>
                                CONSÚLTANOS
                            </Button>
                        </Col>
                    </Row>

                </div>
            </div>


            <NewsLetter />
        </>
    );
}

export default Home;