import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, InputGroup, Card } from 'react-bootstrap';
import './BlogDetail.css';
import Fab from '../../components/fab/Fab';
import Chart from '../../components/chart/Chart';
import NewsLetter from '../../components/newsletter/Newsletter';
import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight } from "react-icons/hi";
import { FaTelegramPlane } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import AppSettings from '../../AppSettings';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { toast } from 'react-toastify';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

// + IMAGES +
import blog1 from '../../assets/imgs/blog1.png';
import blog2 from '../../assets/imgs/blog2.png';
import blog3 from '../../assets/imgs/blog3.png';
import blog4 from '../../assets/imgs/blog4.png';
import blogCover from '../../assets/imgs/blog_cover.png';
// - IMAGES -

const BlogDetail = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const { settings } = AppSettings();

    const { slug } = useParams();
    const [article, setArticle] = useState();

    // (+) Graphql

    const GET_ARTICLE_BY_SLUG = gql`${settings.graphql.query.getArticleBySlug}`;
    const { data, loading, error } = useQuery(GET_ARTICLE_BY_SLUG, { variables: { slug }});

    if (data) {
        if(!article && data.searchArticles.edges.length > 0) setArticle(data.searchArticles.edges[0]);
    }

    if (error) toast(error.message);

    // (-) Graphql


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const onErrorCover= (error) => {
        error.target.src = blogCover;
    }


    return (
        <>
            <Fab />
            <div className='container-blog-detail'>
                <Chart />
                <Container className='p-3 pb-5 pt-5'>
                    <Row className='mt-5'>
                        <Col xs={12} md={3}></Col>
                        <Col xs={12} md={6}>
                            <p className='date'>
                                <Moment format="DD.MM.YYYY">
                                    {article?.node.articleDate}
                                </Moment>
                            </p>

                            <h1 className='text-light'>{article?.node.title}</h1>
                            <h4 className='text-light mt-4'>{article?.node.subtitle}</h4>

                            <img src={article?.node.coverPhotoFullPath}  onError={onErrorCover} width='100%' height='398px' className='image-blog-detail mt-3' alt="" />

                            <p className='text-light mt-4'>{article?.node.body}</p>

                            <div className='w-100 d-flex flex-row flex-wrap mt-5'>
                                <p className='hashtag click h-active'>#ILorem Impsum</p>
                                <p className='hashtag click h-active'>#Lorem Impsum dolor</p>
                                <p className='hashtag click h-active'>#Lorem Impsum</p>
                                <p className='hashtag click h-active'>#Lorem Impsum dolor</p>
                            </div>

                            {/* <Row className='mt-5 space-between'>
                                <Col xs={6} md={4}>
                                    <Button variant='outline-light btn-gb'>
                                        <HiOutlineChevronDoubleLeft className='chevron-back' />
                                        Post Anterior
                                    </Button>
                                </Col>
                                <Col xs={6} md={4}>
                                    <Button variant='outline-light btn-gb'>
                                        Siguiente Post
                                        <HiOutlineChevronDoubleRight className='chevron-next' />
                                    </Button>
                                </Col>
                            </Row> */}
                        </Col>
                        <Col xs={12} md={3}></Col>
                    </Row>
                </Container>
            </div>

            {/* <div className='comments-container'>
                <Container className='p-3 pb-5 pt-5'>
                    <Row>
                        <Col xs={12} md={3}></Col>
                        <Col xs={12} md={6}>
                            <h3><b>Comentarios</b></h3>

                            <Row className='mt-4 align-items-center'>
                                <Col xs={6} md={6}>
                                    <p className='text-color'>amyrobson</p>
                                </Col>
                                <Col xs={6} md={6} className='text-end'>
                                    <span className='date-comment'>17.05.2022</span>
                                </Col>
                            </Row>
                            <Row>
                                <p>Impressive! Though it seems the drag feature could be improved. But overall it looks incredible. You’ve nailed the design and the responsiveness at various breakpoints works really well.</p>
                            </Row>
                            <Row className='space-between align-items-center'>
                                <Col xs={6} md={6}>
                                    <span className='response'>3 respuestas</span>
                                </Col>
                                <Col xs={6} md={6} className='text-end'>
                                    <Button variant='clear' className='btn-response'>
                                        <TiArrowBack className='chevron-back' />
                                        responder
                                    </Button>
                                </Col>
                            </Row>

                            <div className='container-response'>
                                <Row className='mt-3 align-items-center'>
                                    <Col xs={1} md={1}></Col>
                                    <Col xs={6} md={6}>
                                        <p className='text-color'>token123</p>
                                    </Col>
                                    <Col xs={5} md={5} className='text-end'>
                                        <span className='date-comment'>17.05.2022</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={1} md={1}></Col>
                                    <Col xs={11} md={11}>
                                        <p>Impressive! Though it seems the drag feature could be improved. But overall it looks incredible. You’ve nailed the design and the responsiveness at various breakpoints works really well.</p>
                                    </Col>
                                </Row>
                                <Row className='space-between align-items-center'>
                                    <Col xs={1} md={1}></Col>
                                    <Col xs={6} md={6}></Col>
                                    <Col xs={5} md={5} className='text-end'>
                                        <Button variant='clear' className='btn-response'>
                                            <TiArrowBack className='chevron-back' />
                                            responder
                                        </Button>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={1} md={1}></Col>
                                    <Col xs={11} md={11}>
                                        <Form>
                                            <Row className='mt-4'>
                                                <Col xs={12} md={12}></Col>
                                                <Col xs={12} md={12}>
                                                    <Form.Control
                                                        as="textarea"
                                                        className='send-comment'
                                                        placeholder="Add a comment..."
                                                        style={{ height: '100px' }}
                                                    />
                                                </Col>
                                                <Col xs={12} md={3}></Col>
                                            </Row>

                                            <Row className='justify-content-end mt-3'>
                                                <Col xs={6} md={6}>
                                                    <InputGroup className="mb-3 input-alias">
                                                        <Form.Control
                                                            placeholder="Alias*"
                                                            aria-describedby="basic-addon1"
                                                            className='input-text-alias'
                                                        />
                                                    </InputGroup>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <Button className='btn-gb btn-send' variant='outline-dark' size="lg">
                                                        <FaTelegramPlane />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} md={3}></Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={3}></Col>
                        <Col xs={12} md={6}>
                            <Row className='mt-4'>
                                <Col xs={6} md={6}>
                                    <p className='text-color'>amyrobson</p>
                                </Col>
                                <Col xs={6} md={6} className='text-end'>
                                    <span className='date-comment'>17.05.2022</span>
                                </Col>
                            </Row>
                            <Row>
                                <p>Impressive! Though it seems the drag feature could be improved. But overall it looks incredible. You’ve nailed the design and the responsiveness at various breakpoints works really well.</p>
                            </Row>
                        </Col>
                        <Col xs={12} md={3}></Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={3}></Col>
                        <Col xs={12} md={6}>
                            <Row className='mt-4'>
                                <Col xs={6} md={6}>
                                    <p className='text-color'>amyrobson</p>
                                </Col>
                                <Col xs={6} md={6} className='text-end'>
                                    <span className='date-comment'>17.05.2022</span>
                                </Col>
                            </Row>
                            <Row>
                                <p>Impressive! Though it seems the drag feature could be improved. But overall it looks incredible. You’ve nailed the design and the responsiveness at various breakpoints works really well.</p>
                            </Row>
                        </Col>
                        <Col xs={12} md={3}></Col>
                    </Row>

                    <Form>
                        <Row className='mt-4'>
                            <Col xs={12} md={3}></Col>
                            <Col xs={12} md={6}>
                                <Form.Control
                                    as="textarea"
                                    className='send-comment'
                                    placeholder="Add a comment..."
                                    style={{ height: '100px' }}
                                />
                            </Col>
                            <Col xs={12} md={3}></Col>
                        </Row>

                        <Row className='justify-content-end mt-3'>
                            <Col xs={12} md={3}></Col>
                            <Col xs={6} md={3}>
                                <InputGroup className="mb-3 input-alias">
                                    <Form.Control
                                        placeholder="Alias*"
                                        aria-describedby="basic-addon1"
                                        className='input-text-alias'
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={6} md={3}>
                                <Button className='btn-gb btn-send' variant='outline-dark' size="lg">
                                    <FaTelegramPlane />
                                </Button>
                            </Col>
                            <Col xs={12} md={3}></Col>
                        </Row>
                    </Form>
                </Container>
            </div> */}

            {/* <div className='similar-post'>
                <Container className='p-3 pb-5 pt-5'>
                    <h4 className='text-light'>Post similares</h4>
                    <Swiper
                        cssMode={true}
                        navigation={true}
                        pagination={true}
                        mousewheel={true}
                        keyboard={true}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            1300: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                        }}

                        className="mySwiper"
                    >
                        <SwiperSlide position-relative>
                            <Card className='card-blog-first mt-3'>
                                <Card.Img variant="top" className='header-img-card' src={blog1} />
                                <Card.Body>
                                    <Card.Text className='text-light date'>26.05.2022</Card.Text>
                                    <Card.Title className='text-light'>
                                        ¿Qué es un NFT y cómo me sirve en el mercado inmobiliario?
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </SwiperSlide>

                        <SwiperSlide position-relative>
                            <Card className='card-blog-first mt-3'>
                                <Card.Img variant="top" className='header-img-card' src={blog2} />
                                <Card.Body>
                                    <Card.Text className='text-light date'>26.05.2022</Card.Text>
                                    <Card.Title className='text-light'>
                                        ¿Qué es un NFT y cómo me sirve en el mercado inmobiliario?
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </SwiperSlide>

                        <SwiperSlide position-relative>
                            <Card className='card-blog-first mt-3'>
                                <Card.Img variant="top" className='header-img-card' src={blog3} />
                                <Card.Body>
                                    <Card.Text className='text-light date'>26.05.2022</Card.Text>
                                    <Card.Title className='text-light'>
                                        ¿Qué es un NFT y cómo me sirve en el mercado inmobiliario?
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </SwiperSlide>

                        <SwiperSlide position-relative>
                            <Card className='card-blog-first mt-3'>
                                <Card.Img variant="top" className='header-img-card' src={blog4} />
                                <Card.Body>
                                    <Card.Text className='text-light date'>26.05.2022</Card.Text>
                                    <Card.Title className='text-light'>
                                        ¿Qué es un NFT y cómo me sirve en el mercado inmobiliario?
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </SwiperSlide>
                    </Swiper>
                </Container>
            </div> */}
            <NewsLetter />
        </>
    );
}

export default BlogDetail;