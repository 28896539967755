import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import './Contact.css';
import Fab from '../../components/fab/Fab';
import Chart from '../../components/chart/Chart';
import { FaTelegramPlane } from "react-icons/fa";
import { useMutation, gql } from '@apollo/client';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import AppSettings from '../../AppSettings';

// + IMAGES +
import contact from '../../assets/imgs/contact.svg';
// - IMAGES -

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const { settings } = AppSettings();


    // (+) Form

    const { register, handleSubmit, reset, formState, formState: { errors, isSubmitSuccessful } } = useForm();

    const onSubmit = data => sendContact({ variables: data });

    useEffect(() => {
        if (formState.isSubmitSuccessful) reset();
    }, [formState, reset]);

    // (-) Form


    // (+) Graphql

    const SEND_CONTACT = gql`${settings.graphql.mutation.sendContact}`;

    const [sendContact, { data, loading, error }] = useMutation(SEND_CONTACT);

    if (error)
        toast(error.message);

    if (data && formState.isSubmitSuccessful)
        toast((data.sendContact && data.sendContact.ok) ? 'Información de contacto enviada.' : 'No se pudo enviar la informacion de contacto.', {
            type: 'error',
            icon: false
        });

    // (-) Graphql



    return (
        <>
            <Fab />

            <Container className='p-3 pb-5 pt-5'>
                <Row className='align-items-center mt-5'>
                    <Col xs={12} md={1}></Col>
                    <Col xs={12} md={5}>
                        <Row className='justify-content-center'>
                            <Col className='text-center'>
                                <img src={contact} alt="" />
                            </Col>
                        </Row>
                        <h1 className='text-light subtitle mt-5'>¿Tienes alguna duda?</h1>
                        <h2 className='subtitle-text'>¡Contacta con nuestro equipo de atención al inversor!</h2>
                        <p className='text-light mb-5'>Nuestro equipo La Casa del Token de atención al inversor resolverá cualquier duda que tengas.
                            No dudes en contactarnos a través del siguiente formulario. Nuestro equipo resolverá tus dudas lo antes posible.</p>
                    </Col>
                    <Col xs={12} md={5}>
                        <Form>
                            <Form.Group className='mb-3'>
                                <Form.Control type="text" className='input-contact' placeholder="Nombre" {...register("name", { required: true })} />
                                {errors.name &&
                                    <Form.Text className="text-muted">
                                        Debe ingresar el nombre
                                    </Form.Text>
                                }
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Control type="email" className='input-contact' placeholder="Email" {...register("email", { required: true })} />
                                {errors.email &&
                                    <Form.Text className="text-muted">
                                        Debe ingresar el email
                                    </Form.Text>
                                }
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Control type="text" className='input-contact' placeholder="Asunto" {...register("subject", { required: true })} />
                                {errors.subject &&
                                    <Form.Text className="text-muted">
                                        Debe ingresar el asunto
                                    </Form.Text>
                                }
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Control type="text" className='input-contact' placeholder="Mensaje" {...register("message", { required: true })} />
                                {errors.message &&
                                    <Form.Text className="text-muted">
                                        Debe ingresar el mensaje
                                    </Form.Text>
                                }
                            </Form.Group>
                            <Form.Group className='mb-3 mt-5'>
                                <Form.Check type="checkbox" label="Acepto las políticas de privacidad." {...register("privacyPolicies", { required: true })} />
                                {errors.privacyPolicies &&
                                    <Form.Text className="text-muted">
                                        Debe aceptar las politicas de privacidad
                                    </Form.Text>
                                }
                            </Form.Group>
                            <Form.Group>
                                <Form.Check type='checkbox' label="Deseo recibir comunicaciones comerciales sobre los servicios de Adapta Socios." {...register("comercialComunications")} />
                            </Form.Group>

                            <Row className='justify-content-end'>
                                <Col xs={2} md={2}>
                                    <Button variant="outline-light" type="submit" className='btn-gb' disabled={loading} onClick={handleSubmit(onSubmit)}>
                                        {loading &&
                                            <Spinner animation="border" size="sm" variant="light" role="status"></Spinner>
                                        }
                                        {!loading &&
                                            <FaTelegramPlane />
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={12} md={1}></Col>
                </Row>
            </Container>
        </>
    );
}

export default Contact;