import 'bootstrap/dist/css/bootstrap.min.css';
import './SearchMenu.css';
import { FaSearch } from "react-icons/fa";
import { FiChevronDown, FiLogOut } from "react-icons/fi";
import { BsPeople } from "react-icons/bs";
import { CgOptions } from "react-icons/cg";
import { Row, Col, InputGroup, FormControl, Button, Form } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { HashLink as Link } from 'react-router-hash-link';
import React from 'react';

// + IMAGES +
import avatar from '../../assets/imgs/avatar.png';
import logo from '../../assets/imgs/logo_header.png';
// - IMAGES -

function valuetext(value) {
    return `${value}°C`;
}

const SearchMenu = () => {

    const [value, setValue] = React.useState([20, 37]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const truncate = function (str) {
        return str?.length > 7 ? str.substring(0, 7) + "..." : str;
    }

    const filter = (
        <Popover id="filter" className='popover-filter'>
            <Popover.Body>
                <Box sx={{ width: 310 }}>
                    <Form.Label className='text-light'><b>Precio por token</b></Form.Label>
                    <Slider
                        getAriaLabel={() => 'Temperature range'}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                    />
                </Box>
                <Row className='align-items-center'>
                    <Col xs={6} md={6}>
                        <span className='text-light'>XXX €</span>
                    </Col>
                    <Col xs={6} md={6} className="text-end">
                        <span className='text-light'>XXX €</span>
                    </Col>
                </Row>

                <Form.Label className='text-light mt-3'><b>Tipo de activo</b></Form.Label>
                <Row>
                    <Col xs={6} md={6}>
                        <Form.Check type="checkbox" label="Casa" />
                        <Form.Check type="checkbox" label="Edificio de viviendas" />
                        <Form.Check type="checkbox" label="Adosado" />
                    </Col>
                    <Col xs={6} md={6}>
                        <Form.Check type="checkbox" label="Apartamento" />
                        <Form.Check type="checkbox" label="Hostal" />
                        <Form.Check type="checkbox" label="Apartamento con servicios" />
                    </Col>
                </Row>

                <Form.Label className='text-light mt-3'><b>Status</b></Form.Label>
                <Row>
                    <Col xs={6} md={6}>
                        <Form.Check type="checkbox" label="En diseño" />
                        <Form.Check type="checkbox" label="Finalizadas" />
                    </Col>
                    <Col xs={6} md={6}>
                        <Form.Check type="checkbox" label="En suscripción" />
                    </Col>
                </Row>

                <Form.Label className='text-light mt-3'><b>Tipo de proyecto</b></Form.Label>
                <Row>
                    <Col xs={6} md={6}>
                        <Form.Check type="checkbox" label="One shot" />
                    </Col>
                    <Col xs={6} md={6}>
                        <Form.Check type="checkbox" label="Recurrente" />
                    </Col>
                </Row>

                <Box sx={{ width: 310 }}>
                    <Form.Label className='text-light mt-3'><b>Rentabilidad</b></Form.Label>
                    <Slider
                        getAriaLabel={() => 'Temperature range'}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                    />
                </Box>
                <Row className='align-items-center'>
                    <Col xs={6} md={6}>
                        <span className='text-light'>6%</span>
                    </Col>
                    <Col xs={6} md={6} className="text-end">
                        <span className='text-light'>22%</span>
                    </Col>
                </Row>

                <Row className='mt-3 justify-content-center'>
                    <Col xs={12} md={2}></Col>
                    <Col xs={12} md={8}>
                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail'>APLICAR</Button>
                    </Col>
                    <Col xs={12} md={2}></Col>
                </Row>
            </Popover.Body>
        </Popover>
    );

    const user = (
        <Popover id="user" className='popover-user'>
            <Popover.Body>
                <Row>
                    <Col>
                        <Button variant='clear' className='text-light btn-filter'>
                            <FiLogOut />
                            <span>Cerrar sesión</span>
                        </Button>
                    </Col>
                </Row>

                <hr className='line-user' />

                <Row>
                    <Col>
                        <Button variant='clear' className='text-light btn-filter'>
                            <BsPeople />
                            <span>Mi perfil</span>
                        </Button>
                    </Col>
                </Row>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <Row className='w-100 ps-4 align-items-center d-none d-md-flex'>
                <Col xs={12} md={3}></Col>
                <Col xs={12} md={9}>
                    <div className='searchbar-container'>
                        <Row className='align-items-center'>
                            <Col xs={12} md={6}>
                                <InputGroup>
                                    <InputGroup.Text className='input-search'>
                                        <FaSearch className='text-light' />
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Buscar por localización"
                                        className='searchbar' />
                                </InputGroup>
                            </Col>
                            <Col xs={12} md={3}>
                                <OverlayTrigger trigger="click" placement="bottom" overlay={filter}>
                                    <Button variant='clear' className='text-light btn-filter'>
                                        <CgOptions />
                                        <span>Filtrar búsquedas</span>
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                            <Col xs={12} md={3} className="text-end">
                                <img src={avatar} className='avatar' alt="" />
                                <span className='avatar-name'>{truncate('Lorem Ipsum')}</span>
                                <OverlayTrigger trigger="click" placement="bottom" overlay={user}>
                                    <Button variant='clear'>
                                        <FiChevronDown className='text-light pointer' />
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Row className='justify-content-center align-items-center p-3 d-md-none search-mb'>
                <Col xs={4}>
                    <OverlayTrigger trigger="click" placement="bottom" overlay={filter}>
                        <Button variant='clear' className='text-light btn-filter'>
                            <CgOptions className='search-icon-mb' />
                        </Button>
                    </OverlayTrigger>
                </Col>
                <Col xs={4} className='text-center'>
                    <img src={logo} className='logo' alt="" />
                </Col>
                <Col xs={4} className='text-end'>
                    <img src={avatar} className='avatar' alt="" />
                    <OverlayTrigger trigger="click" placement="bottom" overlay={user}>
                        <Button variant='clear'>
                            <FiChevronDown className='text-light pointer' />
                        </Button>
                    </OverlayTrigger>
                </Col>
            </Row>
        </>

    );
}

export default SearchMenu;