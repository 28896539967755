import 'bootstrap/dist/css/bootstrap.min.css';
import './SideMenu.css';
import { Nav, Row, Col } from 'react-bootstrap';
import { FiPieChart, FiSettings, FiLifeBuoy, FiLogOut } from "react-icons/fi";
import { RiBuildingLine } from "react-icons/ri";
import { BsWallet2 } from "react-icons/bs";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { useLocation } from 'react-router-dom';

// + IMAGES +
import logo from '../../assets/imgs/logo_header.png';
// - IMAGES -

const SideMenu = () => {
    const location = useLocation().pathname;

    return (
        <>
            <Nav className="col-md-2.5 d-none d-md-block sidebar">
                <div className="sidebar-sticky"></div>
                <Nav.Item eventKey="0">
                    <Nav.Link className='nav-link text-center' href='/'>
                        <img src={logo} className='logo' alt="" />
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item className='mt-4'>
                    <Nav.Link href="/dashboard" className={`link-side ${(location.match(/^\/dashboard.*$/gim)) ? 'active-btn' : ''}`} eventKey="1"><FiPieChart className='icon icon-m' /> Dashboard</Nav.Link>
                </Nav.Item>
                <Nav.Item className='mt-3'>
                    <Nav.Link href="/investments" className={`link-side ${(location.match(/^\/investment.*$/gim)) ? 'active-btn' : ''}`} eventKey="2"><RiBuildingLine className='icon icon-m' /> Investment</Nav.Link>
                </Nav.Item>
                <Nav.Item className='mt-3'>
                    <Nav.Link href="/wallet" className={`link-side ${(location.match(/^\/wallet.*$/gim)) ? 'active-btn' : ''}`} eventKey="3"><BsWallet2 className='icon icon-m' /> Mi wallet</Nav.Link>
                </Nav.Item>
                <Nav.Item className='mt-3'>
                    <Nav.Link href="/profile" className={`link-side ${(location.match(/^\/profile.*$/gim)) ? 'active-btn' : ''}`} eventKey="4"><MdOutlinePeopleAlt className='icon icon-m' /> Mi perfil</Nav.Link>
                </Nav.Item>

                <hr className='line-menu' />

                <Nav.Item className='mt-4'>
                    <Nav.Link href="/wallet" className='link-side' eventKey="5"><FiSettings className='icon icon-m' /> Configuración</Nav.Link>
                </Nav.Item>
                <Nav.Item className='mt-3'>
                    <Nav.Link href="/wallet" className='link-side' eventKey="6"><FiLifeBuoy className='icon icon-m' /> Ayuda</Nav.Link>
                </Nav.Item>
                <Nav.Item className='mt-3'>
                    <Nav.Link href="/wallet" className='link-side logout' eventKey="7"><FiLogOut className='icon icon-m' /> Log out</Nav.Link>
                </Nav.Item>
            </Nav>

            <div className='tab d-md-none'>
                <Row className="align-items-center justify-content-center">
                    <Col xs={2} className='text-center'>
                        <div className={`${(location.match(/^\/dashboard.*$/gim)) ? 'active-tab' : ''}`}>
                            <a href="/dashboard">
                                <FiPieChart className='text-light icon-tab' />
                            </a>
                        </div>
                    </Col>
                    <Col xs={2} className='text-center'>
                        <div className={`${(location.match(/^\/investment.*$/gim)) ? 'active-tab' : ''}`}>
                            <a href="/investments">
                                <RiBuildingLine className='text-light icon-tab' />
                            </a>
                        </div>
                    </Col>
                    <Col xs={2} className='text-center'>
                        <BsWallet2 className='text-light icon-tab' />
                    </Col>
                    <Col xs={2} className='text-center'>
                        <MdOutlinePeopleAlt className='text-light icon-tab' />
                    </Col>
                    <Col xs={2} className='text-center'>
                        <FiSettings className='text-light icon-tab' />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default SideMenu;