import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import './Privacy.css';

// + IMAGES +
// - IMAGES -

const Privacy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    
    return (
        <Container className='privacy-text pb-5 pt-5'>
            <h1 className='mt-5'>PRIVACIDAD</h1>

            <p>A continuación, le informamos sobre la política privacidad que se aplicará en, aquellos
                casos en los que el interesado a través de la navegación por el sitio web […] (en adelante,
                el “Sitio Web”) nos facilite sus datos de carácter personal. Queremos informarle de nuestra
                Política de Privacidad actualizada y del destino ofrecido a los datos personales que nos
                facilita, incluyendo aquellos que recabamos por el hecho de navegar en nuestro Sitio Web.
                1. IDENTIFICACIÓN DEL RESPONSABLE</p>

            <ul>
                <li><b>Titular: </b>THE TOKEN HOUSE, S.L., (en adelante, “LA CASA DEL TOKEN”)</li>
                <li><b>Domicilio social: </b>Paseo De La Castellana, 180 Bis, 1º Derecha, 28046 – Madrid.</li>
                <li><b>NIF/CIF: </b>B-09608258</li>
                <li><b>Registro Público: </b>Registro Mercantil de Madrid, Tomo 43.191, Folio 170, Sección 8,
                    Hoja M-763229, Inscripción 1ª</li>
                <li><b>E-mail: </b>lacasadeltoken@gmail.com</li>
            </ul>

            <h1 className='mt-5'>2. INFORMACIÓN Y CONSENTIMIENTO</h1>

            <p>De conformidad con lo dispuesto en la Reglamento General UE 679/2016 de Protección de
                Datos (en adelante, “RGPD”) y en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección
                de Datos y Garantía de los Derechos Digitales (en adelante, “LOPDGDD”) mediante la
                aceptación de la presente Política de Privacidad el Interesado web (en adelante, el
                “Interesado”) PRESTA SU CONSENTIMIENTO informado, expreso, libre e inequívoco para
                tratar los datos personales recabados a través del Sitio Web o de la Aplicación, ya sea
                través de formularios habilitados al efecto o bien a través de la información recabada por las
                cookies.</p>

            <p>Asimismo, el Interesado consiente que estos datos sean tratados por el Responsable para
                prestar sus servicios aplicando las medidas organizativas y de seguridad requeridas por el
                RGPD.</p>

            <p>Todos los datos personales solicitados al Interesado en el Sitio Web son obligatorios, no
                siendo posible prestar el servicio sin el correspondiente tratamiento de datos por parte del
                Responsable. Ello, no obstante, la navegación en el Sitio Web es gratuita.</p>

            <p>El Interesado garantiza que los datos personales facilitados al Responsable son ciertos y
                exactos, con lo que LA CASA DEL TOKEN. No se hará responsable de cualquier incidencia
                derivada de la inexactitud o falsedad de la información proporcionada por los Interesados,
                más allá de la posibilidad que tiene el Interesado de ejercitar su derecho de rectificación.</p>

            <p>En el caso que el Interesado nos facilite datos de terceras personas, como responsable o
                encargado del tratamiento, será responsable de cumplir con toda la normativa de protección
                de datos aplicable. Además, el Interesado garantiza que ha obtenido todos los
                consentimientos, autorizaciones y/o aprobaciones legalmente necesarias antes de incluir
                datos personales de terceros en el Sitio Web.</p>

            <h1 className='mt-5'>3. PROCEDENCIA, FINALIDAD Y BASE JURÍDICA</h1>

            <p>Los datos personales del Interesado, o de terceros, que sean cedidos por el Interesado,
                serán tratados de forma confidencial y quedarán incorporados a la correspondiente
                actividad de tratamiento del Responsable.</p>

            <p>Las actividades del tratamiento llevadas a cabo por el Responsable son la siguientes:</p>

            <p>ACTIVIDAD: CREACIÓN DE ÁREA PRIVADA PARA EL INTERESADO</p>

            <p>PROCEDENCIA</p>

            <p>Información que el Interesado proporciona directamente a través del procedimiento de KyC
                que se realiza en el Sitio Web.</p>

            <p>Información obtenida las consultas realizadas a terceras partes, como las consultas de
                ficheros de solvencia (internos y externos), para la valoración de su estado de salud
                financiero y de su solvencia económica.</p>

            <p>BASE JURÍDICA Reglamento General de Protección de Datos, en particular:</p>

            <p>Art. 6.1, f) el tratamiento es necesario para la satisfacción de intereses legítimos
                perseguidos por el responsable del tratamiento.</p>

            <p>FINES TRATAMIENTO</p>

            <p>Estudio del alcance sobre la capacidad del Interesado de hacer frente hacer frente a las
                obligaciones de pago para la aprobación o denegación de la solicitud de la operación de
                préstamo. COLECTIVO Personas físicas</p>

            <p>CATEGORÍA DE DATOS</p>

            <p>Fotografía o imagen del Documento de Identidad (DNI), nombre y apellidos, dirección de
                correo electrónico, domicilio, fecha de nacimiento, edad, género, estado civil, teléfono,
                información financiera y de la tarjeta de crédito, número/s de cuenta/s bancaria/s, datos
                fiscales correspondientes, situación laboral, declaración IRPF, ingresos mensuales,
                información financiera y de la tarjeta de crédito, número/s de cuenta/s bancaria/s,
                transacciones en cuentas bancarias, datos personales relativos al incumplimiento de
                obligaciones dinerarias, financieras o de crédito, antecedentes crediticios, posiciones de
                riesgo, existencia de multas o sanciones administrativas u otras medidas legales
                ejecutorias.</p>

            <p>DESTINATARIOS</p>

            <p>Los datos facilitados por el interesado serán cedidos a terceras entidades, exclusivamente
                pertenecientes a los sectores de las siguientes actividades: entidades financieras,
                intermediarios financieros, aseguradoras, préstamos, hipotecas, tarjetas de crédito,
                pensiones, inversiones y ahorro.</p>

            <p>TRANSFERENCIA INTERNACIONAL No está prevista la transferencia internacional de los
                datos. PLAZOS DE Los datos serán conservados hasta que haya acabado la
                CONSERVACIÓN finalidad para la que fueron recabados, salvo que los datos
                deban mantenerse por una obligación legal. ACTIVIDAD: CESIÓN DE DATOS A
                TERCERAS ENTIDADES</p>

            <p>PROCEDENCIA</p>

            <p>Información que el Interesado proporciona directamente a través de los formularios
                accesibles en el Sitio Web. Información derivada del uso del Sitio Web por parte del
                Interesado cada vez que éste interactúa con el Sitio Web. Información obtenida de las
                consultas realizadas a terceras partes, como las consultas de ficheros (internos y externos),
                para la valoración de su estado de salud financiero y de su solvencia económica.</p>

            <p>BASE JURÍDICA</p>

            <p>Reglamento General de Protección de Datos, en particular: Art. 6.1 a) el interesado dio su
                consentimiento para el tratamiento de sus datos personales para uno o varios fines
                específicos.</p>

            <p>FINES TRATAMIENTO</p>

            <p>Cesión de los datos a terceras entidades para: Perfilado financiero o scoring de crédito,
                para realizar en base al historial de pago del Interesado con la Sitio Web y a la información
                obtenida de terceras fuentes (tales como ficheros de solvencia) para la valoración de la
                solicitud de préstamo del Interesado; Fines estadísticos y de investigación de mercado.</p>

            <p>COLECTIVO Personas físicas y jurídicas CATEGORÍA DE DATOS</p>

            <p>Fotografía o imagen del Documento de Identidad (DNI), nombre y apellidos, dirección de
                correo electrónico, domicilio, fecha de nacimiento, edad, género, estado civil, teléfono,
                información financiera y de la tarjeta de crédito, número/s de cuenta/s bancaria/s, datos
                fiscales correspondientes, situación laboral, declaración IRPF, ingresos mensuales,
                información financiera y de la tarjeta de crédito, número/s de cuenta/s bancaria/s, transacciones en cuentas bancarias, datos
                personales relativos al incumplimiento de obligaciones dinerarias, financieras o de crédito,
                antecedentes crediticios, posiciones de riesgo, existencia de multas o sanciones
                administrativas u otras medidas legales ejecutorias.</p>

            <p>DESTINATARIOS</p>

            <p>Los datos facilitados por el interesado serán cedidos a terceras entidades, exclusivamente
                pertenecientes a los sectores de las siguientes actividades: entidades financieras,
                intermediarios financieros, aseguradoras, préstamos, hipotecas, tarjetas de crédito,
                pensiones, inversiones y ahorro.</p>

            <p>TRANSFERENCIA INTERNACIONAL No está prevista la transferencia internacional de los
                datos.</p>

            <p>PLAZOS DE CONSERVACIÓN</p>

            <p>Los datos serán conservados hasta que haya acabado la finalidad para la que fueron
                recabados, salvo que los datos deban mantenerse por una obligación legal.</p>

            <p>ACTIVIDAD: PREVENCIÓN DE BLANQUEO DE CAPITALES Y DE LA FINANCIACIÓN AL
                TERRORISMO (Ley 10/2010, 28 de abril)</p>

            <p>PROCEDENCIA</p>

            <p>Los datos son recabados a través de los formularios accesibles en el Sitio Web, así como
                de las consultas de ficheros (internos y externos), para la valoración de su estado de salud
                financiero y de su solvencia económica.</p>

            <p>BASE JURÍDICA</p>

            <p>Reglamento General de Protección de Datos, en particular: Art. 6.1 c): cumplimiento de una
                obligación legal aplicable al responsable del tratamiento.</p>

            <p>FINES TRATAMIENTO Cumplimiento de las obligaciones legales y reglamentarias para
                identificar y prevenir fraude, blanqueo de capitales y financiación del terrorismo, así como
                cualquier otra práctica ilegal.</p>

            <p>COLECTIVO Personas físicas o jurídicas</p>

            <p>CATEGORÍA DE DATOS</p>

            <p>Fotografía o imagen del Documento de Identidad (DNI), nombre y apellidos, dirección de
                correo electrónico, domicilio, fecha de nacimiento, edad, género, estado civil, teléfono, datos
                fiscales correspondientes, situación laboral, ingresos mensuales, información financiera y de
                la tarjeta de crédito, número/s de cuenta/s bancaria/s, transacciones en cuentas bancarias,
                datos personales relativos al incumplimiento de obligaciones dinerarias, financieras o de
                crédito, antecedentes crediticios, posiciones de riesgo, existencia de multas o sanciones
                administrativas u otras medidas legales ejecutorias.</p>

            <p>DESTINATARIOS</p>

            <p>Comisión de Prevención del Blanqueo de Capitales e Infracciones Monetarias Servicio
                Ejecutivo de la Comisión de Prevención del Blanqueo de Capitales e Infracciones
                Monetarias. Cualquier entidad jurisdiccional o administrativa que solicite el envío de la
                información.</p>

            <p>TRANSFERENCIA INTERNACIONAL No está prevista la transferencia internacional de los
                datos.</p>

            <p>PLAZOS DE CONSERVACIÓN</p>

            <p>Los datos serán conservados hasta que haya acabado la finalidad para la que fueron
                recabados, salvo que los datos deban mantenerse por una obligación legal.</p>

            <p>ACTIVIDAD: CONTACTO O CONSULTAS</p>

            <p>PROCEDENCIA</p>

            <p>Del propio Interesado a través del envío de correos electrónicos a la dirección indicada, del
                formulario o haciendo uso de cualquier número de teléfono facilitados a través del Sitio
                Web. BASE JURÍDICA Reglamento General de Protección de Datos, en particular: Art.
                6.1.b): petición de medidas precontractuales.</p>

            <p>FINES TRATAMIENTO Atender y responder las consultas solicitadas por el Interesado.
                COLECTIVO Personas físicas o jurídicas</p>

            <p>CATEGORÍA DE DATOS</p>

            <p>Dirección de correo electrónico, así como el resto de los datos que el propio Interesado
                indique en el cuerpo del mensaje.</p>

            <p>DESTINATARIOS</p>

            <p>No está prevista la cesión de datos a terceros, salvo obligación legal o previo
                consentimiento inequívoco e informado del interesado.</p>

            <p>TRANSFERENCIA INTERNACIONAL No está prevista la transferencia internacional de los
                datos.</p>

            <p>PLAZOS DE CONSERVACIÓN</p>

            <p>Los datos serán conservados durante el plazo estrictamente necesario para cumplir con el
                fin para el cual fueron recabados. En cualquier caso, el plazo máximo de conservación será
                de 1 año.</p>

            <h1 className='mt-5'>4. DATOS DEL INTERESADO</h1>

            <p>El Interesado garantiza que es mayor de dieciocho (18) años y que los datos aportados son
                verdaderos, exactos, completos y actualizados, siendo este responsable de cualquier daño
                o perjuicio, directo o indirecto, que pudiera ocasionarse como consecuencia del
                incumplimiento de tal obligación, más allá de la posibilidad que tiene el Interesado de
                ejercitar su derecho de rectificación.</p>

            <p>En el caso de que los datos aportados pertenecieran a un tercero, el Interesado garantiza
                que ha informado a dicho tercero de los aspectos contenidos en este documento y obtenido
                su autorización para facilitar sus datos al responsable para los fines anteriormente
                señalados.</p>

            <p>El Responsable no puede garantizar la absoluta invulnerabilidad de los sistemas, por tanto,
                no asume ninguna responsabilidad por los daños y perjuicios derivados de alteraciones que
                terceros puedan causar en los sistemas informáticos, documentos electrónicos o ficheros.</p>

            <h1 className='mt-5'>5. POLÍTICA DE COOKIES</h1>

            <p>De conformidad con lo dispuesto por el RGPD y la Ley 34/2002, de Servicios de la Sociedad
                de la Información, todos los datos de carácter personal obtenidos mediante las cookies
                durante la utilización del Sitio Web serán tratados de conformidad con los dispuesto en la
                Política de Cookies.</p>

            <h1 className='mt-5'>6. EJERCICIO DE DERECHOS</h1>

            <p>De conformidad con lo dispuesto en el RGPD, le informamos que puede ejercer sus
                derechos de acceso, rectificación, supresión, oposición, portabilidad de los datos y
                limitación del tratamiento directamente ante el Responsable. Para el ejercicio de dichos
                derechos, el Interesado podrá enviar un mensaje al efecto, indicando nombre, apellidos,
                email, número de DNI o pasaporte, a la siguiente dirección de correo electrónico: dpo@LA
                CASA DEL TOKEN El Interesado también podrá ejercer sus derechos mediante la
                presentación de una reclamación ante la Agencia Española de Protección de Datos. 7.
                ACEPTACIÓN DE LA POLÍTICA DE PRIVACIDAD</p>

            <p>El Interesado RECONOCE Y ACEPTA HABER LEÍDO Y COMPRENDIDO la presente
                Política de Privacidad cuyo contenido constituye la totalidad del acuerdo entre el Interesado
                y el Responsable con respecto al uso y tratamiento de su información personal. El
                Interesado expresamente acepta obligarse en los términos de la presente Política de
                Privacidad, en toda su extensión y alcance, sin exceptuar cualquiera de sus disposiciones.
                Será indispensable la aceptación expresa del Presente cuando el Usuario se registre a
                través del Sitio Web rellenando el correspondiente formulario de registro.</p>

            <p>2021 © Todos los derechos reservados. THE TOKEN HOUSE S.L.</p>

            <p>Última actualización: 20 de abril de 2022.</p>
        </Container>
    );
}

export default Privacy;