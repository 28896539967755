import 'bootstrap/dist/css/bootstrap.min.css';
import './Newsletter.css';
import { Button, Container, Row, Col, Form, InputGroup, Spinner } from 'react-bootstrap';
import { FaTelegramPlane } from "react-icons/fa";

import { useMutation, gql } from '@apollo/client';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import AppSettings from '../../AppSettings';


const NewsLetter = () => {


    const { settings } = AppSettings();


    // (+) Form

    const { register, handleSubmit, reset, formState, formState: { errors, isSubmitSuccessful } } = useForm();

    const onSubmit = data => subscribeNewsletter({ variables: data });

    useEffect(() => {
        if (formState.isSubmitSuccessful) reset();
    }, [formState, reset]);

    // (-) Form


    // (+) Graphql

    const SUBSCRIBE_NWESLETTER = gql`${settings.graphql.mutation.subscribeNewsletter}`;

    const [subscribeNewsletter, { data, loading, error }] = useMutation(SUBSCRIBE_NWESLETTER);

    if (error)
        toast(error.message);

    if (data && formState.isSubmitSuccessful)
        toast((data.subscribeNewsletter && data.subscribeNewsletter.ok) ? 'Se ha suscripto con éxito.' : 'No se puede suscribir.', {
            type: 'error',
            icon: false
        });

    // (-) Graphql

    return (
        <Container className='p-5'>
            <Row className='mt-5'>
                <Col xs={12} md={2}></Col>
                <Col xs={12} md={4}>
                    <p className='text-color text-size'>Únete a nuestra <span className='text-light'>newsletter</span> para obtener información que te ayude a invertir con <span className='text-light'>La Casa del Token</span></p>
                </Col>
                <Col xs={12} md={4}>
                    <Form>
                        <Form.Group className='mb-3'>
                            <Form.Control type="email" className='input-contact' placeholder="Correo electrónico"  {...register("email", { required: true })} />
                            {errors.email &&
                                <Form.Text className="text-muted">
                                    Debe ingresar el email
                                </Form.Text>
                            }
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Check type="checkbox" label="Acepto las políticas de privacidad y deseo recibir comunicaciones comerciales sobre los servicios de Adapta Socios." {...register("comercialComunications", { required: true })} />
                            {errors.comercialComunications &&
                                <Form.Text className="text-muted">
                                    Debe aceptar las politicas de privacidad
                                </Form.Text>
                            }
                        </Form.Group>
                        {/* <Form.Group>
                            <Form.Check type='checkbox' label="Deseo recibir comunicaciones comerciales sobre los servicios de Adapta Socios." {...register("comercialComunications")} />
                        </Form.Group> */}

                        <Row className='justify-content-end'>
                            <Col xs={2} md={2}>
                                <Button variant="outline-light" type="submit" className='btn-gb' disabled={loading} onClick={handleSubmit(onSubmit)}>
                                    {loading &&
                                        <Spinner animation="border" size="sm" variant="light" role="status"></Spinner>
                                    }
                                    {!loading &&
                                        <FaTelegramPlane />
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col xs={12} md={2}></Col>
            </Row>
        </Container>
    );
}

export default NewsLetter;