import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { HiOutlineChevronRight } from "react-icons/hi";
import { SiTrustpilot } from 'react-icons/si';
import { FaTelegramPlane, FaInstagram, FaTwitter, FaFacebook, FaTiktok, FaReddit } from "react-icons/fa";

// + IMAGES +
// + IMAGES +

const Footer = () => {
    return (
        <>
            <div className='bg-footer p-4'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col xs={12} md={2}></Col>
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={11} md={8}>
                                    <a className='nameFooter' href='/contacto'>PROPIEDADES</a>
                                </Col>
                                <Col xs={1} md={4}>
                                    <HiOutlineChevronRight />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={11} md={8}>
                                    <a className='nameFooter' href='/equipo-casa-del-token'>SOBRE NOSOTROS</a>
                                </Col>
                                <Col xs={1} md={4}>
                                    <HiOutlineChevronRight />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={11} md={8}>
                                    <a className='nameFooter' href='/como-invertir-en-inmuebles-tokenizados'>¿Cómo funciona?</a>
                                </Col>
                                <Col xs={1} md={4}>
                                    <HiOutlineChevronRight />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={11} md={8}>
                                    <a className='nameFooter' href='/faqs-inversion-inmuebles-tokenizados'>FAQS</a>
                                </Col>
                                <Col xs={1} md={4}>
                                    <HiOutlineChevronRight />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={11} md={8}>
                                    <a className='nameFooter' href='/contacto'>CONTACTO</a>
                                </Col>
                                <Col xs={1} md={4}>
                                    <HiOutlineChevronRight />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={11} md={8}>
                                    <a className='nameFooter' href='/blog-tokenizar-inmueble'>BLOG</a>
                                </Col>
                                <Col xs={1} md={4}>
                                    <HiOutlineChevronRight />
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col xs={11} md={8}>
                                    <a className='nameFooter' href='/login'>LOGIN</a>
                                </Col>
                                <Col xs={1} md={4}>
                                    <HiOutlineChevronRight />
                                </Col>
                            </Row> */}

                            <Row className='align-items-center container-social'>
                                <Col xs={2} md={2} className="text-center">
                                    <Button variant="clear" href='https://t.me/lacasadeltoken' target='_blank'>
                                        <FaTelegramPlane className='text-dark' />
                                    </Button>
                                </Col>
                                <Col xs={2} md={2} className="text-center">
                                    <Button variant="clear" href='https://www.instagram.com/lacasadeltoken/' target='_blank'>
                                        <FaInstagram className='text-dark' />
                                    </Button>
                                </Col>
                                <Col xs={2} md={2} className="text-center">
                                    <Button variant="clear" href='https://www.facebook.com/lacasadeltoken' target='_blank'>
                                        <FaFacebook className='text-dark' />
                                    </Button>
                                </Col>
                                <Col xs={2} md={2} className="text-center">
                                    <Button variant="clear" href='https://www.reddit.com/user/LaCasaDelToken' target='_blank'>
                                        <FaTwitter className='text-dark' />
                                    </Button>
                                </Col>
                                <Col xs={2} md={2} className="text-center">
                                    <Button variant="clear" href='https://www.tiktok.com/@lacasadeltoken?lang=es' target='_blank'>
                                        <FaTiktok className='text-dark' />
                                    </Button>
                                </Col>
                                <Col xs={2} md={2} className="text-center">
                                    <Button variant="clear" href='https://www.reddit.com/user/LaCasaDelToken' target='_blank'>
                                        <FaReddit className='text-dark' />
                                    </Button>
                                </Col>
                                <Col xs={2} md={2} className="text-center">
                                    <Button variant="clear" href='https://es.trustpilot.com/review/lacasadeltoken.com' target='_blank'>
                                        <SiTrustpilot className='text-dark' />
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={2}></Col>
                    </Row>
                </Container>
            </div>

            <div className='bg-footer2 p-4'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col xs={12} md={4} className='text-center'>
                            <a href='/aviso-legal'>
                                <p className='text-light txt-footer'><u>Legal</u></p>
                            </a>
                        </Col>
                        <Col xs={12} md={4} className='text-center'>
                            <a href='/politica-de-cookies'>
                                <p className='text-light txt-footer'><u>Política de cookies</u></p>
                            </a>
                        </Col>
                        <Col xs={12} md={4} className='text-center'>
                            <a href='/politica-de-privacidad'>
                                <p className='text-light txt-footer'><u>Política de Privacidad</u></p>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Footer;