import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Fab.css';
import { Button } from 'react-bootstrap';
import { FaTelegramPlane, FaInstagram, FaTwitter, FaFacebook, FaTiktok, FaReddit } from "react-icons/fa";
import { SiTrustpilot } from 'react-icons/si';
import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi';

const Fab = () => {

    const [fabMobile, setFabMobile] = useState(false);

    return (
        <>
            <div className='social-btns d-none d-md-flex'>
                <ul>
                    <li>
                        <Button variant="clear" href='https://t.me/lacasadeltoken' target='_blank'>
                            <FaTelegramPlane className='social-hover' />
                        </Button>
                    </li>
                    <li>
                        <Button variant="clear" href='https://www.instagram.com/lacasadeltoken/' target='_blank'>
                            <FaInstagram className='social-hover' />
                        </Button>
                    </li>
                    <li>
                        <Button variant="clear" href='https://www.facebook.com/lacasadeltoken' target='_blank'>
                            <FaFacebook className='social-hover' />
                        </Button>
                    </li>
                    <li>
                        <Button variant="clear" href='https://www.tiktok.com/@lacasadeltoken?lang=es' target='_blank'>
                            <FaTiktok className='social-hover' />
                        </Button>
                    </li>
                    <li>
                        <Button variant="clear" href='https://www.reddit.com/user/LaCasaDelToken' target='_blank'>
                            <FaReddit className='social-hover' />
                        </Button>
                    </li>
                    <li>
                        <Button variant="clear" href='https://twitter.com/lacasadeltoken' target='_blank'>
                            <FaTwitter className='social-hover' />
                        </Button>
                    </li>
                    <li>
                        <Button variant="clear" href='https://es.trustpilot.com/review/lacasadeltoken.com' target='_blank'>
                            <SiTrustpilot className='social-hover' />
                        </Button>
                    </li>
                </ul>
            </div>

            <div className={`social-btns d-flex d-md-none ${fabMobile ? 'social-btn-active' : ''}`}>
                <ul>
                    <li>
                        <Button variant="clear" href='https://t.me/lacasadeltoken' target='_blank'>
                            <FaTelegramPlane className='social-hover' />
                        </Button>
                        {!fabMobile &&
                            <Button variant="clear" onClick={() => setFabMobile(true)}>
                                <FiChevronsDown className='text-light' />
                            </Button>
                        }
                    </li>
                    {fabMobile &&
                        <>
                            <li>
                                <Button variant="clear" href='https://www.instagram.com/lacasadeltoken/' target='_blank'>
                                    <FaInstagram className='social-hover' />
                                </Button>
                            </li>
                            <li>
                                <Button variant="clear" href='https://www.facebook.com/lacasadeltoken' target='_blank'>
                                    <FaFacebook className='social-hover' />
                                </Button>
                            </li>
                            <li>
                                <Button variant="clear" href='https://www.tiktok.com/@lacasadeltoken?lang=es' target='_blank'>
                                    <FaTiktok className='social-hover' />
                                </Button>
                            </li>
                            <li>
                                <Button variant="clear" href='https://www.reddit.com/user/LaCasaDelToken' target='_blank'>
                                    <FaReddit className='social-hover' />
                                </Button>
                            </li>
                            <li>
                                <Button variant="clear" href='https://twitter.com/lacasadeltoken' target='_blank'>
                                    <FaTwitter className='social-hover' />
                                </Button>
                            </li>
                            <li>
                                <Button variant="clear" href='https://es.trustpilot.com/review/lacasadeltoken.com' target='_blank'>
                                    <SiTrustpilot className='social-hover' />
                                </Button>
                            </li>
                            <li>
                                <Button variant="clear" onClick={() => setFabMobile(false)}>
                                    <FiChevronsUp className='text-light' />
                                </Button>
                            </li>
                        </>
                    }
                </ul>
            </div>
        </>
    );
}

export default Fab;