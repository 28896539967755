import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ProgressBar, Row, Col, Button, Card } from 'react-bootstrap';
import './Investments.css';
import { useNavigate } from 'react-router-dom';
import { IoLocationOutline } from "react-icons/io5";
import SideMenu from '../../components/sidemenu/SideMenu';
import SearchMenu from '../../components/searchmenu/SearchMenu';

// + IMAGES +
import card from '../../assets/imgs/img_card.png';
// - IMAGES -

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Mousewheel, Keyboard } from "swiper";

const Investments = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const navigate = useNavigate();

    const goToProjects = () => {
        navigate('/projects');
    }

    const goToProjectsDetail = () => {
        navigate('/projects-detail');
    }

    return (
        <>
            <SideMenu />
            <div className='position-relative overflow-hidden container-fluid pb-5'>
                <SearchMenu />
                <div className='container-left-dash-a'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-left-dash-b'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-center-dash'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-right-dash-a'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-right-dash-b'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-right-dash-c'>
                    <div className='ball-c'></div>
                </div>

                <div className='container-right-dash-d'>
                    <div className='ball-c'></div>
                </div>

                <Row className='align-items-center pt-5'>
                    <Col xs={12} md={3}></Col>
                    <Col xs={12} md={9}>
                        <div className='investment-container'>
                            <h5 className='text-light'>Mis Inversiones</h5>

                            <Swiper
                                cssMode={true}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Mousewheel, Keyboard]}
                                breakpoints={{
                                    360: {
                                        slidesPerView: 1.1,
                                        spaceBetween: 20,
                                    },
                                    640: {
                                        slidesPerView: 1.1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 1.1,
                                        spaceBetween: 30,
                                    },
                                    1024: {
                                        slidesPerView: 2.2,
                                        spaceBetween: 30,
                                    },
                                    1300: {
                                        slidesPerView: 2.2,
                                        spaceBetween: 30,
                                    },
                                }}
                                className="swipper-cards-proyects mt-4">
                                <SwiperSlide position-relative>
                                    <Card className='card-investment'>
                                        <Card.Body>
                                            <Row>
                                                <Col xs={5} md={6}>
                                                    <div className='investment-img'>
                                                        <div className='chip-investment'>
                                                            <span className="dot dot-subscription"></span>
                                                            <p className='text-white text-uppercase m-0 font-chip'>en SUBSCRIPCIÓN</p>
                                                        </div>
                                                        <img src={card} width="100%" />
                                                    </div>
                                                </Col>
                                                <Col xs={7} md={6} className='p-3'>
                                                    <p className='text-white text-uppercase m-0'>INMUEBLE</p>
                                                    <p className='text-white m-0'><b>Calle Mendivil 25, Madrid</b></p>

                                                    <div className='mt-3'>
                                                        <p className='text-white text-uppercase m-0'>RENTABILIDAD</p>
                                                        <p className='text-white m-0'><b>8%</b></p>
                                                    </div>

                                                    <div className='mt-3'>
                                                        <p className='text-white text-uppercase m-0'>EJECUCIÓN</p>
                                                        <p className='text-white m-0'><b>24 meses</b></p>
                                                    </div>

                                                    <div className='mt-3'>
                                                        <Button variant='outline-light' className='btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>DETALLE</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </SwiperSlide>

                                <SwiperSlide position-relative>
                                    <Card className='card-investment'>
                                        <Card.Body>
                                            <Row>
                                                <Col xs={5} md={6}>
                                                    <div className='investment-img'>
                                                        <div className='chip-investment'>
                                                            <span className="dot dot-design"></span>
                                                            <p className='text-white text-uppercase m-0 font-chip'>FINALIZADO</p>
                                                        </div>
                                                        <img src={card} width="100%" />
                                                    </div>
                                                </Col>
                                                <Col xs={7} md={6} className='p-3'>
                                                    <p className='text-white text-uppercase m-0'>INMUEBLE</p>
                                                    <p className='text-white m-0'><b>Calle Mendivil 25, Madrid</b></p>

                                                    <div className='mt-3'>
                                                        <p className='text-white text-uppercase m-0'>RENTABILIDAD</p>
                                                        <p className='text-white m-0'><b>8%</b></p>
                                                    </div>

                                                    <div className='mt-3'>
                                                        <p className='text-white text-uppercase m-0'>EJECUCIÓN</p>
                                                        <p className='text-white m-0'><b>24 meses</b></p>
                                                    </div>

                                                    <div className='mt-3'>
                                                        <Button variant='outline-light' className='btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>DETALLE</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </SwiperSlide>

                                <SwiperSlide position-relative>
                                    <Card className='card-investment'>
                                        <Card.Body>
                                            <Row>
                                                <Col xs={5} md={6}>
                                                    <div className='investment-img'>
                                                        <div className='chip-investment'>
                                                            <span className="dot dot-design"></span>
                                                            <p className='text-white text-uppercase m-0 font-chip'>FINALIZADO</p>
                                                        </div>
                                                        <img src={card} width="100%" />
                                                    </div>
                                                </Col>
                                                <Col xs={7} md={6} className='p-3'>
                                                    <p className='text-white text-uppercase m-0'>INMUEBLE</p>
                                                    <p className='text-white m-0'><b>Calle Mendivil 25, Madrid</b></p>

                                                    <div className='mt-3'>
                                                        <p className='text-white text-uppercase m-0'>RENTABILIDAD</p>
                                                        <p className='text-white m-0'><b>8%</b></p>
                                                    </div>

                                                    <div className='mt-3'>
                                                        <p className='text-white text-uppercase m-0'>EJECUCIÓN</p>
                                                        <p className='text-white m-0'><b>24 meses</b></p>
                                                    </div>

                                                    <div className='mt-3'>
                                                        <Button variant='outline-light' className='btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>DETALLE</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </Col>
                </Row>

                {/* SUSCRIPCION */}

                <Row className='align-items-center pb-5 pt-5'>
                    <Col xs={12} md={3}></Col>
                    <Col xs={12} md={9}>
                        <div className='investment-container'>
                            <h5 className='text-light'>Inmuebles en Suscripción</h5>
                            <Swiper
                                cssMode={true}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Mousewheel, Keyboard]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    1300: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                }}
                                className="swipper-cards-proyects mt-4">
                                <SwiperSlide position-relative>

                                    <Card className='container-card-white card-investment2'>
                                        <div className='container-img-card'>
                                            <div className='mascara'>
                                                <Card.Img src={card} />
                                            </div>
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                    <p className='text-gb custom-bold text-uppercase'>8%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                    <p className='text-gb custom-bold'>24 meses</p>
                                                </Col>
                                            </Row>
                                            <Card.Text className='custom-ligth'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                            </Card.Text>

                                            <ProgressBar now={50} className='custom-progress mt-4' />
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1'>50%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <label>
                                                        <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                        <p className='text-gb custom-bold text-end'>100 €</p>
                                                    </label>
                                                </Col>
                                                <Col xs={6} md={6} className='text-end'>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>Detalle</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='custom-card-white'></div>
                                </SwiperSlide>

                                <SwiperSlide position-relative>

                                    <Card className='container-card-white card-investment2'>
                                        <div className='container-img-card'>
                                            <div className='mascara'>
                                                <Card.Img src={card} />
                                            </div>
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                    <p className='text-gb custom-bold text-uppercase'>8%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                    <p className='text-gb custom-bold'>24 meses</p>
                                                </Col>
                                            </Row>
                                            <Card.Text className='custom-ligth'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                            </Card.Text>

                                            <ProgressBar now={50} className='custom-progress mt-4' />
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1'>50%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <label>
                                                        <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                        <p className='text-gb custom-bold text-end'>100 €</p>
                                                    </label>
                                                </Col>
                                                <Col xs={6} md={6} className='text-end'>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>Detalle</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='custom-card-white'></div>
                                </SwiperSlide>

                                <SwiperSlide position-relative>

                                    <Card className='container-card-white card-investment2'>
                                        <div className='container-img-card'>
                                            <div className='mascara'>
                                                <Card.Img src={card} />
                                            </div>
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                    <p className='text-gb custom-bold text-uppercase'>8%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                    <p className='text-gb custom-bold'>24 meses</p>
                                                </Col>
                                            </Row>
                                            <Card.Text className='custom-ligth'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                            </Card.Text>

                                            <ProgressBar now={50} className='custom-progress mt-4' />
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1'>50%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <label>
                                                        <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                        <p className='text-gb custom-bold text-end'>100 €</p>
                                                    </label>
                                                </Col>
                                                <Col xs={6} md={6} className='text-end'>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>Detalle</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='custom-card-white'></div>
                                </SwiperSlide>

                                <SwiperSlide position-relative>

                                    <Card className='container-card-white card-investment2'>
                                        <div className='container-img-card'>
                                            <div className='mascara'>
                                                <Card.Img src={card} />
                                            </div>
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                    <p className='text-gb custom-bold text-uppercase'>8%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                    <p className='text-gb custom-bold'>24 meses</p>
                                                </Col>
                                            </Row>
                                            <Card.Text className='custom-ligth'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                            </Card.Text>

                                            <ProgressBar now={50} className='custom-progress mt-4' />
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1'>50%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <label>
                                                        <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                        <p className='text-gb custom-bold text-end'>100 €</p>
                                                    </label>
                                                </Col>
                                                <Col xs={6} md={6} className='text-end'>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>Detalle</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='custom-card-white'></div>
                                </SwiperSlide>

                                <SwiperSlide position-relative>

                                    <Card className='container-card-white card-investment2'>
                                        <div className='container-img-card'>
                                            <div className='mascara'>
                                                <Card.Img src={card} />
                                            </div>
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                    <p className='text-gb custom-bold text-uppercase'>8%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                    <p className='text-gb custom-bold'>24 meses</p>
                                                </Col>
                                            </Row>
                                            <Card.Text className='custom-ligth'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                            </Card.Text>

                                            <ProgressBar now={50} className='custom-progress mt-4' />
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1'>50%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <label>
                                                        <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                        <p className='text-gb custom-bold text-end'>100 €</p>
                                                    </label>
                                                </Col>
                                                <Col xs={6} md={6} className='text-end'>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>Detalle</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='custom-card-white'></div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                        <div className='pt-4 mb-4'>
                            <Button variant='outline-light m-auto btn-solid custom-ma' className='btn-gb text-uppercase' onClick={goToProjects}>
                                VER INMUEBLES EN SUSCRIPCIÓN
                            </Button>
                        </div>

                        {/* EN DISEÑO */}

                        <div className='investment-container'>
                            <h5 className='text-light'>Inmuebles en Diseño</h5>
                            <Swiper
                                cssMode={true}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Mousewheel, Keyboard]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    1300: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                }}
                                className="swipper-cards-proyects mt-4">
                                <SwiperSlide position-relative>

                                    <Card className='container-card-white card-investment2'>
                                        <div className='container-img-card'>
                                            <div className='mascara'>
                                                <Card.Img src={card} />
                                            </div>
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                    <p className='text-gb custom-bold text-uppercase'>8%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                    <p className='text-gb custom-bold'>24 meses</p>
                                                </Col>
                                            </Row>
                                            <Card.Text className='custom-ligth'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                            </Card.Text>

                                            <ProgressBar now={50} className='custom-progress mt-4' />
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1'>50%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <label>
                                                        <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                        <p className='text-gb custom-bold text-end'>100 €</p>
                                                    </label>
                                                </Col>
                                                <Col xs={6} md={6} className='text-end'>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>Detalle</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='custom-card-white'></div>
                                </SwiperSlide>

                                <SwiperSlide position-relative>

                                    <Card className='container-card-white card-investment2'>
                                        <div className='container-img-card'>
                                            <div className='mascara'>
                                                <Card.Img src={card} />
                                            </div>
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                    <p className='text-gb custom-bold text-uppercase'>8%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                    <p className='text-gb custom-bold'>24 meses</p>
                                                </Col>
                                            </Row>
                                            <Card.Text className='custom-ligth'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                            </Card.Text>

                                            <ProgressBar now={50} className='custom-progress mt-4' />
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1'>50%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <label>
                                                        <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                        <p className='text-gb custom-bold text-end'>100 €</p>
                                                    </label>
                                                </Col>
                                                <Col xs={6} md={6} className='text-end'>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>Detalle</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='custom-card-white'></div>
                                </SwiperSlide>

                                <SwiperSlide position-relative>

                                    <Card className='container-card-white card-investment2'>
                                        <div className='container-img-card'>
                                            <div className='mascara'>
                                                <Card.Img src={card} />
                                            </div>
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                    <p className='text-gb custom-bold text-uppercase'>8%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                    <p className='text-gb custom-bold'>24 meses</p>
                                                </Col>
                                            </Row>
                                            <Card.Text className='custom-ligth'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                            </Card.Text>

                                            <ProgressBar now={50} className='custom-progress mt-4' />
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1'>50%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <label>
                                                        <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                        <p className='text-gb custom-bold text-end'>100 €</p>
                                                    </label>
                                                </Col>
                                                <Col xs={6} md={6} className='text-end'>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>Detalle</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='custom-card-white'></div>
                                </SwiperSlide>

                                <SwiperSlide position-relative>

                                    <Card className='container-card-white card-investment2'>
                                        <div className='container-img-card'>
                                            <div className='mascara'>
                                                <Card.Img src={card} />
                                            </div>
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                    <p className='text-gb custom-bold text-uppercase'>8%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                    <p className='text-gb custom-bold'>24 meses</p>
                                                </Col>
                                            </Row>
                                            <Card.Text className='custom-ligth'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                            </Card.Text>

                                            <ProgressBar now={50} className='custom-progress mt-4' />
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1'>50%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <label>
                                                        <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                        <p className='text-gb custom-bold text-end'>100 €</p>
                                                    </label>
                                                </Col>
                                                <Col xs={6} md={6} className='text-end'>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>Detalle</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='custom-card-white'></div>
                                </SwiperSlide>

                                <SwiperSlide position-relative>

                                    <Card className='container-card-white card-investment2'>
                                        <div className='container-img-card'>
                                            <div className='mascara'>
                                                <Card.Img src={card} />
                                            </div>
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                    <p className='text-gb custom-bold text-uppercase'>8%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                    <p className='text-gb custom-bold'>24 meses</p>
                                                </Col>
                                            </Row>
                                            <Card.Text className='custom-ligth'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                            </Card.Text>

                                            <ProgressBar now={50} className='custom-progress mt-4' />
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1'>50%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <label>
                                                        <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                        <p className='text-gb custom-bold text-end'>100 €</p>
                                                    </label>
                                                </Col>
                                                <Col xs={6} md={6} className='text-end'>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>Detalle</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='custom-card-white'></div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                        <div className='pt-4 mb-4'>
                            <Button variant='outline-light m-auto btn-solid custom-ma' className='btn-gb text-uppercase' onClick={goToProjects}>
                                VER INMUEBLES EN DISEÑO
                            </Button>
                        </div>

                        {/* FINALIZADOS */}

                        <div className='investment-container'>
                            <h5 className='text-light'>Inmuebles Finalizados</h5>
                            <Swiper
                                cssMode={true}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Mousewheel, Keyboard]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    1300: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                }}
                                className="swipper-cards-proyects mt-4">
                                <SwiperSlide position-relative>

                                    <Card className='container-card-white card-investment2'>
                                        <div className='container-img-card'>
                                            <div className='mascara'>
                                                <Card.Img src={card} />
                                            </div>
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                    <p className='text-gb custom-bold text-uppercase'>8%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                    <p className='text-gb custom-bold'>24 meses</p>
                                                </Col>
                                            </Row>
                                            <Card.Text className='custom-ligth'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                            </Card.Text>

                                            <ProgressBar now={50} className='custom-progress mt-4' />
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1'>50%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <label>
                                                        <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                        <p className='text-gb custom-bold text-end'>100 €</p>
                                                    </label>
                                                </Col>
                                                <Col xs={6} md={6} className='text-end'>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>Detalle</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='custom-card-white'></div>
                                </SwiperSlide>

                                <SwiperSlide position-relative>

                                    <Card className='container-card-white card-investment2'>
                                        <div className='container-img-card'>
                                            <div className='mascara'>
                                                <Card.Img src={card} />
                                            </div>
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                    <p className='text-gb custom-bold text-uppercase'>8%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                    <p className='text-gb custom-bold'>24 meses</p>
                                                </Col>
                                            </Row>
                                            <Card.Text className='custom-ligth'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                            </Card.Text>

                                            <ProgressBar now={50} className='custom-progress mt-4' />
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1'>50%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <label>
                                                        <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                        <p className='text-gb custom-bold text-end'>100 €</p>
                                                    </label>
                                                </Col>
                                                <Col xs={6} md={6} className='text-end'>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>Detalle</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='custom-card-white'></div>
                                </SwiperSlide>

                                <SwiperSlide position-relative>

                                    <Card className='container-card-white card-investment2'>
                                        <div className='container-img-card'>
                                            <div className='mascara'>
                                                <Card.Img src={card} />
                                            </div>
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                    <p className='text-gb custom-bold text-uppercase'>8%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                    <p className='text-gb custom-bold'>24 meses</p>
                                                </Col>
                                            </Row>
                                            <Card.Text className='custom-ligth'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                            </Card.Text>

                                            <ProgressBar now={50} className='custom-progress mt-4' />
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1'>50%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <label>
                                                        <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                        <p className='text-gb custom-bold text-end'>100 €</p>
                                                    </label>
                                                </Col>
                                                <Col xs={6} md={6} className='text-end'>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>Detalle</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='custom-card-white'></div>
                                </SwiperSlide>

                                <SwiperSlide position-relative>

                                    <Card className='container-card-white card-investment2'>
                                        <div className='container-img-card'>
                                            <div className='mascara'>
                                                <Card.Img src={card} />
                                            </div>
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                    <p className='text-gb custom-bold text-uppercase'>8%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                    <p className='text-gb custom-bold'>24 meses</p>
                                                </Col>
                                            </Row>
                                            <Card.Text className='custom-ligth'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                            </Card.Text>

                                            <ProgressBar now={50} className='custom-progress mt-4' />
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1'>50%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <label>
                                                        <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                        <p className='text-gb custom-bold text-end'>100 €</p>
                                                    </label>
                                                </Col>
                                                <Col xs={6} md={6} className='text-end'>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>Detalle</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='custom-card-white'></div>
                                </SwiperSlide>

                                <SwiperSlide position-relative>

                                    <Card className='container-card-white card-investment2'>
                                        <div className='container-img-card'>
                                            <div className='mascara'>
                                                <Card.Img src={card} />
                                            </div>
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>rentabilidad</p>
                                                    <p className='text-gb custom-bold text-uppercase'>8%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='custom-ligth text-uppercase mb-1'>ejecucion</p>
                                                    <p className='text-gb custom-bold'>24 meses</p>
                                                </Col>
                                            </Row>
                                            <Card.Text className='custom-ligth'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum morbi proin in venenatis odio. Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.
                                            </Card.Text>

                                            <ProgressBar now={50} className='custom-progress mt-4' />
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1'>50%</p>
                                                </Col>
                                                <Col xs={6} md={6}>
                                                    <p className='text-gb mt-1 text-end'>2.000.000 €</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <label>
                                                        <p className='custom-ligth mb-1'>Inversión mínima</p>
                                                        <p className='text-gb custom-bold text-end'>100 €</p>
                                                    </label>
                                                </Col>
                                                <Col xs={6} md={6} className='text-end'>
                                                    <div className='d-flex justify-content-end'>
                                                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail' onClick={goToProjectsDetail}>Detalle</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className='custom-card-white'></div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                        <div className='pt-4 mb-4'>
                            <Button variant='outline-light m-auto btn-solid custom-ma' className='btn-gb text-uppercase' onClick={goToProjects}>
                                VER INMUEBLES EN FINALIZADOS
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Investments;