import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Button } from 'react-bootstrap';
import './Partners.css';
import Fab from '../../components/fab/Fab';
import Chart from '../../components/chart/Chart';
import NewsLetter from '../../components/newsletter/Newsletter';

// + IMAGES +
import discB from '../../assets/imgs/disc_blue.svg';
import discY from '../../assets/imgs/disc_yellow.svg';
import discG from '../../assets/imgs/disc_green.svg';
import discC from '../../assets/imgs/disc_cyan.svg';
import criptoB from '../../assets/imgs/cripto_azul_gif.gif';
import criptoY from '../../assets/imgs/cripto_amarillo_gif.gif';
import criptoC from '../../assets/imgs/cripto_aguamarina_gif.gif';
import criptoG from '../../assets/imgs/cripto_verde_gif.gif';
// - IMAGES -

const Partners = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <Fab />
            <div className='containter-section1 container-fluid pb-5 pt-5'>
                <div className='container position-relative custom-mt-firts'>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='custom-pl'>
                                <h2 className='subtitle'>Equipo de<br /> La casa del token</h2>
                                <h3 className='subtitle-text'>conoce a nuestros socios</h3>
                                <div className='d-none d-sm-block'>
                                    <p className='text-gb text-work'><span className='text-muted'>Somos la primera plataforma de</span> inmuebles tokenizados <span className='text-muted'> que permite la</span> inversión y retirada de los beneficios<span className='text-muted'> tanto en crypto como en FIAT</span> ampliando así las posibilidades de inversión.</p>
                                    <p className='text-gb text-work'><span className='text-muted'>El expertise en áreas como tecnología, legal y Real Estate nos permite ofrecer una </span>experiencia de usuario única y <span className='text-muted'>excelente.</span></p>
                                    <div className='pt-4 mb-4'>
                                        <Button variant='outline-light btn-solid' className='btn-gb text-uppercase'>
                                            Visitar web
                                        </Button>
                                    </div>
                                </div>
                                <div className='d-sm-none'>
                                    <div className='container-gif'>
                                        <div className='text-center'>
                                            <img src={criptoC} alt="" className='img-fluid' />
                                            <h2 className='text-white text-uppercase title-slider'>adapta socios</h2>
                                        </div>
                                        <div>
                                            <img src={discC} alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='container-truncate  mt-4'>
                                        <div className='text-truncate'>
                                            <p className='text-gb text-work'><span className='text-muted'>Somos la primera plataforma de</span> inmuebles tokenizados <span className='text-muted'> que permite la</span> inversión y retirada de los beneficios<span className='text-muted'> tanto en crypto como en FIAT</span> ampliando así las posibilidades de inversión. <br /><span className='text-muted'>El expertise en áreas como tecnología, legal y Real Estate nos permite ofrecer una </span>experiencia de usuario única y <span className='text-muted'>excelente.</span></p>
                                        </div>
                                        <p className='btn-more'>
                                            Leer mas
                                        </p>
                                        <div className='pt-4 mb-4'>
                                            <Button variant='outline-light btn-solid' className='btn-gb text-uppercase w-75 m-auto'>
                                                Visitar web
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col md={6} className='d-none d-sm-block'>
                            <div className='container-gif'>
                                <div className='text-center'>
                                    <img src={criptoC} alt="" className='img-fluid' />
                                    <h2 className='text-white text-uppercase title-slider'>adapta socios</h2>
                                </div>
                                <div>
                                    <img src={discC} alt="" className='img-fluid' />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='container-green-left'>
                        <div className='ball-cyan'></div>
                    </div>
                    <div className="container-green-right">
                        <div className='ball-cyan'></div>
                    </div>
                </div>
                <div className='container position-relative custom-mt'>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='container-gif'>
                                <div className='text-center'>
                                    <img src={criptoG} alt="" className='img-fluid' />
                                    <h2 className='text-white text-uppercase title-slider'>ALEDRA LEGAL</h2>
                                </div>
                                <div>
                                    <img src={discG} alt="" className='img-fluid' />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='custom-pl'>
                                <div className='d-none d-sm-block'>
                                    <h2 className='subtitle'>Equipo de<br /> La casa del token</h2>
                                    <h3 className='subtitle-text'>conoce a nuestros socios</h3>
                                    <p className='text-gb text-work'><span className='text-muted'>Somos la primera plataforma de</span> inmuebles tokenizados <span className='text-muted'> que permite la</span> inversión y retirada de los beneficios<span className='text-muted'> tanto en crypto como en FIAT</span> ampliando así las posibilidades de inversión.</p>
                                    <p className='text-gb text-work'><span className='text-muted'>El expertise en áreas como tecnología, legal y Real Estate nos permite ofrecer una </span>experiencia de usuario única y <span className='text-muted'>excelente.</span></p>
                                    <div className='pt-4 mb-4'>
                                        <Button variant='outline-light btn-solid' className='btn-gb text-uppercase'>
                                            Visitar web
                                        </Button>
                                    </div>
                                </div>
                                <div className='d-sm-none'>
                                    <div className='container-truncate'>
                                        <div className='text-truncate mt-4'>
                                            <p className='text-gb text-work'><span className='text-muted'>Somos la primera plataforma de</span> inmuebles tokenizados <span className='text-muted'> que permite la</span> inversión y retirada de los beneficios<span className='text-muted'> tanto en crypto como en FIAT</span> ampliando así las posibilidades de inversión. <br /><span className='text-muted'>El expertise en áreas como tecnología, legal y Real Estate nos permite ofrecer una </span>experiencia de usuario única y <span className='text-muted'>excelente.</span></p>
                                        </div>
                                        <p className='btn-more'>
                                            Leer mas
                                        </p>
                                        <div className='pt-4 mb-4'>
                                            <Button variant='outline-light btn-solid' className='btn-gb text-uppercase w-75 m-auto'>
                                                Visitar web
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='container-green-left custom-position-left'>
                        <div className='ball-cyan b-green'></div>
                    </div>
                    <div className="container-green-right custom-position-right">
                        <div className='ball-cyan b-green'></div>
                    </div>
                </div>
                <div className='container position-relative custom-mt'>
                    <Row>
                        <Col xs={12} md={6} className='order-sm-2'>
                            <div className='container-gif'>
                                <div className='text-center'>
                                    <img src={criptoY} alt="" className='img-fluid' />
                                    <h2 className='text-white text-uppercase title-slider'>MINERY REPORT</h2>
                                </div>
                                <div>
                                    <img src={discY} alt="" className='img-fluid' />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className='order-sm-1'>
                            <div className='custom-pl'>
                                <div className='d-none d-sm-block'>
                                    <h2 className='subtitle'>Equipo de<br /> La casa del token</h2>
                                    <h3 className='subtitle-text'>conoce a nuestros socios</h3>
                                    <p className='text-gb text-work'><span className='text-muted'>Somos la primera plataforma de</span> inmuebles tokenizados <span className='text-muted'> que permite la</span> inversión y retirada de los beneficios<span className='text-muted'> tanto en crypto como en FIAT</span> ampliando así las posibilidades de inversión.</p>
                                    <p className='text-gb text-work'><span className='text-muted'>El expertise en áreas como tecnología, legal y Real Estate nos permite ofrecer una </span>experiencia de usuario única y <span className='text-muted'>excelente.</span></p>
                                    <div className='pt-4 mb-4'>
                                        <Button variant='outline-light btn-solid' className='btn-gb text-uppercase'>
                                            Visitar web
                                        </Button>
                                    </div>
                                </div>
                                <div className='d-sm-none'>
                                    <div className='container-truncate'>
                                        <div className='text-truncate mt-4'>
                                            <p className='text-gb text-work'><span className='text-muted'>Somos la primera plataforma de</span> inmuebles tokenizados <span className='text-muted'> que permite la</span> inversión y retirada de los beneficios<span className='text-muted'> tanto en crypto como en FIAT</span> ampliando así las posibilidades de inversión. <br /><span className='text-muted'>El expertise en áreas como tecnología, legal y Real Estate nos permite ofrecer una </span>experiencia de usuario única y <span className='text-muted'>excelente.</span></p>
                                        </div>
                                        <p className='btn-more'>
                                            Leer mas
                                        </p>
                                        <div className='pt-4 mb-4'>
                                            <Button variant='outline-light btn-solid' className='btn-gb text-uppercase w-75 m-auto'>
                                                Visitar web
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='container-green-left custom-position-left'>
                        <div className='ball-cyan b-yellow'></div>
                    </div>
                    <div className="container-green-right custom-position-right">
                        <div className='ball-cyan b-yellow'></div>
                    </div>
                </div>
                {/* <div className='container position-relative custom-mt'>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='container-gif'>
                                <div className='text-center'>
                                    <img src={criptoB} alt="" className='img-fluid' />
                                    <h2 className='text-white text-uppercase title-slider'>bitmotto</h2>
                                </div>
                                <div>
                                    <img src={discB} alt="" className='img-fluid' />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='custom-pl'>
                                <div className='d-none d-sm-block'>
                                    <h2 className='subtitle'>Equipo de<br /> La casa del token</h2>
                                    <h3 className='subtitle-text'>conoce a nuestros socios</h3>
                                    <p className='text-gb text-work'><span className='text-muted'>Somos la primera plataforma de</span> inmuebles tokenizados <span className='text-muted'> que permite la</span> inversión y retirada de los beneficios<span className='text-muted'> tanto en crypto como en FIAT</span> ampliando así las posibilidades de inversión.</p>
                                    <p className='text-gb text-work'><span className='text-muted'>El expertise en áreas como tecnología, legal y Real Estate nos permite ofrecer una </span>experiencia de usuario única y <span className='text-muted'>excelente.</span></p>
                                    <div className='pt-4 mb-4'>
                                        <Button variant='outline-light btn-solid' className='btn-gb text-uppercase'>
                                            Visitar web
                                        </Button>
                                    </div>
                                </div>
                                <div className='d-sm-none'>
                                    <div className='container-truncate'>
                                        <div className='text-truncate mt-4'>
                                            <p className='text-gb text-work'><span className='text-muted'>Somos la primera plataforma de</span> inmuebles tokenizados <span className='text-muted'> que permite la</span> inversión y retirada de los beneficios<span className='text-muted'> tanto en crypto como en FIAT</span> ampliando así las posibilidades de inversión. <br /><span className='text-muted'>El expertise en áreas como tecnología, legal y Real Estate nos permite ofrecer una </span>experiencia de usuario única y <span className='text-muted'>excelente.</span></p>
                                        </div>
                                        <p className='btn-more'>
                                            Leer mas
                                        </p>
                                        <div className='pt-4 mb-4'>
                                            <Button variant='outline-light btn-solid' className='btn-gb text-uppercase w-75 m-auto'>
                                                Visitar web
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='container-green-left custom-position-left'>
                        <div className='ball-cyan b-blue'></div>
                    </div>
                    <div className="container-green-right custom-position-right">
                        <div className='ball-cyan b-blue'></div>
                    </div>
                </div> */}
            </div>
            <NewsLetter />
        </>
    );
}

export default Partners;