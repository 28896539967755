import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import './Login.css';
import { AiOutlineClose } from "react-icons/ai";
import { RiUserLine } from "react-icons/ri";
import { IoLockClosedOutline } from "react-icons/io5";
// + IMAGES +
import step1 from '../../assets/imgs/step_01.svg';
import step2 from '../../assets/imgs/step_02.svg';
import step3 from '../../assets/imgs/step_03.svg';
import arrowDouble from '../../assets/imgs/arrow_double.svg';
import singin from '../../assets/imgs/singin.svg';
// - IMAGES -

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

const Login = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <div className='container-fluid overflow-hidden custom-bg-login'>
                <div className='container vh-100 '>
                    <Swiper
                        cssMode={true}
                        navigation={true}
                        pagination={true}
                        mousewheel={true}
                        keyboard={true}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            1300: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                        }}
                        className="swipper-login"
                    >
                        <SwiperSlide className='custom-slide position-relative'>
                            <div className='text-center  m-4'>
                                <img src={singin} alt="" />
                            </div>
                            <p className='subtitle-text text-center pt-4'>Iniciar Sesión</p>
                            <Form>
                                <div className='mb-4'>
                                    <Form.Group className='mb-3'>
                                        <InputGroup>
                                            <InputGroup.Text className='input-send'>
                                                <RiUserLine className='text-white' />
                                            </InputGroup.Text>
                                            <Form.Control type="email" className='input-contact custom-placeholder' placeholder="Usuario" />
                                        </InputGroup>

                                        <InputGroup className='mt-3'>
                                            <InputGroup.Text className='input-send'>
                                                <IoLockClosedOutline className='text-white' />
                                            </InputGroup.Text>
                                            <Form.Control type="text" className='input-contact custom-placeholder' placeholder="Contraseña" />
                                        </InputGroup>
                                        <div className='w-100 mt-3 text-end'>
                                            <a href="#" className='text-white  text-end'>Olvidé mi contraseña</a>
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className=''>
                                    <div className='pt-4 mb-3'>
                                        <Button variant='outline-light' className='btn-gb btn-global btn-border-light w-100'>acceder</Button>
                                    </div>
                                    <Row className='justify-content-center m-0'>
                                        <Col xs={6}>
                                            <p className='txt-gray text-end'>¿No tienes cuenta?</p>
                                        </Col>
                                        <Col xs={6}>
                                            <a href="#" className='text-white  text-start'>Regístrate Gratis</a>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                            <span className='img-trama'></span>
                        </SwiperSlide>
                        <SwiperSlide className='custom-slide'>
                            <div className='text-end m-4'>
                                <img src={step1} alt="" />
                            </div>
                            <p className='subtitle-text text-center pt-4'>Datos de acceso</p>
                            <Form>
                                <div>
                                    <Form.Group className='mb-3'>
                                        <InputGroup className='mt-3'>
                                            <Form.Control type="email" className='input-contact custom-placeholder' placeholder="Email" />
                                            <InputGroup.Text className='input-send'>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <InputGroup className='mt-3'>
                                            <Form.Control type="text" className='input-contact custom-placeholder' placeholder="Contraseña" />
                                            <InputGroup.Text className='input-send'>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <InputGroup className='mt-3'>
                                            <Form.Control type="text" className='input-contact custom-placeholder' placeholder="Contraseña" />
                                            <InputGroup.Text className='input-send'>
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                </div>
                                <div className='swipper-footer'>
                                    {/* <Button variant="outline-light" type="submit" className='btn-gb btn-next'>
                                        <img src={arrowDouble} alt="" />
                                    </Button> */}
                                    <Row className='justify-content-center m-0'>
                                        <Col xs={6}>
                                            <p className='txt-gray text-end'>¿Ya tienes cuenta?</p>
                                        </Col>
                                        <Col xs={6}>
                                            <a href="#" className='text-white  text-start'>Iniciar sesión</a>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </SwiperSlide>
                        <SwiperSlide className='custom-slide'>
                            <div className='text-center  m-4'>
                                <img src={step2} alt="" />
                            </div>
                            <p className='subtitle-text text-center pt-4'>Datos personales</p>
                            <Form>
                                <div>
                                    <Form.Group className='mb-3'>
                                        <InputGroup className='mt-3'>
                                            <Form.Control type="text" className='input-contact custom-placeholder' placeholder="Nombre" />
                                            <InputGroup.Text className='input-send'>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <InputGroup className='mt-3'>
                                            <Form.Control type="text" className='input-contact custom-placeholder' placeholder="Apellidos" />
                                            <InputGroup.Text className='input-send'>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <InputGroup className='mt-3'>
                                            <Form.Control type="number" className='input-contact custom-placeholder' placeholder="DNI" />
                                            <InputGroup.Text className='input-send'>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <InputGroup className='mt-3'>
                                            <Row>
                                                <Col xs={3}>
                                                    <Form.Control type="number" className='input-contact custom-placeholder' placeholder="+00" />
                                                </Col>
                                                <Col xs={9}>
                                                    <Form.Control type="number" className='input-contact custom-placeholder' placeholder="Teléfono" />
                                                </Col>
                                            </Row>
                                        </InputGroup>
                                        <InputGroup className='mt-3'>
                                            <Form.Control type="number" className='input-contact custom-placeholder' placeholder="Fecha de nacimiento" />
                                            <InputGroup.Text className='input-send'>
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                </div>
                                <div className='swipper-footer'>
                                    <Row className='justify-content-center m-0'>
                                        <Col xs={6}>
                                            <p className='txt-gray text-end'>¿Ya tienes cuenta?</p>
                                        </Col>
                                        <Col xs={6}>
                                            <a href="#" className='text-white  text-start'>Iniciar sesión</a>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </SwiperSlide>
                        <SwiperSlide className='custom-slide'>
                            <div className='text-start  m-4'>
                                <img src={step3} alt="" />
                            </div>
                            <p className='subtitle-text text-center pt-4'>Datos personales</p>
                            <Form>
                                <div>

                                    <Form.Group className='mb-3'>
                                        <Form.Check type="checkbox" className='mb-3 txt-gray' label="Acepto las políticas de privacidad y deseo recibir comunicaciones comerciales sobre los servicios de Adapta Socios." />
                                        <Form.Check type="checkbox" label="Acepto las políticas de privacidad y deseo recibir comunicaciones comerciales sobre los servicios de Adapta Socios." />
                                    </Form.Group>
                                </div>
                                <div className='swipper-footer'>
                                    <div className='pt-4 btn-mb-88'>
                                        <Button variant='outline-light' className='btn-gb btn-global btn-border-light w-100'>Registrarme</Button>
                                    </div>
                                    <Row className='justify-content-center m-0'>
                                        <Col xs={6}>
                                            <p className='txt-gray text-end'>¿Ya tienes cuenta?</p>
                                        </Col>
                                        <Col xs={6}>
                                            <a href="#" className='text-white  text-start'>Iniciar sesión</a>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </SwiperSlide>
                    </Swiper>
                </div >
            </div>
        </>
    );
}

export default Login;