import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TopMenu.css';
import { Navbar, Nav, Row, Col, Button } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import { AiOutlineUser } from "react-icons/ai";
import { FaTelegramPlane, FaInstagram, FaTwitter, FaFacebook, FaTiktok, FaReddit } from "react-icons/fa";
import { SiTrustpilot } from 'react-icons/si';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Chart from '../../components/chart/Chart';

// + IMAGES +
import logo from '../../assets/imgs/logo_header.png';
// - IMAGES -

const TopMenu = () => {

    const location = useLocation().pathname;

    return (
        <>
            <ToastContainer
                position='top-right'
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick={true}
                pauseOnHover={true}
                draggable={true}
                theme='dark'
                type='error'
                icon={false}

            />
            <Navbar collapseOnSelect expand='lg' variant="dark" className='navbar d-none d-md-flex' fixed="top">
                {!(location.match(/^\/projecto.*$/gim)) && !(location.match(/^\/login.*$/gim)) && <Chart />}
                <Nav.Link eventKey="8">
                    <NavLink className='nav-link' to='/'>
                        <AiOutlineUser className='icon d-md-none' />
                    </NavLink>
                </Nav.Link>
                <Nav.Link eventKey="0">
                    <NavLink className='nav-link' to='/'>
                        <img src={logo} className='logo' alt="" />
                    </NavLink>
                </Nav.Link>
                <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        <Nav.Link eventKey="0" exact>
                            <Link className="nav-link text-mb" to='/'>Inicio</Link>
                        </Nav.Link>
                        <Nav.Link exact eventKey="1">
                            <Link className='nav-link text-mb' to='/home/#properties' smooth>Propiedades</Link>
                        </Nav.Link>
                        <Nav.Link exact eventKey="2">
                            <Link className='nav-link text-mb' to='/equipo-casa-del-token'>Socios</Link>
                        </Nav.Link>
                        <Nav.Link exact eventKey="3">
                            <Link className='nav-link text-mb' to='/como-invertir-en-inmuebles-tokenizados'>¿Cómo funciona?</Link>
                        </Nav.Link>
                        <Nav.Link exact eventKey="4">
                            <Link className='nav-link text-mb' to='/faqs-inversion-inmuebles-tokenizados'>FAQS</Link>
                        </Nav.Link>
                        <Nav.Link exact eventKey="5">
                            <Link className='nav-link text-mb' to='/contacto'>Contacto</Link>
                        </Nav.Link>
                        <Nav.Link exact eventKey="6">
                            <Link className='nav-link text-mb' to='/blog-tokenizar-inmueble'>Blog</Link>
                        </Nav.Link>
                        {/* <Nav.Link eventKey="7" className="d-none d-md-block">
                        <NavLink className='nav-link' to='/login'><AiOutlineUser className='icon' /> Login</NavLink>
                    </Nav.Link> */}
                    </Nav>

                    <Row className='mt-4 mb-4 justify-content-center d-md-none'>
                        <Col xs="1">
                            <Button variant="clear" href='https://t.me/lacasadeltoken' target='_blank'>
                                <FaTelegramPlane className='text-light' />
                            </Button>
                        </Col>

                        <Col xs="1">
                            <Button variant="clear" href='https://www.instagram.com/lacasadeltoken/' target='_blank'>
                                <FaInstagram className='text-light' />
                            </Button>
                        </Col>

                        <Col xs="1">
                            <Button variant="clear" href='https://www.facebook.com/lacasadeltoken' target='_blank'>
                                <FaFacebook className='social-hover' />
                            </Button>
                        </Col>

                        <Col xs="1">
                            <Button variant="clear" href='https://www.tiktok.com/@lacasadeltoken?lang=es' target='_blank'>
                                <FaTiktok className='social-hover' />
                            </Button>
                        </Col>

                        <Col xs="1">
                            <Button variant="clear" href='https://www.reddit.com/user/LaCasaDelToken' target='_blank'>
                                <FaReddit className='social-hover' />
                            </Button>
                        </Col>

                        <Col xs="1">
                            <Button variant="clear">
                                <FaTwitter className='text-light' />
                            </Button>
                        </Col>

                        <Col xs="1">
                            <Button variant="clear" href='https://es.trustpilot.com/review/lacasadeltoken.com' target='_blank'>
                                <SiTrustpilot className='social-hover' />
                            </Button>
                        </Col>
                    </Row>
                </Navbar.Collapse>
            </Navbar>

            {/* MOBILE */}

            <Navbar collapseOnSelect expand='lg' variant="dark" className='navbar d-md-none'>
                <Nav.Link eventKey="8">
                    <NavLink className='nav-link' to='/'>
                        <AiOutlineUser className='icon d-md-none' />
                    </NavLink>
                </Nav.Link>
                <Nav.Link eventKey="0">
                    <NavLink className='nav-link' to='/home'>
                        <img src={logo} className='logo' alt="" />
                    </NavLink>
                </Nav.Link>
                <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        <Nav.Link eventKey="1">
                            <Link className='nav-link text-mb' to='/home/#properties' smooth>Propiedades</Link>
                        </Nav.Link>
                        <Nav.Link eventKey="2">
                            <Link className='nav-link text-mb' to='/equipo-casa-del-token'>Socios</Link>
                        </Nav.Link>
                        <Nav.Link eventKey="3">
                            <Link className='nav-link text-mb' to='/como-invertir-en-inmuebles-tokenizados'>¿Cómo funciona?</Link>
                        </Nav.Link>
                        <Nav.Link eventKey="4">
                            <Link className='nav-link text-mb' to='/faqs-inversion-inmuebles-tokenizados'>FAQS</Link>
                        </Nav.Link>
                        <Nav.Link eventKey="5">
                            <Link className='nav-link text-mb' to='/contacto'>Contacto</Link>
                        </Nav.Link>
                        <Nav.Link eventKey="6">
                            <Link className='nav-link text-mb' to='/blog-tokenizar-inmueble'>Blog</Link>
                        </Nav.Link>
                        {/* <Nav.Link eventKey="7" className="d-none d-md-block">
                        <NavLink className='nav-link' to='/login'><AiOutlineUser className='icon' /> Login</NavLink>
                    </Nav.Link> */}
                    </Nav>

                    <Row className='mt-4 mb-4 justify-content-center d-md-none'>
                        <Col xs="1">
                            <Button variant="clear" href='https://t.me/lacasadeltoken' target='_blank'>
                                <FaTelegramPlane className='text-light' />
                            </Button>
                        </Col>

                        <Col xs="1">
                            <Button variant="clear" href='https://www.instagram.com/lacasadeltoken/' target='_blank'>
                                <FaInstagram className='text-light' />
                            </Button>
                        </Col>

                        <Col xs="1">
                            <Button variant="clear" href='https://www.facebook.com/lacasadeltoken' target='_blank'>
                                <FaFacebook className='social-hover' />
                            </Button>
                        </Col>

                        <Col xs="1">
                            <Button variant="clear" href='https://www.tiktok.com/@lacasadeltoken?lang=es' target='_blank'>
                                <FaTiktok className='social-hover' />
                            </Button>
                        </Col>

                        <Col xs="1">
                            <Button variant="clear" href='https://www.reddit.com/user/LaCasaDelToken' target='_blank'>
                                <FaReddit className='social-hover' />
                            </Button>
                        </Col>

                        <Col xs="1">
                            <Button variant="clear">
                                <FaTwitter className='text-light' />
                            </Button>
                        </Col>

                        <Col xs="1">
                            <Button variant="clear" href='https://es.trustpilot.com/review/lacasadeltoken.com' target='_blank'>
                                <SiTrustpilot className='social-hover' />
                            </Button>
                        </Col>
                    </Row>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}

export default TopMenu;