import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import './Legal.css';

// + IMAGES +
// - IMAGES -

const Legal = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container className='privacy-text pb-5 pt-5'>
            <h1 className='mt-5'>AVISO LEGAL</h1>

            <p>Para dar cumplimiento a la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
                Información y de Comercio Electrónico y, en particular, a su artículo 10, se pone a
                disposición del usuario el siguiente aviso legal.</p>

            <p>El acceso, navegación y utilización del sitio web [...] (en adelante, el “Sitio Web”) implican
                la aceptación tácita y sin reservas de todas las estipulacones del presente aviso legal,
                teniendo la misma validez y eficacia que cualquier contrato celebrado por escrito y firmado.</p>

            <p>Su observancia y cumplimiento serán exigibles respecto de cualquier persona que acceda,
                navegue o utilice el Sitio Web. Si usted no está de acuerdo con las condiciones expuestas,
                no acceda, navegue o utilice los mismos.</p>

            <h1 className='mt-5'>1.- IDENTIFICACIÓN</h1>

            <ul>
                <li><b>Titular: </b>THE TOKEN HOUSE, S.L., (en adelante, <b>“LA CASA DEL TOKEN”</b>)</li>
                <li><b>Domicilio social: </b>Paseo De La Castellana, 180 Bis, 1º Derecha, 28046 – Madrid.</li>
                <li><b>NIF/CIF: </b>B-09608258</li>
                <li><b>Registro Público: </b>Registro Mercantil de Madrid, Tomo 43.191, Folio 170, Sección 8,
                    Hoja M-763229, Inscripción 1ª</li>
                <li><b>E-mail: </b>lacasadeltoken@gmail.com</li>
            </ul>

            <h1 className='mt-5'>2.- OBJETO</h1>

            <p>El presente aviso legal regula el acceso, navegación y utilización del Sitio Web, por medio
                del cual informamos a los usuarios acerca de sus derechos y obligaciones en relación con
                los contenidos mostrados en los mismos, los logotipos y marcas utilizadas, así como de las
                responsabilidades que pueden derivarse de su uso.</p>

            <p>El acceso al Sitio Web es gratuito salvo en lo relativo al coste de la conexión a través de la
                red de telecomunicaciones suministrada por el proveedor de acceso contratado por los
                usuarios. La finalidad del Sitio Web es, principalmente, la de presentar a la sociedad
                TOKEN HOUSE, S.L.</p>

            <p>A este respecto, los servicios que presta LA CASA DEL TOKEN son servicios relacionados
                con las tecnologías de la información y la informática; y, servicios de intermediación en
                operaciones comerciales y asesoramiento en operaciones de compraventa de todo tipo de
                bienes inmuebles y muebles, tangibles o intangibles.</p>

            <p>Sin perjuicio de lo anterior, LA CASA DEL TOKEN se reserva el derecho a modificar la
                presentación, configuración y contenido del Sitio Web, así como el presente aviso legal por
                lo que se recomienda a los usuarios revisarlo de forma constante. El acceso y/o utilización
                del Sitio Web tras la entrada en vigor de sus modificaciones o cambios suponen la
                aceptación de los mismos.</p>

            <h1 className='mt-5'>3.- DERECHOS DE PROPIEDAD INTELECTUAL E
                INDUSTRIAL</h1>

            <p>LA CASA DEL TOKEN es titular o, en su caso, cuenta con las licencias correspondientes
                sobre los derechos de propiedad intelectual, industrial, de imagen o cualesquiera otros
                análogos sobre el Sitio Web, así como de todos los contenidos ofrecidos en el mismo,
                incluyendo, a título enunciativo, pero no limitativo, fotografías, ilustraciones, logos, marcas,
                grafismos, diseños, interfaces, o cualquier otra información o contenido incluidos en el
                mismo.</p>

            <p>Los usuarios únicamente disponen de un derecho de uso no exclusivo, dentro de un ámbito
                estrictamente personal y con la única finalidad de poder navegar por el Sitio Web de
                acuerdo con el presente aviso legal.</p>

            <h1 className='mt-5'>4.- REGLAS DE USO DEL SITIO WEB</h1>

            <p>1. No está permitido y, por tanto, sus consecuencias serán responsabilidad exclusiva
                de los usuarios, el acceso o la utilización del Sitio Web con fines ilegales o no
                autorizados, con o sin finalidad económica. En concreto, y sin carácter limitativo a la
                presente lista, queda prohibido:</p>

            <ul>
                <li>usar el Sitio Web en forma alguna que pueda provocar daños, interrupciones,
                    ineficiencias o defectos en el funcionamiento de estos o en dispositivos de
                    terceros;</li>
                <li>usar el Sitio Web para la transmisión, instalación o publicación de cualquier
                    virus, troyano, gusano, código malicioso u otros programas o archivos
                    perjudiciales o con intenciones ilícitas;</li>
                <li>usar el Sitio Web para transmitir material con fines publicitarios o de
                    promoción, incluidos spam, correos electrónicos en cadena o similares, sin la
                    previa autorización expresa y por escrito de LA CASA DEL TOKEN;</li>
                <li>usar el Sitio Web de manera que constituya una vulneración de cualesquiera
                    derechos de LA CASA DEL TOKEN o de terceros;</li>
                <li>usar el Sitio Web para transmitir o publicar cualquier material de carácter
                    difamatorio, ofensivo, racista, vulgar, denigrante, pornográfico, o de
                    naturaleza obscena o amenazadora o que pueda molestar, perjudicar o
                    meramente afectar a cualquier persona bajo el criterio de LA CASA DEL
                    TOKEN;</li>
                <li>usar el Sitio Web para recoger datos de carácter personal de otros usuarios;</li>
                <li>usar el Sitio Web de forma ilegal, en contra de la buena fe, la moral y/o el
                    orden público;</li>
                <li>acceder sin autorización a cualquier sección del Sitio Web, a otros sistemas o
                    redes conectados a los mismos, a cualquier servidor de LA CASA DEL
                    TOKEN o a los servicios ofrecidos a través del Sitio Web por medio de
                    pirateo o falsificación, extracción de contraseñas o cualquier otro medio
                    ilegítimo o establecido en el presente aviso legal;</li>
                <li>llevar a cabo alguna acción que provoque una saturación desproporcionada o
                    innecesaria en la infraestructura del Sitio Web o en los sistemas o redes

                    utilizados o titularidad de LA CASA DEL TOKEN así como en los sistemas y
                    redes conectados al Sitio Web.</li>
            </ul>

            <p>2. El incumplimiento de cualquiera de las anteriores obligaciones por parte de los
                usuarios del Sitio Web podrá llevar a la adopción por parte de LA CASA DEL
                TOKEN de las medidas oportunas amparadas en Derecho y en el ejercicio de sus
                legítimos intereses.</p>

            <p>3. Recogida de información del usuario, sin perjuicio de lo dispuesto en la cláusula 8
                del presente Aviso Legal, así como en las políticas de privacidad accesibles desde el
                Sitio Web y que pudieran resultar aplicables en cada momento, la utilización de
                ciertos servicios o solicitudes dirigidas a LA CASA DEL TOKEN están condicionadas
                a la previa cumplimentación de los formularios del Sitio Web.</p>

            <p>Toda la información que facilite el usuario a través de los formularios del Sitio Web a los
                efectos anteriores o cualesquiera otros deberá ser veraz. A estos efectos, el usuario
                garantiza la autenticidad de todos aquellos datos que comunique y mantendrá la
                información facilitada a LA CASA DEL TOKEN perfectamente actualizada de forma que
                responda, en todo momento, a la situación real del usuario. En todo caso será el usuario el
                único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios
                que cause a LA CASA DEL TOKEN o a terceros por la información que facilite.</p>

            <h1 className='mt-5'>5.- RESPONSABILIDADES Y GARANTÍAS</h1>

            <p>LA CASA DEL TOKEN no puede garantizar la fiabilidad, utilidad o veracidad de
                absolutamente toda la información contenida en el Sitio Web, ni tampoco la utilidad o
                veracidad de los contenidos puestos a disposición de los usuarios a través de los mismos.</p>

            <p>En consecuencia, LA CASA DEL TOKEN no garantiza ni se hace responsable de:</p>

            <ul>
                <li>la continuidad de los contenidos del Sitio Web;</li>
                <li>la ausencia de errores en dichos contenidos;</li>
                <li>la ausencia de virus, troyanos, gusanos y/o demás componentes maliciosos o
                    tecnológicamente dañinos en el Sitio Web o en el servidor que los suministra;</li>
                <li>la invulnerabilidad del Sitio Web y/o la imposibilidad de vulnerar las medidas de
                    seguridad que se adopten en los mismos;</li>
                <li>la falta de utilidad o rendimiento de los contenidos del Sitio Web;</li>
                <li>los fallos del Sitio Web ocasionados por cualquier tipo de ataque a sus servidores o
                    a los de terceros proveedores de servicios de LA CASA DEL TOKEN, así como los
                    fallos técnicos o de seguridad del sistema de cualquier de dichos proveedores que
                    impidan el correcto funcionamiento del Sitio Web;</li>
                <li>cualquier fallo técnico, de cualquier tipo, que dificulte, retrase o impida el correcto
                    funcionamiento del Sitio Web;</li>
                <li>los daños o perjuicios que cause, a sí mismo o a un tercero, cualquier persona que
                    infringiera las condiciones, normas e instrucciones que LA CASA DEL TOKEN
                    establece en el Sitio Web o a través de la vulneración de los sistemas de seguridad.</li>
            </ul>

            <p>LA CASA DEL TOKEN declara que ha adoptado todas las medidas necesarias, dentro de
                sus posibilidades y del estado de la técnica, para garantizar el funcionamiento del Sitio Web
                y reducir al mínimo los errores del sistema, tanto desde el punto de vista técnico como de
                los contenidos publicados en el Sitio Web.</p>

            <p>LA CASA DEL TOKEN no será responsable de la veracidad, integridad o actualización de
                las informaciones publicadas en el Sitio Web provenientes de fuentes ajenas a la misma, así
                como tampoco de las contenidas en otras plataformas a las que se enlace desde el Sitio
                Web.</p>

            <p>LA CASA DEL TOKEN se reserva el derecho a suspender, modificar, restringir o
                interrumpir, ya sea temporal o permanentemente, el acceso, navegación, uso, alojamiento
                y/o descarga del contenido y/o uso de servicios del Sitio Web, con o sin previa notificación,
                a los usuarios que contravengan cualquier de las disposiciones detalladas en el presente
                aviso legal, sin que medie la posibilidad de exigir indemnización por esta causa.</p>

            <h1 className='mt-5'>6.- FUERZA MAYOR</h1>

            <p>Sin perjuicio de lo anterior, LA CASA DEL TOKEN no será responsable de los retrasos o
                fallos que se produjeran en el acceso, funcionamiento y operatividad del Sitio Web, sus
                Contenidos y/o Servicios, así como tampoco de las interrupciones, suspensiones o el mal
                funcionamiento del mismo, cuando tuvieran su origen en averías producidas por cualquier
                situación de fuerza mayor o causa fortuita.</p>

            <p>No seremos responsables por ningún incumplimiento o retraso en la realización de cualquier
                actuación, cuando el mismo se deba a acontecimientos que están fuera de nuestro control
                razonable (“Causa de Fuerza Mayor”).</p>

            <p>Las Causas de Fuerza Mayor incluirán cualquier acto, acontecimiento, falta de ejercicio,
                omisión o accidente que esté fuera de nuestro control razonable y entre otros, los
                siguientes:</p>

            <ul>
                <li>Huelgas, cierres patronales u otras medidas reivindicativas.</li>
                <li>Conmoción civil, revuelta, invasión, amenaza o ataque terrorista, guerra (declarada o
                    no) o amenaza o preparativos de guerra.</li>
                <li>Incendio, explosión, tormenta, inundación, terremoto, hundimiento, epidemia o
                    cualquier otro desastre natural.</li>
                <li>Imposibilidad de uso de trenes, barcos, aviones, transportes de motor u otros medios
                    de transporte, públicos o privados.</li>
                <li>Imposibilidad de utilizar sistemas públicos o privados de telecomunicaciones.</li>
                <li>Actos, decretos, legislación, normativa o restricciones de cualquier gobierno o
                    autoridad pública.</li>
            </ul>

            <p>Se entenderá que las mencionadas actuaciones quedarán suspendidas durante el período
                en que la Causa de Fuerza Mayor continúe, y dispondremos de una ampliación en el plazo
                para cumplir con las mismas por un periodo de tiempo igual al que dure la Causa de Fuerza

                Mayor. Pondremos todos los medios razonables para que finalice la Causa de Fuerza
                Mayor o para encontrar una solución que nos permita cumplir nuestras obligaciones a pesar
                de la Causa de Fuerza Mayor.</p>

            <h1 className='mt-5'>7.- ATENCIÓN AL USUARIO</h1>

            <p>Los usuarios tienen a su disposición el servicio de atención al usuario por el que LA CASA
                DEL TOKEN atenderá las consultas, quejas y sugerencias planteadas por ellos.</p>

            <p>Los usuarios podrán acceder al servicio de atención al cliente a través del siguiente e-mail
                de contacto:</p>

            <p>E-mail: lacasadeltoken@gmail.com</p>

            <p>LA CASA DEL TOKEN dará respuesta a las reclamaciones o consultas en el plazo más
                breve posible y, en cualquier caso, en un plazo máximo de un (1) mes.</p>

            <h1 className='mt-5'>8.- CONFIDENCIALIDAD Y PROTECCIÓN DE DATOS.</h1>

            <p>De conformidad con lo dispuesto en el REGLAMENTO (UE) 2016/679 DEL PARLAMENTO
                EUROPEO Y DEL CONSEJO de 27 de abril de 2016 relativo a la protección de las
                personasfísicas en lo que respecta al tratamiento de datos personales y a la libre circulación
                de estos datos y por el que se deroga la Directiva 95/46/CE (en adelante, “RGPD”) y la Ley
                Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los
                derechos digitales (en adelante, “LOPDGDD”) todos los datos de carácter personal
                facilitados durante la utilización del Sitio Web serán tratados de conformidad con lo
                dispuesto en la Política de Privacidad que los usuarios deben leer y, en su caso, aceptar y/o
                consentir.</p>

            <h1 className='mt-5'>9.- CLÁUSULA DE SALVAGUARDA</h1>

            <p>Todas las cláusulas o extremos del presente aviso legal deben ser interpretadas de forma
                independiente, no viéndose afectadas el resto de las cláusulas en caso de que una de ellas
                haya sido declarada nula por sentencia judicial o resolución arbitral firme. Se sustituirá la
                cláusula o cláusulas afectadas por otra u otras que preserven los efectos perseguidos por
                este aviso legal.</p>

            <h1 className='mt-5'>10.- LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h1>

            <p>El acceso y utilización del Sitio Web se regirá e interpretará de conformidad con la
                legislación española.</p>

            <p>En el supuesto de que surja cualquier conflicto o discrepancia en la interpretación y/o
                aplicación del aviso legal, los Juzgados competentes serán los que disponga la normativa
                legal aplicable, y en caso de permitirse, todo litigio será sometido a los Juzgados y
                Tribunales de la ciudad de Madrid.</p>

            <p>Resolución de litigios en línea en materia de consumo conforme al Art. 14.1 del Reglamento
                (UE) 524/2013: La Comisión Europea facilita una plataforma de resolución de litigios en
                línea que se encuentra disponible en el siguiente enlace:</p>

            <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>

            <p>Última actualización: 20 de abril de 2022</p>

            <p><b>Copyright © The TOKEN HOUSE, S.L. Todos los derechos reservados.</b></p>
        </Container>
    );
}

export default Legal;