import { useState } from "react";


const useGlobal = () => {

	const [global, setGlobal] = useState({});

	const addGlobals = (values) => {
		let newGlobal = {...global, ...values};
		setGlobal(newGlobal);
	};

	const addGlobal = (key, value) => {
		let newGlobal = {...global};
		newGlobal[key] = value;
		setGlobal(newGlobal);
	};

  	const removeGlobal = (key) => {
    	// delete global[key];
		let newGlobal = {...global};
		delete newGlobal[key]
		setGlobal(newGlobal);
	};

	return {
		global,
		addGlobal,
    	removeGlobal,
		addGlobals
	}

};

export default useGlobal;
