import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FiChevronDown, FiLogOut } from "react-icons/fi";
import { BsPeople } from "react-icons/bs";
import { CgOptions } from "react-icons/cg";
import { HiChevronDoubleDown, HiOutlineLockClosed } from "react-icons/hi";
import { BsFileEarmarkArrowDown } from 'react-icons/bs';
import { IoLocationOutline } from "react-icons/io5";
import { Row, Col, Tab, Nav, Button, Form, Card, ProgressBar, ListGroup, InputGroup, FormControl } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import './ProjectsDetail.css';
import SideMenu from '../../components/sidemenu/SideMenu';
import SearchMenu from '../../components/searchmenu/SearchMenu';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { EffectFade, Navigation, Pagination } from "swiper";

// + IMAGES +
import logo from '../../assets/imgs/logo_header.png';
import avatar from '../../assets/imgs/avatar.png';
import detail from '../../assets/imgs/detail.png';
import criptoC from '../../assets/imgs/cripto_aguamarina_gif.gif';
import discC from '../../assets/imgs/disc_cyan.svg';
import blogCover from '../../assets/imgs/blog_cover.png';
// - IMAGES -

function valuetext(value) {
    return `${value}°C`;
}

const ProjectsDetail = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const [value, setValue] = React.useState([20, 37]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const truncate = function (str) {
        return str?.length > 7 ? str.substring(0, 7) + "..." : str;
    }

    const filter = (
        <Popover id="filter" className='popover-filter'>
            <Popover.Body>
                <Box sx={{ width: 310 }}>
                    <Form.Label className='text-light'><b>Precio por token</b></Form.Label>
                    <Slider
                        getAriaLabel={() => 'Temperature range'}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                    />
                </Box>
                <Row className='align-items-center'>
                    <Col xs={6} md={6}>
                        <span className='text-light'>XXX €</span>
                    </Col>
                    <Col xs={6} md={6} className="text-end">
                        <span className='text-light'>XXX €</span>
                    </Col>
                </Row>

                <Form.Label className='text-light mt-3'><b>Tipo de activo</b></Form.Label>
                <Row>
                    <Col xs={6} md={6}>
                        <Form.Check type="checkbox" label="Casa" />
                        <Form.Check type="checkbox" label="Edificio de viviendas" />
                        <Form.Check type="checkbox" label="Adosado" />
                    </Col>
                    <Col xs={6} md={6}>
                        <Form.Check type="checkbox" label="Apartamento" />
                        <Form.Check type="checkbox" label="Hostal" />
                        <Form.Check type="checkbox" label="Apartamento con servicios" />
                    </Col>
                </Row>

                <Form.Label className='text-light mt-3'><b>Status</b></Form.Label>
                <Row>
                    <Col xs={6} md={6}>
                        <Form.Check type="checkbox" label="En diseño" />
                        <Form.Check type="checkbox" label="Finalizadas" />
                    </Col>
                    <Col xs={6} md={6}>
                        <Form.Check type="checkbox" label="En suscripción" />
                    </Col>
                </Row>

                <Form.Label className='text-light mt-3'><b>Tipo de proyecto</b></Form.Label>
                <Row>
                    <Col xs={6} md={6}>
                        <Form.Check type="checkbox" label="One shot" />
                    </Col>
                    <Col xs={6} md={6}>
                        <Form.Check type="checkbox" label="Recurrente" />
                    </Col>
                </Row>

                <Box sx={{ width: 310 }}>
                    <Form.Label className='text-light mt-3'><b>Rentabilidad</b></Form.Label>
                    <Slider
                        getAriaLabel={() => 'Temperature range'}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                    />
                </Box>
                <Row className='align-items-center'>
                    <Col xs={6} md={6}>
                        <span className='text-light'>6%</span>
                    </Col>
                    <Col xs={6} md={6} className="text-end">
                        <span className='text-light'>22%</span>
                    </Col>
                </Row>

                <Row className='mt-3 justify-content-center'>
                    <Col xs={12} md={2}></Col>
                    <Col xs={12} md={8}>
                        <Button variant='outline-light' className='w-100 btn-gb text-uppercase btn-global btn-detail'>APLICAR</Button>
                    </Col>
                    <Col xs={12} md={2}></Col>
                </Row>
            </Popover.Body>
        </Popover>
    );

    const card = (
        <Popover id="card" className='popover-card'>
            <Popover.Body>
                <Card className='detail-card'>
                    <Card.Body>
                        <Row>
                            <Col xs={6} md={6}>
                                <Card.Title className='text-white'>Inmueble</Card.Title>
                            </Col>
                            <Col xs={6} md={6} className='text-end'>
                                <div className='chip-detail'>
                                    <span className="dot dot-design"></span>
                                    <p className='text-white text-uppercase m-0 font-chip'>en diseño</p>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                            </Col>
                        </Row>

                        <p className='text-light detail-text-card'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officiis, sit omnis. Maxime quo aut unde iste,
                            illum omnis fugit harum repellat architecto quod facere ipsam quasi consectetur quisquam deserunt molestiae.</p>

                        <p className='text-light detail-text-card'>Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.</p>

                        <ProgressBar now={50} className='custom-progress mt-4' />

                        <Row className='align-items-center mt-1'>
                            <Col xs={6} md={6}>
                                <p className='text-light'>50%</p>
                            </Col>
                            <Col xs={6} md={6} className='text-end'>
                                <p className='text-light'>2.000.000 €</p>
                            </Col>
                        </Row>

                        <ListGroup variant="flush" className='list mt-3'>
                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                Ejecución
                                <h6 className='m-0'><b>24 meses</b></h6>
                            </ListGroup.Item>
                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                Nº inversores
                                <HiOutlineLockClosed />
                            </ListGroup.Item>
                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                Modalidad
                                <HiOutlineLockClosed />
                            </ListGroup.Item>
                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                LVT
                                <HiOutlineLockClosed />
                            </ListGroup.Item>
                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                Rentabilidad anual
                                <h6 className='m-0'><b>8%</b></h6>
                            </ListGroup.Item>
                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                Rentabilidad total
                                <HiOutlineLockClosed />
                            </ListGroup.Item>
                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                Inversión minima
                                <h6 className='m-0'><b>100 €</b></h6>
                            </ListGroup.Item>
                        </ListGroup>

                        <Row className='align-items-center mt-3'>
                            <Col xs={12}>
                                <h4 className='text-light'>QUIERO INVERTIR:</h4>
                            </Col>
                        </Row>

                        <Row className='justify-content-end align-items-center'>
                            <Col xs={6} md={6} className='text-end'>
                                <InputGroup>
                                    <FormControl
                                        placeholder="0,00"
                                        className='input-investment' />
                                    <InputGroup.Text className='text-investment'>
                                        <h3 className='text-light'>€</h3>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col xs={6} md={6}>
                                <div className='d-flex justify-content-end'>
                                    <Button variant='outline-light' className='btn-gb text-uppercase btn-global btn-detail btn-investment'>INVERTIR</Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Popover.Body>
        </Popover>
    );

    const user = (
        <Popover id="user" className='popover-user'>
            <Popover.Body>
                <Row>
                    <Col>
                        <Button variant='clear' className='text-light btn-filter'>
                            <FiLogOut />
                            <span>Cerrar sesión</span>
                        </Button>
                    </Col>
                </Row>

                <hr className='line-user' />

                <Row>
                    <Col>
                        <Button variant='clear' className='text-light btn-filter'>
                            <BsPeople />
                            <span>Mi perfil</span>
                        </Button>
                    </Col>
                </Row>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <SideMenu />
            <div className='position-relative overflow-hidden container-fluid pb-5'>
                <Tab.Container defaultActiveKey="resumen">
                    <Row className='w-100 ps-4 align-items-center d-none d-md-flex'>
                        <Col xs={12} md={3}></Col>
                        <Col xs={12} md={9}>
                            <div className='searchbar-container'>
                                <Row className='align-items-center'>
                                    <Col xs={12} md={9}>
                                        <Nav>
                                            <Nav.Item>
                                                <Nav.Link eventKey="resumen" className='detail-tab pointer'>Resumen</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="foto" className='detail-tab pointer'>Fotografia y video</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="inversion" className='detail-tab pointer'>Inversión</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="promotores" className='detail-tab pointer'>Promotores</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="doc" className='detail-tab pointer'>Documentación</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="seguimiento" className='detail-tab pointer'>Seguimiento</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col xs={12} md={3} className="text-end">
                                        <img src={avatar} className='avatar' alt="" />
                                        <span className='avatar-name'>{truncate('Lorem Ipsum')}</span>
                                        <OverlayTrigger trigger="click" placement="bottom" overlay={user}>
                                            <Button variant='clear'>
                                                <FiChevronDown className='text-light pointer' />
                                            </Button>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row className='justify-content-center align-items-center p-3 d-md-none search-mb'>
                        <Col xs={4}>
                            <OverlayTrigger trigger="click" placement="bottom" overlay={filter}>
                                <Button variant='clear' className='text-light btn-filter'>
                                    <CgOptions className='search-icon-mb' />
                                </Button>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={4} className='text-center'>
                            <img src={logo} className='logo' alt="" />
                        </Col>
                        <Col xs={4} className='text-end'>
                            <img src={avatar} className='avatar' alt="" />
                            <OverlayTrigger trigger="click" placement="bottom" overlay={user}>
                                <Button variant='clear'>
                                    <FiChevronDown className='text-light pointer' />
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>

                    <Row className='d-md-none mt-5'>
                        <Col xs={12}>
                            <OverlayTrigger trigger="click" placement="bottom" overlay={card}>
                                <Button variant='clear' className='text-light btn-card-mb w-100'>
                                    <IoLocationOutline />
                                    Calle Mendevil 25, Madrid
                                    <HiChevronDoubleDown className='text-light' />
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>

                    <div className='d-md-none mt-3'>
                        <Row className='align-items-center'>
                            <Col xs={12}>
                                <Nav className='nav-mobile'>
                                    <Nav.Item className='nav-mobile-item'>
                                        <Nav.Link eventKey="resumen" className='detail-tab pointer'>Resumen</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-mobile-item'>
                                        <Nav.Link eventKey="foto" className='detail-tab pointer'>Fotografia y video</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-mobile-item'>
                                        <Nav.Link eventKey="inversion" className='detail-tab pointer'>Inversión</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-mobile-item'>
                                        <Nav.Link eventKey="promotores" className='detail-tab pointer'>Promotores</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-mobile-item'>
                                        <Nav.Link eventKey="doc" className='detail-tab pointer'>Documentación</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-mobile-item'>
                                        <Nav.Link eventKey="seguimiento" className='detail-tab pointer'>Seguimiento</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                    </div>

                    <div className='container-left-dash-a d-none d-md-block'>
                        <div className='ball-c'></div>
                    </div>

                    <div className='container-left-dash-b d-none d-md-block'>
                        <div className='ball-c'></div>
                    </div>

                    <div className='container-center-dash d-none d-md-block'>
                        <div className='ball-c'></div>
                    </div>

                    <div className='container-right-dash-a'>
                        <div className='ball-c'></div>
                    </div>

                    <div className='container-right-dash-b'>
                        <div className='ball-c'></div>
                    </div>

                    <div className='container-right-dash-c'>
                        <div className='ball-c'></div>
                    </div>

                    <div className='container-right-dash-d'>
                        <div className='ball-c'></div>
                    </div>

                    <Row>
                        <Col xs={12} md={3}></Col>
                        <Col xs={12} md={5}>
                            <Tab.Content>
                                <Tab.Pane eventKey="resumen" className='animate__animated animate__fadeInRight animate__faster'>
                                    <Row className='mt-3'>
                                        <Col xs={12} md={12}>
                                            <h5 className='text-light d-none d-md-block'>Calle Mendevil 25, Madrid</h5>
                                            {!detail &&
                                                <img src={blogCover} className='image-blog' alt="" />
                                            }

                                            {detail &&
                                                <img src={detail} className="detail-img mt-3 mb-3" alt="" />
                                            }

                                            <p className='text-light'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis donec mollis eu placerat. Sit mattis sed habitasse nec. Eu vel mauris sed nunc adipiscing erat malesuada bibendum.
                                                Dapibus aliquet dapibus ultrices curabitur elit nullam ut. Neque sit mauris amet dolor sed. Nulla feugiat quam mattis cursus integer maecenas risus sem amet.</p>

                                            <p className='text-light'>Maecenas cursus libero in lectus. Elit risus feugiat maecenas sit sed turpis laoreet. Velit orci gravida sed adipiscing amet, in neque scelerisque. Mauris, ornare volutpat at sed. Augue viverra eget ornare congue nullam sed id tempus. Elit leo turpis amet, sed diam, nibh leo arcu. Ornare viverra risus ultricies pellentesque tellus tellus arcu, arcu. Dui quisque senectus diam placerat lacinia adipiscing bibendum.
                                                Posuere tincidunt ullamcorper faucibus id morbi enim varius turpis phasellus.</p>

                                            <p className='text-light'>Enim pulvinar felis semper sodales pellentesque volutpat egestas sit amet. Malesuada enim, ornare sit enim urna, tellus arcu. Amet hendrerit augue suscipit eu. At venenatis orci suspendisse orci et non. Sit viverra a vel, enim neque nibh leo fames sagittis. Purus duis nec neque congue cursus sed. NOTA: lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis donec mollis eu placerat. Sit mattis sed habitasse nec. Eu vel mauris sed nunc adipiscing erat malesuada bibendum. Dapibus aliquet dapibus ultrices curabitur elit nullam ut.
                                                Neque sit mauris amet dolor sed. Nulla feugiat quam mattis cursus integer maecenas risus sem amet.</p>

                                            <p className='text-light'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis donec mollis eu placerat. Sit mattis sed habitasse nec. Eu vel mauris sed nunc adipiscing erat malesuada bibendum.
                                                Dapibus aliquet dapibus ultrices curabitur elit nullam ut. Neque sit mauris amet dolor sed. Nulla feugiat quam mattis cursus integer maecenas risus sem amet.</p>

                                            <div>
                                                {/* MAPA */}
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="foto" className='animate__animated animate__fadeInRight animate__faster'>
                                    <Row className='mt-5'>
                                        <Col xs={12} md={12}>
                                            <h5 className='text-light d-none d-md-block'>Calle Mendevil 25, Madrid</h5>

                                            <Swiper
                                                spaceBetween={30}
                                                effect={"fade"}
                                                navigation={true}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                                modules={[EffectFade, Navigation, Pagination]}
                                                className="swiper-detail"
                                            >
                                                <SwiperSlide className="swiper-slide-detail">
                                                    <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
                                                </SwiperSlide>
                                                <SwiperSlide className="swiper-slide-detail">
                                                    <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
                                                </SwiperSlide>
                                                <SwiperSlide className="swiper-slide-detail">
                                                    <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
                                                </SwiperSlide>
                                                <SwiperSlide className="swiper-slide-detail">
                                                    <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
                                                </SwiperSlide>
                                            </Swiper>
                                            <div className="video-responsive mt-5">
                                                <iframe
                                                    width="853"
                                                    height="480"
                                                    src={`https://www.youtube.com/embed/rokGy0huYEA`}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    title="Embedded youtube"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="inversion" className='animate__animated animate__fadeInRight animate__faster'>
                                    <Row className='mt-5'>
                                        <Col xs={12} md={12}></Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="promotores" className='animate__animated animate__fadeInRight animate__faster'>
                                    <Row className='mt-5'>
                                        <Col xs={12} md={12}>
                                            <div className='container-gif'>
                                                <div className='text-center'>
                                                    <img src={criptoC} alt="" className='img-fluid' />
                                                    <h2 className='text-white text-uppercase title-slider'>adapta socios</h2>
                                                </div>
                                                <div>
                                                    <img src={discC} alt="" className='img-fluid' />
                                                </div>
                                            </div>
                                            <div className='container-truncate mt-5'>
                                                <div>
                                                    <p className='text-gb text-work'><span className='text-muted'>Somos la primera plataforma de</span> inmuebles tokenizados <span className='text-muted'> que permite la</span> inversión y retirada de los beneficios<span className='text-muted'> tanto en crypto como en FIAT</span> ampliando así las posibilidades de inversión. <br /> <br /><span className='text-muted'>El expertise en áreas como tecnología, legal y Real Estate nos permite ofrecer una </span>experiencia de usuario única y <span className='text-muted'>excelente.</span></p>
                                                </div>
                                            </div>

                                            <Row className='align-items-center justify-content-center mt-5'>
                                                <Col xs={12} md={6} className='text-center'>
                                                    <img src={avatar} className='avatar-detail' alt="" />
                                                    <h5 className='text-light mt-3'>Lorem Ipsum</h5>
                                                    <p className='text-light text-left'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi iusto suscipit, illum aspernatur doloribus obcaecati,
                                                        cumque quod illo quibusdam molestias,
                                                        necessitatibus sunt omnis delectus error aut iure consequuntur possimus nemo!</p>
                                                </Col>
                                                <Col xs={12} md={6} className='text-center'>
                                                    <img src={avatar} className='avatar-detail' alt="" />
                                                    <h5 className='text-light mt-3'>Lorem Ipsum</h5>
                                                    <p className='text-light text-left'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi iusto suscipit, illum aspernatur doloribus obcaecati,
                                                        cumque quod illo quibusdam molestias,
                                                        necessitatibus sunt omnis delectus error aut iure consequuntur possimus nemo!</p>
                                                </Col>
                                            </Row>

                                            <Row className='align-items-center justify-content-center mt-5'>
                                                <Col xs={12} md={6} className='text-center'>
                                                    <img src={avatar} className='avatar-detail' alt="" />
                                                    <h5 className='text-light mt-3'>Lorem Ipsum</h5>
                                                    <p className='text-light text-left'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi iusto suscipit, illum aspernatur doloribus obcaecati,
                                                        cumque quod illo quibusdam molestias,
                                                        necessitatibus sunt omnis delectus error aut iure consequuntur possimus nemo!</p>
                                                </Col>
                                                <Col xs={12} md={6} className='text-center'>
                                                    <img src={avatar} className='avatar-detail' alt="" />
                                                    <h5 className='text-light mt-3'>Lorem Ipsum</h5>
                                                    <p className='text-light text-left'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi iusto suscipit, illum aspernatur doloribus obcaecati,
                                                        cumque quod illo quibusdam molestias,
                                                        necessitatibus sunt omnis delectus error aut iure consequuntur possimus nemo!</p>
                                                </Col>
                                            </Row>

                                            <Row className='align-items-center justify-content-center mt-5'>
                                                <Col xs={12} md={6} className='text-center'>
                                                    <img src={avatar} className='avatar-detail' alt="" />
                                                    <h5 className='text-light mt-3'>Lorem Ipsum</h5>
                                                    <p className='text-light text-left'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi iusto suscipit, illum aspernatur doloribus obcaecati,
                                                        cumque quod illo quibusdam molestias,
                                                        necessitatibus sunt omnis delectus error aut iure consequuntur possimus nemo!</p>
                                                </Col>
                                                <Col xs={12} md={6} className='text-center'>
                                                    <img src={avatar} className='avatar-detail' alt="" />
                                                    <h5 className='text-light mt-3'>Lorem Ipsum</h5>
                                                    <p className='text-light text-left'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi iusto suscipit, illum aspernatur doloribus obcaecati,
                                                        cumque quod illo quibusdam molestias,
                                                        necessitatibus sunt omnis delectus error aut iure consequuntur possimus nemo!</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="doc" className='animate__animated animate__fadeInRight animate__faster'>
                                    <Row className='mt-5'>
                                        <Col xs={12} md={12}>
                                            <h5 className='text-light d-none d-md-block'>Calle Mendevil 25, Madrid</h5>

                                            {!detail &&
                                                <img src={blogCover} className="detail-img mt-3 mb-3" alt="" />
                                            }

                                            {detail &&
                                                <img src={detail} className="detail-img mt-3 mb-3" alt="" />
                                            }

                                            <h5 className='text-light mt-4'>Descarga de documentos</h5>

                                            <ListGroup variant="flush" className='list mt-2'>
                                                <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                    Lorem Ipsum dolor
                                                    <BsFileEarmarkArrowDown className='text-light' />
                                                </ListGroup.Item>
                                                <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                    Lorem Ipsum dolor
                                                    <BsFileEarmarkArrowDown className='text-light' />
                                                </ListGroup.Item>
                                                <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                    Lorem Ipsum dolor
                                                    <BsFileEarmarkArrowDown className='text-light' />
                                                </ListGroup.Item>
                                                <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                    Lorem Ipsum dolor
                                                    <BsFileEarmarkArrowDown className='text-light' />
                                                </ListGroup.Item>
                                                <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                    Lorem Ipsum dolor
                                                    <BsFileEarmarkArrowDown className='text-light' />
                                                </ListGroup.Item>
                                                <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                    Lorem Ipsum dolor
                                                    <BsFileEarmarkArrowDown className='text-light' />
                                                </ListGroup.Item>
                                            </ListGroup>

                                            <h5 className='text-light mt-4'>Descarga de planos</h5>

                                            <ListGroup variant="flush" className='list mt-2'>
                                                <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                    Lorem Ipsum dolor
                                                    <BsFileEarmarkArrowDown className='text-light' />
                                                </ListGroup.Item>
                                                <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                    Lorem Ipsum dolor
                                                    <BsFileEarmarkArrowDown className='text-light' />
                                                </ListGroup.Item>
                                                <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                    Lorem Ipsum dolor
                                                    <BsFileEarmarkArrowDown className='text-light' />
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="seguimiento" className='animate__animated animate__fadeInRight animate__faster'>
                                    <Row className='mt-5'>
                                        <Col xs={12} md={12}>
                                            <h5 className='text-light d-none d-md-block'>Calle Mendevil 25, Madrid</h5>

                                            <p className='text-light'>17.05.2023</p>

                                            <Swiper
                                                slidesPerView={3}
                                                spaceBetween={30}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                                className="swiper-images"
                                            >
                                                <SwiperSlide className='swiper-slide-images'>
                                                    <img src={detail} alt="" />
                                                </SwiperSlide>
                                                <SwiperSlide className='swiper-slide-images'>
                                                    <img src={detail} alt="" />
                                                </SwiperSlide>
                                                <SwiperSlide className='swiper-slide-images'>
                                                    <img src={detail} alt="" />
                                                </SwiperSlide>
                                                <SwiperSlide className='swiper-slide-images'>
                                                    <img src={detail} alt="" />
                                                </SwiperSlide>
                                            </Swiper>

                                            <p className='text-light mt-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error saepe est dolor quam ducimus deserunt aperiam aliquam alias iusto laboriosam,
                                                consequatur odit nulla dolore blanditiis perspiciatis. Alias culpa nemo aspernatur.</p>

                                            <hr className='separator-detail' />

                                            <p className='text-light'>17.05.2023</p>

                                            <p className='text-light mt-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error saepe est dolor quam ducimus deserunt aperiam aliquam alias iusto laboriosam,
                                                consequatur odit nulla dolore blanditiis perspiciatis. Alias culpa nemo aspernatur.</p>

                                            <hr className='separator-detail' />

                                            <p className='text-light'>17.05.2023</p>

                                            <Swiper
                                                slidesPerView={3}
                                                spaceBetween={30}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                                className="swiper-images"
                                            >
                                                <SwiperSlide className='swiper-slide-images'>
                                                    <img src={detail} alt="" />
                                                </SwiperSlide>
                                                <SwiperSlide className='swiper-slide-images'>
                                                    <img src={detail} alt="" />
                                                </SwiperSlide>
                                            </Swiper>

                                            <p className='text-light mt-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error saepe est dolor quam ducimus deserunt aperiam aliquam alias iusto laboriosam,
                                                consequatur odit nulla dolore blanditiis perspiciatis. Alias culpa nemo aspernatur.</p>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                        <Col xs={12} md={4} className='d-none d-md-block'>
                            <Card className='detail-card'>
                                <Card.Body>
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <Card.Title className='text-white'>Inmueble</Card.Title>
                                            <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                                        </Col>
                                        <Col xs={6} md={6} className='text-end'>
                                            <div className='chip-detail'>
                                                <span className="dot dot-design"></span>
                                                <p className='text-white text-uppercase m-0 font-chip'>en diseño</p>
                                            </div>
                                        </Col>
                                    </Row>

                                    <p className='text-light detail-text-card'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officiis, sit omnis. Maxime quo aut unde iste,
                                        illum omnis fugit harum repellat architecto quod facere ipsam quasi consectetur quisquam deserunt molestiae.</p>

                                    <p className='text-light detail-text-card'>Enim dolor ultricies turpis id quis diam pharetra sagittis. Ullamcorper.</p>

                                    <ProgressBar now={50} className='custom-progress mt-4' />

                                    <Row className='align-items-center mt-1'>
                                        <Col xs={6} md={6}>
                                            <p className='text-light'>50%</p>
                                        </Col>
                                        <Col xs={6} md={6} className='text-end'>
                                            <p className='text-light'>2.000.000 €</p>
                                        </Col>
                                    </Row>

                                    <ListGroup variant="flush" className='list mt-5'>
                                        <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                            Ejecución
                                            <h5 className='m-0'><b>24 meses</b></h5>
                                        </ListGroup.Item>
                                        <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                            Nº inversores
                                            <h5 className='m-0'><b>103</b></h5>
                                        </ListGroup.Item>
                                        <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                            Modalidad
                                            <h5 className='m-0'><b>24 meses</b></h5>
                                        </ListGroup.Item>
                                        <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                            LVT
                                            <h5 className='m-0'><b>XXX</b></h5>
                                        </ListGroup.Item>
                                        <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                            Rentabilidad anual
                                            <h5 className='m-0'><b>8%</b></h5>
                                        </ListGroup.Item>
                                        <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                            Rentabilidad total
                                            <h5 className='m-0'><b>16%</b></h5>
                                        </ListGroup.Item>
                                        <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                            Inversión minima
                                            <h5 className='m-0'><b>100 €</b></h5>
                                        </ListGroup.Item>
                                    </ListGroup>

                                    <Row className='align-items-center mt-3'>
                                        <Col xs={6} md={8}>
                                            <h4 className='text-light'>QUIERO INVERTIR:</h4>
                                            <Row className='justify-content-end align-items-center'>
                                                <Col xs={12} md={6}></Col>
                                                <Col xs={11} md={6} className='text-end'>
                                                    <InputGroup>
                                                        <FormControl
                                                            placeholder="0,00"
                                                            className='input-investment' />
                                                        <InputGroup.Text className='text-investment'>
                                                            <h3 className='text-light'>€</h3>
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={6} md={4} className='text-end'>
                                            <div className='d-flex justify-content-end'>
                                                <Button variant='outline-light' className='btn-gb text-uppercase btn-global btn-detail btn-investment'>INVERTIR</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    );
}

export default ProjectsDetail;