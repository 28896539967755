import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Card, Pagination, Placeholder } from 'react-bootstrap';
import './Blog.css';
import Fab from '../../components/fab/Fab';
import Chart from '../../components/chart/Chart';
import NewsLetter from '../../components/newsletter/Newsletter';
import { useQuery, gql } from '@apollo/client';
import AppSettings from '../../AppSettings';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import { useState } from 'react';
import { useEffect } from 'react';

// + IMAGES +
import blog1 from '../../assets/imgs/blog1.png';
import blogCover from '../../assets/imgs/blog_cover.png';

// - IMAGES -

const Blog = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const { settings } = AppSettings();


    // (+) Graphql

    const [article, setArticle] = useState();
    const [articles, setArticles] = useState([]);
    const [offset, setOffset] = useState(0);
    const limit = 9;
    const [hasPreviousPage, setHasPreviousPage] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);

    const SEARCH_ARTICLES = gql`${settings.graphql.query.searchArticles}`;

    const { data, loading, error, fetchMore } = useQuery(SEARCH_ARTICLES, { variables: { offset, limit } });

    if (error) toast(error.message);

    useEffect(() => {
        if (data) {
            if (!article && data.searchArticles.edges.length > 0) setArticle(data.searchArticles.edges[0]);
            setArticles(data.searchArticles.edges);
            setHasNextPage(data.searchArticles.pageInfo.hasNextPage);
            setHasPreviousPage(data.searchArticles.pageInfo.hasPreviousPage);
        }
    }, [data]);

    const fetchNext = () => {
        fetch(offset + limit);
    }

    const fetchPrevious = () => {
        fetch(offset - limit);
    }

    const fetch = (newOffset) => {
        fetchMore({ variables: { offset: newOffset } }).then(res => {
            setArticles(res.data.searchArticles.edges);
            setHasNextPage(data.searchArticles.pageInfo.hasNextPage);
            setHasPreviousPage(data.searchArticles.pageInfo.hasPreviousPage);
            setOffset(newOffset);
        }).catch(error => toast(error.message));
    }
    // (-) Graphql


    const navigate = useNavigate();

    const goToBlogDetail = (article) => {
        navigate(`/blog-tokenizar-inmueble-detalle/${article.node.slug}`);
    }

    const onErrorCover = (error) => {
        error.target.src = blogCover;
    }

    const truncate = function (str) {
        return str?.length > 180 ? str.substring(0, 180) + "..." : str;
    }

    const truncateDetail = function (str) {
        return str?.length > 120 ? str.substring(0, 120) + "..." : str;
    }

    return (
        <>
            <Fab />
            <div className='container-blog'>
                <Container className='p-3 pb-5 pt-5 mt-5'>
                    <Row className='d-none d-md-flex container-blog-images mt-5'>
                        <Col md={4} className='col-index'>
                            {!article &&
                                <img src={blogCover} className='image-blog' alt="" />
                            }

                            {article &&
                                <img src={article?.node.coverPhotoFullPath} onError={onErrorCover} className='image-blog' alt="" />
                            }
                        </Col>
                        <Col md={8} className='position-relative'>
                            <div className='col-index position-relative'>
                                <p className='date'>
                                    <Moment format="DD.MM.YYYY">
                                        {article?.node.articleDate}
                                    </Moment>
                                </p>

                                {!article &&
                                    <Placeholder as={Card.Title} animation="glow">
                                        <Placeholder bg="light" lg={10} />
                                    </Placeholder>
                                }

                                {article &&
                                    <h2 className='text-light title-card-blog'>{truncate(article?.node.title)}</h2>
                                }

                                <Row className='align-items-center'>
                                    <Col md={8}>
                                        <div className='w-100 d-flex flex-row flex-wrap mt-3'>
                                            <p className='hashtag'>#Inversiones</p>
                                            <p className='hashtag'>#Crypto</p>
                                            <p className='hashtag'>#NFT</p>
                                            <p className='hashtag'>#Inmobiliaria</p>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <Button variant='outline-light' className='btn-global btn-gb' onClick={() => goToBlogDetail(article)}>
                                            LEER POST
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            <div className='card-blur-blog'>
                            </div>
                        </Col>
                    </Row>

                    <Card className='d-md-none card-blog-first mt-3'>
                        <Card.Img variant="top" className='header-img-card header-img-card-mb' src={blog1} />
                        <Card.Body>
                            <Card.Text className='text-light date'>26.05.2022</Card.Text>
                            <Card.Title className='text-light'>
                                ¿Qué es un NFT y cómo me sirve en el mercado inmobiliario?
                            </Card.Title>
                            <div className='w-100 d-flex flex-row flex-wrap mt-3'>
                                <p className='hashtag'>#Inversiones</p>
                                <p className='hashtag'>#Crypto</p>
                                <p className='hashtag'>#NFT</p>
                                <p className='hashtag'>#Inmobiliaria</p>
                            </div>
                            <Button variant='outline-light' className='btn-global btn-gb' onClick={goToBlogDetail}>
                                LEER POST
                            </Button>
                        </Card.Body>
                    </Card>
                </Container>

                {/* <Container className='p-5'>
                    <Row className='mt-2'>
                        <Col>
                            <h3 className='text-light'>¿Qué te interesa?</h3>
                            <div className='w-100 d-flex flex-row flex-wrap mt-3'>
                                <p className='hashtag click h-active'>#Todos</p>
                                <p className='hashtag click'>#Inversiones</p>
                                <p className='hashtag click'>#Crypto</p>
                                <p className='hashtag click'>#NFT</p>
                                <p className='hashtag click'>#Inmobiliaria</p>
                                <p className='hashtag click'>#Lorem Impsum</p>
                                <p className='hashtag click'>#Lorem Impsum</p>
                                <p className='hashtag click'>#Lorem Impsum</p>
                                <p className='hashtag click'>#Lorem Impsum</p>
                                <p className='hashtag click'>#Lorem Impsum</p>
                                <p className='hashtag click'>#Lorem Impsum</p>
                                <p className='hashtag click'>#Lorem Impsum</p>
                            </div>
                        </Col>
                    </Row>
                </Container> */}
            </div>

            <Container className='p-3 pb-5'>
                <Row className='mt-4'>
                    {!article &&
                        <>
                            <Col xs={12} md={4}>
                                <Card className='card-blog'>
                                    <Card.Img className='header-img-card' variant="top" src={blogCover} />
                                    <Card.Body>
                                        <Placeholder as={Card.Title} animation="glow">
                                            <Placeholder bg="light" lg={6} />
                                        </Placeholder>
                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder bg="light" lg={7} /> <Placeholder bg="light" lg={4} />
                                        </Placeholder>
                                        <Placeholder as={Card.Title} animation="glow">
                                            <Placeholder bg="light" lg={12} />
                                        </Placeholder>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xs={12} md={4}>
                                <Card className='card-blog'>
                                    <Card.Img className='header-img-card' variant="top" src={blogCover} />
                                    <Card.Body>
                                        <Placeholder as={Card.Title} animation="glow">
                                            <Placeholder bg="light" lg={6} />
                                        </Placeholder>
                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder bg="light" lg={7} /> <Placeholder bg="light" lg={4} />
                                        </Placeholder>
                                        <Placeholder as={Card.Title} animation="glow">
                                            <Placeholder bg="light" lg={12} />
                                        </Placeholder>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xs={12} md={4}>
                                <Card className='card-blog'>
                                    <Card.Img className='header-img-card' variant="top" src={blogCover} />
                                    <Card.Body>
                                        <Placeholder as={Card.Title} animation="glow">
                                            <Placeholder bg="light" lg={6} />
                                        </Placeholder>
                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder bg="light" lg={7} /> <Placeholder bg="light" lg={4} />
                                        </Placeholder>
                                        <Placeholder as={Card.Title} animation="glow">
                                            <Placeholder bg="light" lg={12} />
                                        </Placeholder>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </>
                    }

                    {article && articles.map((article) => (
                        <Col xs={12} md={4} className='mt-3'>
                            <Card className='card-blog' onClick={() => goToBlogDetail(article)}>
                                <Card.Img variant="top" className='header-img-card' src={article.node.coverPhotoFullPath} onError={onErrorCover} />
                                <Card.Body>
                                    <Card.Text className='text-light date'>
                                        <Moment format="DD.MM.YYYY">
                                            {article.node.articleDate}
                                        </Moment>
                                    </Card.Text>
                                    <Card.Title className='text-light'>
                                        {truncateDetail(article.node.title)}
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}


                </Row>

                <Row className='justify-content-center mt-5'>
                    <Col xs={12} md={4}></Col>
                    <Col xs={12} md={4} className='text-center'>
                        <Pagination className='paginations'>

                            {hasPreviousPage &&
                                <Pagination.First onClick={() => fetchPrevious()} />
                            }

                            {/* <Pagination.Ellipsis />

                            <Pagination.Item active>{3}</Pagination.Item>
                            <Pagination.Item>{4}</Pagination.Item>
                            <Pagination.Item>{5}</Pagination.Item>
                            <Pagination.Item>{6}</Pagination.Item>
                            <Pagination.Item>{7}</Pagination.Item>

                            <Pagination.Ellipsis /> */}

                            {hasNextPage &&
                                <Pagination.Last onClick={() => fetchNext()} />
                            }

                        </Pagination>
                    </Col>
                    <Col xs={12} md={4}></Col>
                </Row>
            </Container>
            <NewsLetter />
        </>
    );
}

export default Blog;