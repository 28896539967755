import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HiChevronDoubleDown, HiOutlineLockClosed } from "react-icons/hi";
import { FaTelegramPlane } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import NewsLetter from '../../components/newsletter/Newsletter';
import { Row, Col, Tab, Nav, Button, Card, ProgressBar, ListGroup, InputGroup, Spinner, FormControl, Container, Form } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useMutation, gql } from '@apollo/client';
import AppSettings from '../../AppSettings';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import './ProjectDetailLogin.css';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useCallback } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { EffectFade, Navigation, Pagination } from "swiper";

// + IMAGES +
import contact from '../../assets/imgs/contact.svg';
import avatar from '../../assets/imgs/avatar.png';
import criptoC from '../../assets/imgs/cripto_aguamarina_gif.gif';
import discC from '../../assets/imgs/disc_cyan.svg';
import blogCover from '../../assets/imgs/blog_cover.png';
// - IMAGES -

function valuetext(value) {
    return `${value}°C`;
}

const ProjectDetailLogin = () => {

    const onErrorCover = (error) => {
        error.target.src = blogCover;
    }

    const navigate = useNavigate();
    const [property, setProperty] = useState();
    const [latitude, setLatitude] = useState(-31.3463791);
    const [longitude, setLongitude] = useState(-64.2823772);


    const { slug } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const { settings } = AppSettings();

    const [map, setMap] = useState(null);


    // (+) Form

    const { register, handleSubmit, reset, formState, formState: { errors, isSubmitSuccessful } } = useForm();

    const onSubmit = data => sendContact({ variables: data });

    useEffect(() => {
        if (formState.isSubmitSuccessful) reset();
    }, [formState, reset]);

    // (-) Form


    // (+) Graphql

    const SEND_CONTACT = gql`${settings.graphql.mutation.sendContact}`;

    const [sendContact, { data, loading, error }] = useMutation(SEND_CONTACT);

    if (error)
        toast(error.message);

    if (data && formState.isSubmitSuccessful)
        toast((data.sendContact && data.sendContact.ok) ? 'Información de contacto enviada.' : 'No se pudo enviar la informacion de contacto.', {
            type: 'error',
            icon: false
        });

    const GET_PROPERTY_BY_SLUG = gql`${settings.graphql.query.getPropertyBySlug}`;
    const { data: propertyData, loading: propertyLoading, error: propertyError } = useQuery(GET_PROPERTY_BY_SLUG, { variables: { slug } });

    if (propertyData) {
        if (!property && propertyData.searchProperties.edges.length > 0) {
            setProperty(propertyData.searchProperties.edges[0]);
            setLatitude(Number(propertyData.searchProperties.edges[0].node.latitude));
            setLongitude(Number(propertyData.searchProperties.edges[0].node.longitude));
        }
    }

    console.log("------settings.GOOGLE_API_KEY-----");
    console.log(settings.googleApiKey);

    if (propertyError) toast(propertyError.message);

    // (-) Graphql

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const [value, setValue] = React.useState([20, 37]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const truncate = function (str) {
        return str?.length > 7 ? str.substring(0, 7) + "..." : str;
    }

    const numberFormat = function (number) {// floating point example
        return (number ? number.toLocaleString(undefined, { maximumFractionDigits: 2 }) : "");
    }

    const goToLogin = () => {
        navigate('/login');
    }

    const goToContacto = () => {
        navigate('#contacto');
    }

    const getPropertyStatusUIClasses = (property) => {
        if (!property) return '';
        if (property.node.status == 'INI') return 'dot dot-subscription';
        if (property.node.status == 'CLO') return 'dot dot-finish';
        if (property.node.status == 'PRE') return 'dot dot-design';
        if (property.node.status == 'FUN') return 'dot dot-finish'; // FONDEADO
        return '';
    }

    const getPropertyStatusUILabel = (property) => {
        if (!property) return '';
        if (property.node.status == 'INI') return 'en SUBSCRIPCIÓN';
        if (property.node.status == 'CLO') return 'FINALIZADO';
        if (property.node.status == 'PRE') return 'en PREPARACIÓN';
        if (property.node.status == 'FUN') return 'FONDEADO'; // FONDEADO
        return '';
    }

    const card = (
        <Popover id="card" className='popover-card'>
            <Popover.Body>
                <Card className='detail-card'>
                    <Card.Body>
                        <Row>
                            <Col xs={6} md={6}>
                                <Card.Title className='text-white'>{property?.node.projectTypeText}</Card.Title>
                            </Col>
                            <Col xs={6} md={6} className='text-end'>
                                <div className='chip-detail'>
                                    <span className={getPropertyStatusUIClasses(property)}></span>
                                    <p className='text-white text-uppercase m-0 font-chip'>{getPropertyStatusUILabel(property)}</p>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> Calle Mendivil 25, Madrid</Card.Subtitle>
                            </Col>
                        </Row>

                        <p className='text-light detail-text-card'>{property?.node.shortDescription}</p>

                        <ProgressBar now={property?.node.raisedAmmount / property?.node.targetGoal * 100} className='custom-progress mt-4' />

                        <Row className='align-items-center mt-1'>
                            <Col xs={6} md={6}>
                                <p className='text-light'>50%</p>
                            </Col>
                            <Col xs={6} md={6} className='text-end'>
                                <p className='text-light'>2.000.000 €</p>
                            </Col>
                        </Row>

                        <ListGroup variant="flush" className='list mt-3'>
                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                Ejecución
                                <h6 className='m-0'><b>{property?.node.executionTime} meses</b></h6>
                            </ListGroup.Item>
                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                Nº inversores
                                <HiOutlineLockClosed />
                            </ListGroup.Item>
                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                Modalidad
                                <HiOutlineLockClosed />
                            </ListGroup.Item>
                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                LVT
                                <HiOutlineLockClosed />
                            </ListGroup.Item>
                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                Rentabilidad anual
                                <h6 className='m-0'><b>{numberFormat(property?.node.investmentEstimatedYield)} %</b></h6>
                            </ListGroup.Item>
                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                Rentabilidad total
                                <HiOutlineLockClosed />
                            </ListGroup.Item>
                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                Inversión minima
                                <h6 className='m-0'><b>{numberFormat(property?.node.minInvestment)} €</b></h6>
                            </ListGroup.Item>
                        </ListGroup>

                        <Row className='align-items-center mt-3'>
                            <Col xs={12}>
                                <h4 className='text-light'>QUIERO INVERTIR:</h4>
                            </Col>
                        </Row>

                        <Row className='justify-content-end align-items-center'>
                            <Col xs={6} md={6} className='text-end'>
                                <InputGroup>
                                    <FormControl
                                        placeholder="0,00"
                                        className='input-investment' />
                                    <InputGroup.Text className='text-investment'>
                                        <h3 className='text-light'>€</h3>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col xs={6} md={6}>
                                <div className='d-flex justify-content-end'>
                                    <Link to="#contacto" className="nav-link">
                                        <Button variant='outline-light' className='btn-gb text-uppercase btn-global btn-detail btn-investment'>INVERTIR</Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <div className='position-relative overflow-hidden container-fluid pb-5'>
                <Tab.Container defaultActiveKey="resumen">
                    <Container>
                        <Row className='w-100 align-items-center d-none d-md-flex mt-5'>
                            <Col xs={12} md={12}>
                                <div className='searchbar-container-detail'>
                                    <Row className='align-items-center'>
                                        <Col xs={12} md={12} className="ps-0">
                                            <Nav>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="resumen" className='detail-tab pointer'>Resumen</Nav.Link>
                                                </Nav.Item>
                                                {/* <Nav.Item>
                                                    <Nav.Link eventKey="foto" className='detail-tab pointer'>Fotografia y video</Nav.Link>
                                                </Nav.Item> */}
                                                <Nav.Item>
                                                    <Nav.Link eventKey="inversion" href='#contacto' className='detail-tab pointer d-flex align-items-center'>
                                                        <HiOutlineLockClosed />
                                                        Inversión
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="promotores" className='detail-tab pointer'>Promotores</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="documentacion" href='#contacto' className='detail-tab pointer d-flex align-items-center'>
                                                        <HiOutlineLockClosed />
                                                        Documentación
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="seguimiento" href='#contacto' className='detail-tab pointer d-flex align-items-center'>
                                                        <HiOutlineLockClosed />
                                                        Seguimiento
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Row className='d-md-none mt-5'>
                        <Col xs={12}>
                            <OverlayTrigger trigger="click" placement="bottom" overlay={card}>
                                <Button variant='clear' className='text-light btn-card-mb w-100'>
                                    <IoLocationOutline />
                                    Calle Mendevil 25, Madrid
                                    <HiChevronDoubleDown className='text-light' />
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>

                    <div className='d-md-none mt-3'>
                        <Row className='align-items-center'>
                            <Col xs={12}>
                                <Nav className='nav-mobile'>
                                    <Nav.Item className='nav-mobile-item'>
                                        <Nav.Link eventKey="resumen" className='detail-tab pointer'>Resumen</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item className='nav-mobile-item'>
                                        <Nav.Link eventKey="foto" className='detail-tab pointer'>Fotografia y video</Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item className='nav-mobile-item'>
                                        <Nav.Link href='/login' className='detail-tab pointer d-flex align-items-center'>
                                            <HiOutlineLockClosed />
                                            Inversión
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-mobile-item'>
                                        <Nav.Link eventKey="promotores" className='detail-tab pointer'>Promotores</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-mobile-item'>
                                        <Nav.Link href='/login' className='detail-tab pointer d-flex align-items-center'>
                                            <HiOutlineLockClosed />
                                            Documentación
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-mobile-item'>
                                        <Nav.Link href='/login' className='detail-tab pointer d-flex align-items-center'>
                                            <HiOutlineLockClosed />
                                            Seguimiento
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                    </div>

                    <Container>
                        <Row>
                            <Col xs={12} md={7}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="seguimiento" className='animate__animated animate__fadeInRight animate__faster'>
                                        <Row className='mt-3'>
                                            <Col xs={12} md={12}>
                                                <h5 className='text-light d-none d-md-block'>{property?.node.projectName}</h5>

                                                {!property?.node.coverPhotoFullPath &&
                                                    <img src={blogCover} onError={onErrorCover} className="detail-img mt-3 mb-3" alt="" />
                                                }

                                                {property?.node.coverPhotoFullPath &&
                                                    <img src={property?.node.coverPhotoFullPath} onError={onErrorCover} className="detail-img mt-3 mb-3" alt="" />
                                                }

                                                <p className='text-light'>{property?.node.description}</p>

                                                <div style={{ height: '50vh', width: '100%' }}>
                                                    <GoogleMapReact
                                                        bootstrapURLKeys={{ key: settings.googleApiKey }}
                                                        defaultZoom={15}
                                                        center={{ lat: latitude, lng: longitude }}
                                                    >
                                                        <div style={{ width: '18px', height: '18px', 'background-color': '#000', border: '2px solid #fff', 'border-radius': '100%', 'user-select': 'none' }}
                                                            lat={latitude}
                                                            lng={longitude}
                                                        ></div>
                                                    </GoogleMapReact>

                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="documentacion" className='animate__animated animate__fadeInRight animate__faster'>
                                        <Row className='mt-3'>
                                            <Col xs={12} md={12}>
                                                <h5 className='text-light d-none d-md-block'>{property?.node.projectName}</h5>
                                                {!property?.node.coverPhotoFullPath &&
                                                    <img src={blogCover} onError={onErrorCover} className="detail-img mt-3 mb-3" alt="" />
                                                }

                                                {property?.node.coverPhotoFullPath &&
                                                    <img src={property?.node.coverPhotoFullPath} onError={onErrorCover} className="detail-img mt-3 mb-3" alt="" />
                                                }

                                                <p className='text-light'>{property?.node.description}</p>

                                                <div style={{ height: '50vh', width: '100%' }}>
                                                    <GoogleMapReact
                                                        bootstrapURLKeys={{ key: settings.googleApiKey }}
                                                        defaultZoom={15}
                                                        center={{ lat: latitude, lng: longitude }}
                                                    >
                                                        <div style={{ width: '18px', height: '18px', 'background-color': '#000', border: '2px solid #fff', 'border-radius': '100%', 'user-select': 'none' }}
                                                            lat={latitude}
                                                            lng={longitude}
                                                        ></div>
                                                    </GoogleMapReact>

                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="inversion" className='animate__animated animate__fadeInRight animate__faster'>
                                        <Row className='mt-3'>
                                            <Col xs={12} md={12}>
                                                <h5 className='text-light d-none d-md-block'>{property?.node.projectName}</h5>

                                                {!property?.node.coverPhotoFullPath &&
                                                    <img src={blogCover} onError={onErrorCover} className="detail-img mt-3 mb-3" alt="" />
                                                }

                                                {property?.node.coverPhotoFullPath &&
                                                    <img src={property?.node.coverPhotoFullPath} onError={onErrorCover} className="detail-img mt-3 mb-3" alt="" />
                                                }
                                            

                                                <p className='text-light'>{property?.node.description}</p>

                                                <div style={{ height: '50vh', width: '100%' }}>
                                                    <GoogleMapReact
                                                        bootstrapURLKeys={{ key: settings.googleApiKey }}
                                                        defaultZoom={15}
                                                        center={{ lat: latitude, lng: longitude }}
                                                    >
                                                        <div style={{ width: '18px', height: '18px', 'background-color': '#000', border: '2px solid #fff', 'border-radius': '100%', 'user-select': 'none' }}
                                                            lat={latitude}
                                                            lng={longitude}
                                                        ></div>
                                                    </GoogleMapReact>

                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="resumen" className='animate__animated animate__fadeInRight animate__faster'>
                                        <Row className='mt-3'>
                                            <Col xs={12} md={12}>
                                                <h5 className='text-light d-none d-md-block'>{property?.node.projectName}</h5>

                                                {!property?.node.coverPhotoFullPath &&
                                                    <img src={blogCover} onError={onErrorCover} className="detail-img mt-3 mb-3" alt="" />
                                                }

                                                {property?.node.coverPhotoFullPath &&
                                                    <img src={property?.node.coverPhotoFullPath} onError={onErrorCover} className="detail-img mt-3 mb-3" alt="" />
                                                }
                                    

                                                <p className='text-light'>{property?.node.description}</p>

                                                <div style={{ height: '50vh', width: '100%' }}>
                                                    <GoogleMapReact
                                                        bootstrapURLKeys={{ key: settings.googleApiKey }}
                                                        defaultZoom={15}
                                                        center={{ lat: latitude, lng: longitude }}
                                                    >
                                                        <div style={{ width: '18px', height: '18px', 'background-color': '#000', border: '2px solid #fff', 'border-radius': '100%', 'user-select': 'none' }}
                                                            lat={latitude}
                                                            lng={longitude}
                                                        ></div>
                                                    </GoogleMapReact>

                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="foto" className='animate__animated animate__fadeInRight animate__faster'>
                                        <Row className='mt-5'>
                                            <Col xs={12} md={12}>
                                                <h5 className='text-light d-none d-md-block'>Calle Mendevil 25, Madrid</h5>

                                                <Swiper
                                                    spaceBetween={30}
                                                    effect={"fade"}
                                                    navigation={true}
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                    modules={[EffectFade, Navigation, Pagination]}
                                                    className="swiper-detail"
                                                >
                                                    <SwiperSlide className="swiper-slide-detail">
                                                        <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide className="swiper-slide-detail">
                                                        <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide className="swiper-slide-detail">
                                                        <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
                                                    </SwiperSlide>
                                                    <SwiperSlide className="swiper-slide-detail">
                                                        <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
                                                    </SwiperSlide>
                                                </Swiper>
                                                <div className="video-responsive mt-5">
                                                    <iframe
                                                        width="853"
                                                        height="480"
                                                        src={`https://www.youtube.com/embed/rokGy0huYEA`}
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                        title="Embedded youtube"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="promotores" className='animate__animated animate__fadeInRight animate__faster'>
                                        <Row className='mt-5'>
                                            <Col xs={12} md={12}>
                                                <div className='container-gif'>
                                                    <div className='text-center'>
                                                        <img src={criptoC} alt="" className='img-fluid' />
                                                        <h2 className='text-white text-uppercase title-slider'>adapta socios</h2>
                                                    </div>
                                                    <div>
                                                        <img src={discC} alt="" className='img-fluid' />
                                                    </div>
                                                </div>
                                                <div className='container-truncate mt-5'>
                                                    <div>
                                                        <p className='text-gb text-work'><span className='text-muted'>Somos la primera plataforma de</span> inmuebles tokenizados <span className='text-muted'> que permite la</span> inversión y retirada de los beneficios<span className='text-muted'> tanto en crypto como en FIAT</span> ampliando así las posibilidades de inversión. <br /> <br /><span className='text-muted'>El expertise en áreas como tecnología, legal y Real Estate nos permite ofrecer una </span>experiencia de usuario única y <span className='text-muted'>excelente.</span></p>
                                                    </div>
                                                </div>

                                                <Row className='align-items-center justify-content-center mt-5'>
                                                    <Col xs={12} md={6} className='text-center'>
                                                        <img src={avatar} className='avatar-detail' alt="" />
                                                        <h5 className='text-light mt-3'>Lorem Ipsum</h5>
                                                        <p className='text-light text-left'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi iusto suscipit, illum aspernatur doloribus obcaecati,
                                                            cumque quod illo quibusdam molestias,
                                                            necessitatibus sunt omnis delectus error aut iure consequuntur possimus nemo!</p>
                                                    </Col>
                                                    <Col xs={12} md={6} className='text-center'>
                                                        <img src={avatar} className='avatar-detail' alt="" />
                                                        <h5 className='text-light mt-3'>Lorem Ipsum</h5>
                                                        <p className='text-light text-left'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi iusto suscipit, illum aspernatur doloribus obcaecati,
                                                            cumque quod illo quibusdam molestias,
                                                            necessitatibus sunt omnis delectus error aut iure consequuntur possimus nemo!</p>
                                                    </Col>
                                                </Row>

                                                <Row className='align-items-center justify-content-center mt-5'>
                                                    <Col xs={12} md={6} className='text-center'>
                                                        <img src={avatar} className='avatar-detail' alt="" />
                                                        <h5 className='text-light mt-3'>Lorem Ipsum</h5>
                                                        <p className='text-light text-left'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi iusto suscipit, illum aspernatur doloribus obcaecati,
                                                            cumque quod illo quibusdam molestias,
                                                            necessitatibus sunt omnis delectus error aut iure consequuntur possimus nemo!</p>
                                                    </Col>
                                                    <Col xs={12} md={6} className='text-center'>
                                                        <img src={avatar} className='avatar-detail' alt="" />
                                                        <h5 className='text-light mt-3'>Lorem Ipsum</h5>
                                                        <p className='text-light text-left'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi iusto suscipit, illum aspernatur doloribus obcaecati,
                                                            cumque quod illo quibusdam molestias,
                                                            necessitatibus sunt omnis delectus error aut iure consequuntur possimus nemo!</p>
                                                    </Col>
                                                </Row>

                                                <Row className='align-items-center justify-content-center mt-5'>
                                                    <Col xs={12} md={6} className='text-center'>
                                                        <img src={avatar} className='avatar-detail' alt="" />
                                                        <h5 className='text-light mt-3'>Lorem Ipsum</h5>
                                                        <p className='text-light text-left'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi iusto suscipit, illum aspernatur doloribus obcaecati,
                                                            cumque quod illo quibusdam molestias,
                                                            necessitatibus sunt omnis delectus error aut iure consequuntur possimus nemo!</p>
                                                    </Col>
                                                    <Col xs={12} md={6} className='text-center'>
                                                        <img src={avatar} className='avatar-detail' alt="" />
                                                        <h5 className='text-light mt-3'>Lorem Ipsum</h5>
                                                        <p className='text-light text-left'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi iusto suscipit, illum aspernatur doloribus obcaecati,
                                                            cumque quod illo quibusdam molestias,
                                                            necessitatibus sunt omnis delectus error aut iure consequuntur possimus nemo!</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                            <Col xs={12} md={5} className='d-none d-md-block'>
                                <Card className='detail-card'>
                                    <Card.Body>
                                        <Row>
                                            <Col xs={6} md={6}>
                                                <Card.Title className='text-white'>{property?.node.projectTypeText}</Card.Title>
                                                <Card.Subtitle className="mb-3 mt-3 text-white txt-small"><IoLocationOutline /> {property?.node.location}</Card.Subtitle>
                                            </Col>
                                            <Col xs={6} md={6} className='text-end'>
                                                <div className='chip-detail'>
                                                    <span className={getPropertyStatusUIClasses(property)}></span>
                                                    <p className='text-white text-uppercase m-0 font-chip'>{getPropertyStatusUILabel(property)}</p>
                                                </div>
                                            </Col>
                                        </Row>

                                        <p className='text-light detail-text-card'>{property?.node.shortDescription}</p>

                                        <ProgressBar now={property?.node.raisedAmmount / property?.node.targetGoal * 100} className='custom-progress mt-4' />

                                        <Row className='align-items-center mt-1'>
                                            <Col xs={6} md={6}>
                                                <p className='text-light'>{numberFormat(property?.node.raisedAmmount / property?.node.targetGoal * 100)} %</p>
                                            </Col>
                                            <Col xs={6} md={6} className='text-end'>
                                                <p className='text-light'>{numberFormat(property?.node.targetGoal)} €</p>
                                            </Col>
                                        </Row>

                                        <ListGroup variant="flush" className='list mt-5'>
                                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                Ejecución
                                                <h5 className='m-0'><b>{property?.node.executionTime} meses</b></h5>
                                            </ListGroup.Item>
                                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                Nº inversores
                                                <HiOutlineLockClosed />
                                            </ListGroup.Item>
                                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                Modalidad
                                                <HiOutlineLockClosed />
                                            </ListGroup.Item>
                                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                LVT
                                                <HiOutlineLockClosed />
                                            </ListGroup.Item>
                                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                Rentabilidad anual
                                                <h5 className='m-0'><b>{numberFormat(property?.node.investmentEstimatedYield)} %</b></h5>
                                            </ListGroup.Item>
                                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                Rentabilidad total
                                                <HiOutlineLockClosed />
                                            </ListGroup.Item>
                                            <ListGroup.Item className='d-flex justify-content-between align-items-start'>
                                                Inversión minima
                                                <h5 className='m-0'><b>{numberFormat(property?.node.minInvestment)} €</b></h5>
                                            </ListGroup.Item>
                                        </ListGroup>

                                        <Row className='align-items-center mt-3'>
                                            <Col xs={6} md={8}>
                                                {/* <h4 className='text-light'>QUIERO INVERTIR:</h4> */}
                                                {/* <Row className='justify-content-end align-items-center'>
                                                    <Col xs={12} md={6}></Col>
                                                    <Col xs={11} md={6} className='text-end'>
                                                        <InputGroup>
                                                            <FormControl
                                                                placeholder="0,00"
                                                                className='input-investment' />
                                                            <InputGroup.Text className='text-investment'>
                                                                <h3 className='text-light'>€</h3>
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                    </Col>
                                                </Row> */}
                                            </Col>
                                            <Col xs={6} md={4} className='text-end'>
                                                <div className='d-flex justify-content-end'>
                                                    <Link to="#contacto" className="nav-link">
                                                        <Button variant='outline-light' className='btn-gb text-uppercase btn-global btn-detail btn-investment'>INVERTIR</Button>
                                                    </Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Tab.Container>
            </div>
            <Container className='p-3' id='contacto' >
                <Row>
                    <Col xs={12} md={7}>
                        <Row className='align-items-center mt-3'>
                            <Col xs={12} md={6}>
                                <Row className='justify-content-center'>
                                    <Col className='text-center'>
                                        <img src={contact} alt="" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={6}>
                                <h1 className='text-light subtitle-detail mt-5'>¿Tienes alguna duda?</h1>
                                <h2 className='subtitle-text-detail'>¡Contacta con nuestro equipo de atención al inversor!</h2>
                                <Form>
                                    <Form.Group className='mb-3'>
                                        <Form.Control type="text" className='input-contact' placeholder="Nombre" {...register("name", { required: true })} />
                                        {errors.name &&
                                            <Form.Text className="text-muted">
                                                Debe ingresar el nombre
                                            </Form.Text>
                                        }
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Control type="email" className='input-contact' placeholder="Email" {...register("email", { required: true })} />
                                        {errors.email &&
                                            <Form.Text className="text-muted">
                                                Debe ingresar el email
                                            </Form.Text>
                                        }
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Control type="text" className='input-contact' placeholder="Asunto" {...register("subject", { value: slug, required: true })} />
                                        {errors.subject &&
                                            <Form.Text className="text-muted">
                                                Debe ingresar el asunto
                                            </Form.Text>
                                        }
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Control type="text" className='input-contact' placeholder="Mensaje" {...register("message", { required: true })} />
                                        {errors.message &&
                                            <Form.Text className="text-muted">
                                                Debe ingresar el mensaje
                                            </Form.Text>
                                        }
                                    </Form.Group>
                                    <Form.Group className='mb-3 mt-5'>
                                        <Form.Check type="checkbox" label="Acepto las políticas de privacidad." {...register("privacyPolicies", { required: true })} />
                                        {errors.privacyPolicies &&
                                            <Form.Text className="text-muted">
                                                Debe aceptar las politicas de privacidad
                                            </Form.Text>
                                        }
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check type='checkbox' label="Deseo recibir comunicaciones comerciales sobre los servicios de Adapta Socios." {...register("comercialComunications")} />
                                    </Form.Group>

                                    <Row className='justify-content-end'>
                                        <Col xs={2} md={2}>
                                            <Button variant="outline-light" type="submit" className='btn-gb' disabled={loading} onClick={handleSubmit(onSubmit)}>
                                                {loading &&
                                                    <Spinner animation="border" size="sm" variant="light" role="status"></Spinner>
                                                }
                                                {!loading &&
                                                    <FaTelegramPlane />
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col xs={12} md={1}></Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <NewsLetter />
        </>
    );
}

export default ProjectDetailLogin;