import { Routes, Route, useLocation } from 'react-router-dom';
import TopMenu from './components/topmenu/TopMenu';
import Footer from './components/footer/Footer';
import Home from './modules/home/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';
import useGlobal from './hooks/useGlobal';
import AppGlobal from './context/AppGlobal';
import Faqs from './modules/faqs/Faqs';
import HowWorks from './modules/how-works/HowWorks';
import Blog from './modules/blog/Blog';
import Partners from './modules/partners/Partners';
import BlogDetail from './modules/blog-detail/BlogDetail';
import Contact from './modules/contact/Contact';
import Login from './modules/login/Login';
import RecoverPassword from './modules/recover-password/RecoverPassword';
import Dashboard from './modules/dashboard/Dashboard';
import Privacy from './modules/privacy/Privacy';
import Cookies from './modules/cookies/Cookies';
import Legal from './modules/legal/Legal';
import Investments from './modules/investments/Investments';
import Projects from './modules/projects/Projects';
import ProjectsDetail from './modules/projects-detail/ProjectsDetail';
import ProjectDetailLogin from './modules/project-detail-login/ProjectDetailLogin';

const App = () => {
  const global = useGlobal();
  const location = useLocation().pathname;

  return (
    <div>
      <AppGlobal.Provider value={global}>
        {!(location.match(/^\/dashboard.*$/gim)) && !(location.match(/^\/investments.*$/gim)) && !(location.match(/^\/projects.*$/gim)) && <TopMenu />}
        <Routes>
          <Route exact path='/*' element={<Home />}></Route>
          <Route path='/faqs-inversion-inmuebles-tokenizados' element={<Faqs />}></Route>
          <Route path='/como-invertir-en-inmuebles-tokenizados' element={<HowWorks />}></Route>
          <Route path='/blog-tokenizar-inmueble' element={<Blog />}></Route>
          <Route path='/equipo-casa-del-token' element={<Partners />}></Route>
          <Route path='/contacto' element={<Contact />}></Route>
          <Route path='/blog-tokenizar-inmueble-detalle/:slug' element={<BlogDetail />}></Route>
          <Route path='/login' element={<Login />}></Route>
          <Route path='/recover-password' element={<RecoverPassword />}></Route>
          <Route path='/dashboard' element={<Dashboard />}></Route>
          <Route path='/investments' element={<Investments />}></Route>
          <Route path='/projects' element={<Projects />}></Route>
          <Route path='/projects-detail' element={<ProjectsDetail />}></Route>
          <Route path='/projecto/:slug' element={<ProjectDetailLogin />}></Route>
          <Route path='/politica-de-privacidad' element={<Privacy />}></Route>
          <Route path='/politica-de-cookies' element={<Cookies />}></Route>
          <Route path='/aviso-legal' element={<Legal />}></Route>
        </Routes>
        <Footer />
      </AppGlobal.Provider>
    </div>
  );
}

export default App;
