import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import './RecoverPassword.css';
import { MdOutlinePassword } from "react-icons/md";
import { BsKeyboard } from "react-icons/bs"
import { IoClose } from "react-icons/io5"
// + IMAGES +

// - IMAGES -

const RecoverPassword = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <div className='container-fluid overflow-hidden custom-bg-login'>
                <div className='container position-relative'>
                    <div className='text-center icon-container'>
                        <MdOutlinePassword className='text-white' />
                    </div>
                    <p className='subtitle-text text-center pt-4'>Recuperar contraseña</p>

                    <p className='text-gb text-center'>
                        <small>
                            Se ha enviado un código a esta dirección de correo electrónico.
                        </small>
                    </p>
                    <p className='text-gb text-center mb-4'>
                        example****@gmail.com
                    </p>
                    <Form>
                        <div className='mb-4 pt-3'>
                            <Form.Group className='mb-3'>
                                <InputGroup className='mt-3'>
                                    <InputGroup.Text className='input-send'>
                                        <BsKeyboard className='text-white' />
                                    </InputGroup.Text>
                                    <Form.Control type="text" className='input-contact custom-placeholder' placeholder="Ingrease el codigo aqui" />
                                </InputGroup>
                            </Form.Group>
                        </div>
                        <div className=''>
                            <div className='pt-4 mb-3'>
                                <Button variant='outline-light' className='btn-gb btn-global btn-border-light w-100'>enviar</Button>
                            </div>
                        </div>
                    </Form>
                    <span className='img-trama'></span>
                </div>
            </div>
        </>
    );
}

export default RecoverPassword;