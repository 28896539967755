import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Chart.css';
import { Row, Col } from 'react-bootstrap';
import { RiBuildingLine } from "react-icons/ri";
import { AiOutlineLineChart } from "react-icons/ai";

const Chart = () => {
    const [chart, setChart] = useState(false);

    return (
        <>
            <Row className='align-items-center d-none d-md-flex porcents-nav'>
                <Col md={6} className='text-center mt-1 mb-2'>
                    <p className='text-light m-0 animate__animated animate__fadeInRight animate__infinite animate__slower'><RiBuildingLine /><b> Franco Rodríguez | </b><span className='text-success mt-2'><b> 18%</b></span></p>
                </Col>
                <Col md={6} className='text-center mt-1 mb-2'>
                    <p className='text-light m-0 animate__animated animate__fadeInRight animate__infinite animate__slower'><RiBuildingLine /><b> Gaztambide 3 | </b><span className='text-danger mt-2'><b> 8%</b></span></p>
                </Col>
            </Row>
            {!chart &&
                <Row className='d-md-none justify-content-start animate__animated animate__bounceInRight animate__faster cont-chart'>
                    <Col className='text-end'>
                        <AiOutlineLineChart className='text-light chart mb-2' onClick={() => setChart(true)} />
                    </Col>
                </Row>
            }
            {chart &&
                <Row className='animate__animated animate__bounceInLeft animate__faster cont-chart-full m-0' onClick={() => setChart(false)}>
                    <Col xs={6}>
                        <p className='text-light text-chart animate__animated animate__fadeInRight animate__infinite animate__slower'><RiBuildingLine /><b> Franco Rodríguez | </b><span className='text-success mt-2'><b> 18%</b></span></p>
                    </Col>
                    <Col xs={6}>
                        <p className='text-light text-chart animate__animated animate__fadeInRight animate__infinite animate__slower'><RiBuildingLine /><b> Gaztambide 3 | </b><span className='text-danger mt-2'><b> 8%</b></span></p>
                    </Col>
                </Row>
            }
        </>
    );
}

export default Chart;