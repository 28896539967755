import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, InputGroup, FormControl, Accordion } from 'react-bootstrap';
import './Faqs.css';
import { FaSearch } from "react-icons/fa";
import Fab from '../../components/fab/Fab';
import Chart from '../../components/chart/Chart';
import NewsLetter from '../../components/newsletter/Newsletter';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// + IMAGES +
import swiper1 from '../../assets/imgs/swiper1.svg';
import swiper2 from '../../assets/imgs/swiper2.svg';
import swiper3 from '../../assets/imgs/swiper3.svg';
// - IMAGES -

const Faqs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const [card, setCard] = useState('first');

    return (
        <>
            <div className='container-faqs'>
                <Fab />
                <Container className='p-5 pt-5 mt-5'>
                    <h1 className='subtitle'>Preguntas <br /> frecuentes</h1>

                    <h2 className='text-color'>¿Tienes alguna duda?</h2>

                    {/* <Row className='mt-5'>
                        <Col xs={12} md={6}>
                            <InputGroup>
                                <InputGroup.Text className='input-search'>
                                    <FaSearch className='text-light' />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Escribe tema o pregunta..."
                                    className='searchbar' />
                            </InputGroup>
                        </Col>
                    </Row> */}

                    <div className='mt-5'>
                        <Swiper spaceBetween={16} modules={[Pagination]} breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                            1300: {
                                slidesPerView: 4,
                                spaceBetween: 40,
                            },
                        }}>
                            <SwiperSlide>
                                <div className={`card-round-faq ${card == 'first' ? 'active-card' : ''}`} onClick={() => setCard('first')}>
                                    <div className='icon-swiper-container-faq'>
                                        <img src={swiper1} className='icon-swiper' alt="" />
                                    </div>

                                    <p className='title-swiper text-center'>Sobre la Casa del Token y su funcionamiento</p>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className={`card-round-faq ${card == 'second' ? 'active-card' : ''}`} onClick={() => setCard('second')}>
                                    <div className='icon-swiper-container-faq'>
                                        <img src={swiper3} className='icon-swiper' alt="" />
                                    </div>

                                    <p className='title-swiper text-center'>Sobre inversiones inmobiliarias</p>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className={`card-round-faq ${card == 'third' ? 'active-card' : ''}`} onClick={() => setCard('third')}>
                                    <div className='icon-swiper-container-faq'>
                                        <img src={swiper2} className='icon-swiper' alt="" />
                                    </div>

                                    <p className='title-swiper text-center'>Sobre Blockchain</p>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </Container>
            </div>


            <div>
                <Container className='p-5'>
                    {card == 'first' &&
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0" className='accordion-faq'>
                                <Accordion.Header className='accordion-faq-header'>¿Cuál es la propuesta de valor de La Casa Del Token?</Accordion.Header>
                                <Accordion.Body>
                                    La Casa Del Token nace con el objetivo de democratizar y globalizar la inversión en Real Estate.
                                    Gracias a nuestra plataforma los pequeños y medianos inversores de todas las partes del mundo podrán obtener ingresos pasivos y
                                    una rentabilidad anual.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" className='accordion-faq'>
                                <Accordion.Header className='accordion-faq-header'>¿Qué personas pueden invertir en La Casa Del Token?</Accordion.Header>
                                <Accordion.Body>
                                    La Casa Del Token permite a cualquier persona generar ingresos pasivos gracias a su plataforma Blockchain. Lo único que se necesita es tener 18 años y pasar nuestro KYC
                                    (filtro de inversor acreditado) que nos asegure que nuestro producto financiero ha quedado completamente entendido por el inversor.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2" className='accordion-faq'>
                                <Accordion.Header className='accordion-faq-header'>¿Cómo me registro?</Accordion.Header>
                                <Accordion.Body>
                                    Tienes que acceder a nuestra web, pulsar el botón “Regístrate ya” y rellenar todos los datos, así como tu documentación y prueba de residencia.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3" className='accordion-faq'>
                                <Accordion.Header className='accordion-faq-header'>¿Cuál es el mínimo que puedo invertir?</Accordion.Header>
                                <Accordion.Body>
                                    La mínima inversión que permitimos es de 100€. Es por ello que hacemos una equivalencia de 1 Token = 100€.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4" className='accordion-faq'>
                                <Accordion.Header className='accordion-faq-header'>¿Cómo funciona el proceso de inversión? ¿Requiere mucho tiempo?</Accordion.Header>
                                <Accordion.Body>
                                    Resulta muy intuitivo, hemos trabajado la UX de la página web para que puedas hacer este proceso en 5 minutos:
                                    <ul className='list-faqs'>
                                        <li>Registrate y realiza el proceso de validación de identidad y de KYC.</li>
                                        <li>Selecciona los tokens que deseas y realiza la compra desde tu wallet.</li>
                                        <li>Firma el Acuerdo de Inversión.</li>
                                        <li>Recibe tus tokens y disfruta de tus ingresos pasivos anuales.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    }

                    {card == 'second' &&
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0" className='accordion-faq'>
                                <Accordion.Header className='accordion-faq-header'>¿La Casa del Token cumple todo el parámetro legal?</Accordion.Header>
                                <Accordion.Body>
                                    La Casa Del Token cumple todos los requisitos legales requeridos por la CNMV (Comisión Nacional del Mercado de Valores) y organismos competentes.
                                    La Casa Del Token tokeniza un préstamo participativo por inmueble, el cual se oferta a los inversores a través de una STO (Security Token Offering),
                                    con la aprobación de una ESI como agente regulado por CNMV según el artículo 35 de la Ley del Mercado de Valores.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" className='accordion-faq'>
                                <Accordion.Header className='accordion-faq-header'>¿Qué es un préstamo participativo?</Accordion.Header>
                                <Accordion.Body>
                                    El préstamo participativo es un instrumento financiero intermedio entre el capital social y el préstamo. Una de sus principales características es que su retribución depende directamente de la explotación del inmueble.
                                    De este modo, La Casa Del Token tokeniza un préstamo participativo asociado a la renta que genera un inmueble.
                                    El inversor que posee los tokens percibirá un interés anual en función de las características y el rendimiento del inmueble.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2" className='accordion-faq'>
                                <Accordion.Header className='accordion-faq-header'>¿Qué oportunidades se presentan en la plataforma?</Accordion.Header>
                                <Accordion.Body>
                                    Gracias a nuestros partners, poseemos una cartera inmobiliaria líder en el sector de la tokenización de Inmuebles.
                                    Es por ello que todo el producto inmobiliario que ofrecemos es propio y podemos generar rentabilidades anuales superiores al 10%.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3" className='accordion-faq'>
                                <Accordion.Header className='accordion-faq-header'>¿Es necesario que un inmueble se venda al 100%?</Accordion.Header>
                                <Accordion.Body>
                                    Correcto, una vez que la venta de tokens llega al 100%, nuestro equipo operativo se encarga de llevar a cabo todas las acciones pertinentes para comenzar la explotación del inmueble.
                                    De esta forma todos los inversores comienzan a obtener sus ingresos pasivos en forma de rentabilidad anual.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    }

                    {card == 'third' &&
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0" className='accordion-faq'>
                                <Accordion.Header className='accordion-faq-header'>¿Cuáles son las definiciones de Token y Tokenización?</Accordion.Header>
                                <Accordion.Body>
                                    Un token es la representación digital de un activo en una Blockchain. Tokenización es la acción de digitalizar un activo de manera que el token formalice el valor de
                                    ese bien y quede registrado en la Blockchain.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" className='accordion-faq'>
                                <Accordion.Header className='accordion-faq-header'>¿Cuáles son las ventajas de la tokenización?</Accordion.Header>
                                <Accordion.Body>
                                    Tener un activo digital genera muchas prestaciones al usuario:
                                    <ul className='list-faqs'>
                                        <li>Transferencias en tiempo récord.</li>
                                        <li>Capacidad de almacenar los tokens.</li>
                                        <li>Repartición en forma de tickets de todos los inmuebles.</li>
                                    </ul>
                                    Estas ventajas permiten una plataforma online rápida,segura y rentable.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2" className='accordion-faq'>
                                <Accordion.Header className='accordion-faq-header'>¿Qué Blockchain utiliza La Casa Del Token?</Accordion.Header>
                                <Accordion.Body>
                                    La Casa Del Token utiliza la red BNB Chain. En BNB Chain un token representa el valor y/o la propiedad de cualquier bien. De forma que mientras en el mundo no
                                    digital unas escrituras representan la propiedad de un inmueble, en BNB Chain un token desplegado sobre la red puede representar la propiedad del inmueble o un producto asociado,
                                    en nuestro caso los derechos de explotación sobre el mismo y las plusvalías generadas en su posterior venta.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    }
                </Container>

                <NewsLetter />
            </div>
        </>
    );
}

export default Faqs;